import * as actionTypes from './actionTypes';
import {postData} from "../../shared/utility";

export const createCouponStart = () => ({
    type: actionTypes.CREATE_COUPON_START
});

export const createCouponSuccess = coupon => ({
    type: actionTypes.CREATE_COUPON_SUCCESS,
    coupon
});

export const createCouponError = error => ({
    type: actionTypes.CREATE_COUPON_ERROR,
    error
});

export const createCoupon = data => dispatch => {
    dispatch(createCouponStart());
    let onSuccess = response => {
        console.log(response);
        dispatch(createCouponSuccess(response.data));
    };

    postData(
        "/coupon",
        data,
        onSuccess,
        error => {
            console.log(error);
            dispatch(createCouponError(error));
        },
        dispatch
    );
};