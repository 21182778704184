import React from 'react';
import '../../styles/components/Select.scss';

const Select = props => {
    if (!props.list) {
        return null;
    }

    let options = props.list.map((item, index) =>
        <option
            value={item}
            key={index}
            className="option"
        >
            {item}
        </option>
    );

    return (
        <div className="select">
            <label htmlFor="options" className="select-label">{props.selectLabel}</label><br/>
            <select
                id="options"
                onChange={e => props.changeOption(e.target.value)}
                value={props.currentOption}
            >
                {options}
            </select>
        </div>
    )
};

export default Select;