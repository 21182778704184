import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    data: null,
    indexedData: null,
    loading: false,
    error: null,
};

const getAssetsStart = ( state, action ) => {
    return updateObject( state, {
        data: null,
        indexedData: null,
        loading: true,
        error: null
    } );
};

const getAssetsSuccess = ( state, action ) => {
    const indexedData = {};
    action.data.forEach(asset => indexedData[asset.id] = asset);
    return updateObject( state, {
        data: action.data,
        indexedData: indexedData,
        loading: false,
        error: null
    } );
};

const getAssetsFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};

const invalidateAssets = ( state, action ) => {
    return updateObject( state, {
        data: null,
        indexedData: null
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_ASSETS_START: return getAssetsStart(state, action);
        case actionTypes.GET_ASSETS_SUCCESS: return getAssetsSuccess(state, action);
        case actionTypes.GET_ASSETS_FAIL: return getAssetsFail(state, action);
        case actionTypes.CREATE_ASSET_SUCCESS: return invalidateAssets(state, action);
        case actionTypes.INVALIDATE_ASSETS: return invalidateAssets(state, action);
        default:
            return state;
    }
};

export default reducer;