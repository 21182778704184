import * as actionTypes from "./actionTypes";
import {deleteData, getData, postData} from "../../shared/utility";

export const getTaskStart = () => {
    return {
        type: actionTypes.GET_TASK_START
    }
};

export const getTaskSuccess = (data) => {
    return {
        type: actionTypes.GET_TASK_SUCCESS,
        data: data,
    }
};

export const getTaskFail = (error) => {
    return {
        type: actionTypes.GET_TASK_FAIL,
        error: error
    }
};

export const deleteTaskStart = () => {
    return {
        type: actionTypes.DELETE_TASK_START
    }
};

export const deleteTaskSuccess = () => {
    return {
        type: actionTypes.DELETE_TASK_SUCCESS,
    }
};

export const deleteTaskFail = (error) => {
    return {
        type: actionTypes.DELETE_TASK_FAIL,
        error: error
    }
};

export const createTaskStart = () => {
    return {
        type: actionTypes.CREATE_TASK_START
    }
};

export const createTaskSuccess = (data) => {
    return {
        type: actionTypes.CREATE_TASK_SUCCESS,
        data: data,
    }
};

export const createTaskFail = (error) => {
    return {
        type: actionTypes.CREATE_TASK_FAIL,
        error: error
    }
};

export const clearTaskFail = () => {
    return {
        type: actionTypes.CLEAR_TASK_FAIL
    }
};

export const updateTaskStart = () => {
    return {
        type: actionTypes.UPDATE_TASK_START
    }
};

export const updateTaskSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_TASK_SUCCESS,
        data: data,
    }
};

export const updateTaskFail = (error) => {
    return {
        type: actionTypes.UPDATE_TASK_FAIL,
        error: error
    }
};

export const getTask = (id) => {
    return dispatch => {
        dispatch(getTaskStart());
        getData(
            "/tasks/" + id,
            response => {
                dispatch(getTaskSuccess(response.data))
            },
            error => {
                dispatch(getTaskFail(error.response.status))
            },
            dispatch
        );
    };
};

export const createTask = (data) => {
    return dispatch => {
        dispatch(createTaskStart());
        postData(
            "/tasks",
            data,
            response => {
                dispatch(createTaskSuccess(response.data))
            },
            error => {
                dispatch(createTaskFail(error.response.status))
            },
            dispatch
        );
    };
};

export const updateTask = (id, data) => {
    return dispatch => {
        dispatch(updateTaskStart());
        postData(
            "/tasks/" + id,
            data,
            response => {
                dispatch(updateTaskSuccess(response.data))
            },
            error => {
                dispatch(updateTaskFail(error.response.status))
            },
            dispatch
        );
    };
};

export const deleteTask = (id) => {
    return dispatch => {
        dispatch(deleteTaskStart());
        deleteData(
            "/tasks/" + id,
            response => {
                dispatch(deleteTaskSuccess())
            },
            error => {
                dispatch(deleteTaskFail(error.response.status))
            },
            dispatch
        );
    };
};