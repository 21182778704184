import React from 'react';
import {connect} from 'react-redux';
import { getProductGroups } from '../../store/actions/productGroups';
import KeyValueBlock from "../KeyValueBlock/KeyValueBlock";
import PopupForm from '../PopupForm/PopupForm';
import classes from './ProductGroup.module.css';
import productGroupFields from "../../forms/productGroup";
import {updateProductGroup} from "../../store/actions/productGroup";

class ProductGroup extends React.Component {
    state = {
        showEditForm: false
    };

    componentDidMount() {
        if (!this.props.indexedGroups) {
            this.props.dispatch(getProductGroups());
        }
    }

    formatData(id, newData) {
        newData.links = {thumbnail: newData.links};
        this.props.dispatch(updateProductGroup(id, newData));
        this.setState({showEditForm: false});
    }

    render() {
        let groupData;
        let imgUrl = null;
        if (this.props.indexedGroups && this.props.match.params.id) {
            groupData = this.props.indexedGroups[this.props.match.params.id];

            if (groupData.links) {
                imgUrl = groupData.links.thumbnail;
            }

            const translations = {
                id: "ID",
                descriptiveName: "Name",
                tag: "Tag",
                links: "Image URL",
                description: "Description",
                products: "Products",
                visible: "Visible",
                order: "Order"
            };

            let products = groupData.products.map((product, index) =>
                <li
                    key={index}
                    onClick={() => this.props.history.push('/product/' + product.id)}
                    className={classes.productItem}
                >
                    {product.tag}
                </li>
            );
            const popupFormData = {
                id: groupData.id,
                descriptiveName: groupData.descriptiveName,
                tag: groupData.tag,
                links: imgUrl,
                description: groupData.description,
                products: products,
                visible: groupData.visible.toString(),
                order: groupData.order
            };

            const keyBlockValueData = {
                id: groupData.id,
                descriptiveName: groupData.descriptiveName,
                tag: groupData.tag,
                links: imgUrl,
                description: groupData.description.split('\n').map((item, index) => {
                    return (
                        <span className="product-text" key={index}>
                            {item}
                            <br/>
                        </span>
                    );
                }),
                products: products,
                visible: groupData.visible.toString(),
                order: groupData.order
            };

            return (
                <React.Fragment>
                    {
                        this.state.showEditForm ?
                        <PopupForm
                            fields={productGroupFields}
                            prefill={popupFormData}
                            onSubmit={newData => this.formatData(this.props.match.params.id, newData)}
                            close={() => this.setState({showEditForm: false})}
                        /> :
                        null
                    }
                    <KeyValueBlock
                        data={keyBlockValueData}
                        keys={["id", "descriptiveName", "tag", "links", "description", "products", "visible", "order"]}
                        translations={translations}
                        editClick={() => this.setState({showEditForm: true})}
                    />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    groups: state.productGroups.productGroups,
    indexedGroups: state.productGroups.indexedGroups
});

export default connect(mapStateToProps)(ProductGroup);