import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    data: null,
    loading: false,
    error: null
};

const addUsersToCompanySeatsStart = (state, action) => {
    return updateObject(state, {
        data: null,
        loading: true,
        error: null
    })
};

const addUsersToCompanySeatsSuccess = (state, action) => {
    return updateObject(state, {
        data: action.data,
        loading: false,
        error: null
    })
};

const addUsersToCompanySeatsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_USERS_COMPANY_SEATS_START:
            return addUsersToCompanySeatsStart(state, action);
        case actionTypes.ADD_USERS_COMPANY_SEATS_SUCCESS:
            return addUsersToCompanySeatsSuccess(state, action);
        case actionTypes.ADD_USERS_COMPANY_SEATS_FAIL:
            return addUsersToCompanySeatsFail(state, action);
        default:
            return state;
    }
};

export default reducer;