// AUTH
export var LOGIN_START = 'LOGIN_START';
export var LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export var LOGIN_FAIL = 'LOGIN_FAIL';
export var LOGOUT = 'LOGOUT';
export var CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
// ME
export var GET_ME_START = 'GET_ME_START';
export var GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export var GET_ME_FAIL = 'GET_ME_FAIL';
export var CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export var CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export var CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
// USERS
export var GET_USERS_START = 'GET_USERS_START';
export var GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export var GET_USERS_FAIL = 'GET_USERS_FAIL';
export var INVALIDATE_USERS = 'INVALIDATE_USERS';
// USER
export var GET_USER_START = 'GET_USER_START';
export var GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export var GET_USER_FAIL = 'GET_USER_FAIL';
export var CREATE_USER_START = 'CREATE_USER_START';
export var CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export var CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export var CLEAR_USER_FAIL = 'CLEAR_USER_FAIL';
export var CHANGE_USER_ROLE_START = 'CHANGE_USER_ROLE_START';
export var CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export var CHANGE_USER_ROLE_FAIL = 'CHANGE_USER_ROLE_FAIL';
export var CHANGE_USER_PASSWORD_START = 'CHANGE_USER_PASSWORD_START';
export var CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export var CHANGE_USER_PASSWORD_FAIL = 'CHANGE_USER_PASSWORD_FAIL';
// COMPANIES
export var GET_COMPANIES_START = 'GET_COMPANIES_START';
export var GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export var GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';
export var INVALIDATE_COMPANIES = 'INVALIDATE_COMPANIES';
// COMPANY
export var GET_COMPANY_START = 'GET_COMPANY_START';
export var GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export var GET_COMPANY_FAIL = 'GET_COMPANY_FAIL';
export var GET_PAYMENT_LINK_STATUS_START = 'GET_PAYMENT_LINK_STATUS_START';
export var GET_PAYMENT_LINK_STATUS_SUCCESS = 'GET_PAYMENT_LINK_STATUS_SUCCESS';
export var GET_PAYMENT_LINK_STATUS_FAIL = 'GET_PAYMENT_LINK_STATUS_FAIL';
export var CREATE_COMPANY_START = 'CREATE_COMPANY_START';
export var CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export var CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';
// PRODUCTS
export var GET_PRODUCTS_START = 'GET_PRODUCTS_START';
export var GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export var GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';
export var GET_PRODUCT_START = 'GET_PRODUCT_START';
export var GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export var GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export var CREATE_PRODUCT_START = 'CREATE_PRODUCT_START';
export var CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export var CREATE_PRODUCT_FAIL = 'CREATE_PRODUCT_FAIL';
export var CLEAR_PRODUCT_FAIL = 'CLEAR_PRODUCT_FAIL';
export var UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START';
export var UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export var UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';
export var DELETE_PRODUCT_START = 'DELETE_PRODUCT_START';
export var DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export var DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';
// PRODUCT GROUPS
export var GET_PRODUCT_GROUPS_START = 'GET_PRODUCT_GROUPS_START';
export var GET_PRODUCT_GROUPS_SUCCESS = 'GET_PRODUCT_GROUPS_SUCCESS';
export var GET_PRODUCT_GROUPS_ERROR = 'GET_PRODUCT_GROUPS_ERROR';
export var CREATE_PRODUCT_GROUP_START = 'CREATE_PRODUCT_GROUP_START';
export var CREATE_PRODUCT_GROUP_SUCCESS = 'CREATE_PRODUCT_GROUP_SUCCESS';
export var CREATE_PRODUCT_GROUP_ERROR = 'CREATE_PRODUCT_GROUP_ERROR';
export var CLEAR_PRODUCT_GROUP_ERROR = 'CLEAR_PRODUCT_GROUP_ERROR';
export var UPDATE_PRODUCT_GROUP_START = 'UPDATE_PRODUCT_GROUP_START';
export var UPDATE_PRODUCT_GROUP_SUCCESS = 'UPDATE_PRODUCT_GROUP_SUCCESS';
export var UPDATE_PRODUCT_GROUP_ERROR = 'UPDATE_PRODUCT_GROUP_ERROR';
// ORDER FORMS
export var GET_ORDER_FORMS_START = 'GET_ORDER_FORMS_START';
export var GET_ORDER_FORMS_SUCCESS = 'GET_ORDER_FORMS_SUCCESS';
export var GET_ORDER_FORMS_FAIL = 'GET_ORDER_FORMS_FAIL';
export var FILTER_BY_ORDER_FORMS_START = 'FILTER_BY_ORDER_FORMS_START';
export var FILTER_BY_ORDER_FORMS_SUCCESS = 'FILTER_BY_ORDER_FORMS_SUCCESS';
export var FILTER_BY_ORDER_FORMS_ERROR = 'FILTER_BY_ORDER_FORMS_ERROR';
// ORDER FORM
export var CREATE_ORDER_FORM_START = 'CREATE_ORDER_FORM_START';
export var CREATE_ORDER_FORM_SUCCESS = 'CREATE_ORDER_FORM_SUCCESS';
export var CREATE_ORDER_FORM_FAIL = 'CREATE_ORDER_FORM_FAIL';
export var GET_ORDER_FORM_START = 'GET_ORDER_FORM_START';
export var GET_ORDER_FORM_SUCCESS = 'GET_ORDER_FORM_SUCCESS';
export var GET_ORDER_FORM_FAIL = 'GET_ORDER_FORM_FAIL';
export var GET_ORDER_FORMS_ISSUED_START = 'GET_ORDER_FORMS_ISSUED_START';
export var GET_ORDER_FORMS_ISSUED_SUCCESS = 'GET_ORDER_FORMS_ISSUED_SUCCESS';
export var GET_ORDER_FORMS_ISSUED_ERROR = 'GET_ORDER_FORMS_ISSUED_ERROR';
export var CLEAR_ORDER_FORMS_ERROR = 'CLEAR_ORDER_FORMS_ERROR';
// TRANSACTIONS
export var GET_TRANSACTIONS_START = 'GET_TRANSACTIONS_START';
export var GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export var GET_TRANSACTIONS_FAIL = 'GET_TRANSACTIONS_FAIL';
export var GET_TRANSACTION_START = 'GET_TRANSACTION_START';
export var GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export var GET_TRANSACTION_FAIL = 'GET_TRANSACTION_FAIL';
export var CANCEL_TRANSACTION_START = 'CANCEL_TRANSACTION_START';
export var CANCEL_TRANSACTION_SUCCESS = 'CANCEL_TRANSACTION_SUCCESS';
export var CANCEL_TRANSACTION_FAIL = 'CANCEL_TRANSACTION_FAIL';
export var CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START';
export var CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export var CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
export var REFUND_TRANSACTION_START = 'REFUND_TRANSACTION_START';
export var REFUND_TRANSACTION_SUCCESS = 'REFUND_TRANSACTION_SUCCESS';
export var REFUND_TRANSACTION_FAIL = 'REFUND_TRANSACTION_FAIL';
export var CANCEL_AND_REFUND_TRANSACTION_START = 'CANCEL_AND_REFUND_TRANSACTION_START';
export var CANCEL_AND_REFUND_TRANSACTION_SUCCESS = 'CANCEL_AND_REFUND_TRANSACTION_SUCCESS';
export var CANCEL_AND_REFUND_TRANSACTION_FAIL = 'CANCEL_AND_REFUND_TRANSACTION_FAIL';
// export var FILTER_BY_PAYMENT_STATE_START = 'FILTER_BY_PAYMENT_STATE_START';
// export var FILTER_BY_PAYMENT_STATE_SUCCESS = 'FILTER_BY_PAYMENT_STATE_SUCCESS';
// export var FILTER_BY_PAYMENT_STATE_ERROR = 'FILTER_BY_PAYMENT_STATE_ERROR';
// export var FILTER_BY_PRODUCT_NAME_START = 'FILTER_BY_PRODUCT_NAME_START';
// export var FILTER_BY_PRODUCT_NAME_SUCCESS = 'FILTER_BY_PRODUCT_NAME_SUCCESS';
// export var FILTER_BY_PRODUCT_NAME_ERROR = 'FILTER_BY_PRODUCT_NAME_ERROR';
// export var FILTER_BY_DATE_START = 'FILTER_BY_DATE_START';
// export var FILTER_BY_DATE_SUCCESS = 'FILTER_BY_DATE_SUCCESS';
// export var FILTER_BY_DATE_ERROR = 'FILTER_BY_DATE_ERROR';
export var FILTER_BY_START = 'FILTER_BY_START';
export var FILTER_BY_SUCCESS = 'FILTER_BY_SUCCESS';
export var FILTER_BY_ERROR = 'FILTER_BY_ERROR';

// BUNDLES
export var GET_BUNDLES_START = 'GET_BUNDLES_START';
export var GET_BUNDLES_SUCCESS = 'GET_BUNDLES_SUCCESS';
export var GET_BUNDLES_FAIL = 'GET_BUNDLES_FAIL';
export var GET_BUNDLE_START = 'GET_BUNDLE_START';
export var GET_BUNDLE_SUCCESS = 'GET_BUNDLE_SUCCESS';
export var GET_BUNDLE_FAIL = 'GET_BUNDLE_FAIL';
// ASSETS
export var GET_ASSETS_START = 'GET_ASSETS_START';
export var GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export var GET_ASSETS_FAIL = 'GET_ASSETS_FAIL';
export var INVALIDATE_ASSETS = 'INVALIDATE_ASSETS';
// ASSET
export var GET_ASSET_START = 'GET_ASSET_START';
export var GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS';
export var GET_ASSET_FAIL = 'GET_ASSET_FAIL';
export var CREATE_ASSET_START = 'CREATE_ASSET_START';
export var CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export var CREATE_ASSET_FAIL = 'CREATE_ASSET_FAIL';
// COUPONS
export var GET_COUPONS_START = 'GET_COUPONS_START';
export var GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export var GET_COUPONS_ERROR = 'GET_COUPONS_ERROR';
export var CREATE_COUPON_START = 'CREATE_COUPON_START';
export var CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export var CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR';
export var DELETE_COUPON_START = 'DELETE_COUPON_START';
export var DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export var DELETE_COUPON_ERROR = 'DELETE_COUPON_ERROR';
export var UPDATE_COUPON_START = 'UPDATE_COUPON_START';
export var UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export var UPDATE_COUPON_ERROR = 'UPDATE_COUPON_ERROR';
// TASKS
export var GET_TASKS_START = 'GET_TASKS_START';
export var GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export var GET_TASKS_FAIL = 'GET_TASKS_FAIL';
export var GET_TASKS_BY_STATUS_START = 'GET_TASKS_BY_STATUS_START';
export var GET_TASKS_BY_STATUS_SUCCESS = 'GET_TASKS_BY_STATUS_SUCCESS';
export var GET_TASKS_BY_STATUS_FAIL = 'GET_TASKS_BY_STATUS_FAIL';
export var GET_TASK_START = 'GET_TASK_START';
export var GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export var GET_TASK_FAIL = 'GET_TASK_FAIL';
export var CREATE_TASK_START = 'CREATE_TASK_START';
export var CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export var CREATE_TASK_FAIL = 'CREATE_TASK_FAIL';
export var CLEAR_TASK_FAIL = 'CLEAR_TASK_FAIL';
export var UPDATE_TASK_START = 'UPDATE_TASK_START';
export var UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export var UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL';
export var DELETE_TASK_START = 'DELETE_TASK_START';
export var DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export var DELETE_TASK_FAIL = 'DELETE_TASK_FAIL';

// SEARCH
export var SEARCH_START = 'SEARCH_START';
export var SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export var SEARCH_ERROR = 'SEARCH_ERROR';

// VIEW
export var CHANGE_VIEW = 'CHANGE_VIEW';
export var GET_STORED_VIEW = 'GET_STORED_VIEW';

// REPORTS
export var GET_REPORT_START = 'GET_REPORT_START';
export var GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export var GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export var GET_REPORT_FAIL = 'GET_REPORT_FAIL';
export var REMOVE_REPORT = 'REMOVE_REPORT';

// CSV PARSING
export var ADD_USERS_COMPANY_SEATS_START = 'ADD_USERS_COMPANY_SEATS_START';
export var ADD_USERS_COMPANY_SEATS_SUCCESS = 'ADD_USERS_COMPANY_SEATS_SUCCESS';
export var ADD_USERS_COMPANY_SEATS_FAIL = 'ADD_USERS_COMPANY_SEATS_FAIL';

// SIDE MENU
export var TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
