import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    data: null,
    loading: false,
    error: null,
    errorResponse: null,
    roleChanged: false,
    passwordChanged: false
};

const start = ( state, action ) => {
    return updateObject( state, {
        data: null,
        loading: true,
        error: null
    } );
};

const success = ( state, action ) => {
    return updateObject( state, {
        data: action.data,
        loading: false,
        error: null
    } );
};

const fail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error,
        errorResponse: action.errorResponse
    } );
};

const clearFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        errorResponse: null
    })
};

const roleStart = ( state, action ) => {
    return updateObject( state, {
        roleChanged: false,
        loading: true,
        error: null
    } );
};

const roleSuccess = ( state, action ) => {
    return updateObject( state, {
        roleChanged: true,
        loading: false,
        error: null
    } );
};

const roleFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};

const passwordStart = ( state, action ) => {
    return updateObject( state, {
        passwordChanged: false,
        loading: true,
        error: null
    } );
};

const passwordSuccess = ( state, action ) => {
    return updateObject( state, {
        passwordChanged: true,
        loading: false,
        error: null
    } );
};

const passwordFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_START:
            return start(state, action);
        case actionTypes.GET_USER_SUCCESS:
            return success(state, action);
        case actionTypes.GET_USER_FAIL:
            return fail(state, action);
        case actionTypes.CREATE_USER_START:
            return start(state, action);
        case actionTypes.CREATE_USER_SUCCESS:
            return success(state, action);
        case actionTypes.CREATE_USER_FAIL:
            return fail(state, action);
        case actionTypes.CLEAR_USER_FAIL:
            return clearFail(state, action);
        case actionTypes.CHANGE_USER_ROLE_START:
            return roleStart(state, action);
        case actionTypes.CHANGE_USER_ROLE_SUCCESS:
            return roleSuccess(state, action);
        case actionTypes.CHANGE_USER_ROLE_FAIL:
            return roleFail(state, action);
        case actionTypes.CHANGE_USER_PASSWORD_START:
            return passwordStart(state, action);
        case actionTypes.CHANGE_USER_PASSWORD_SUCCESS:
            return passwordSuccess(state, action);
        case actionTypes.CHANGE_USER_PASSWORD_FAIL:
            return passwordFail(state, action);
        default:
            return state;
    }
};

export default reducer;