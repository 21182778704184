import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    data: null,
    loading: false,
    error: null,
    deleting: false,
    deleted: false
};

const start = ( state, action ) => {
    return updateObject( state, {
        data: null,
        loading: true,
        error: null
    } );
};

const success = ( state, action ) => {
    return updateObject( state, {
        data: action.data,
        loading: false,
        error: null
    } );
};

const fail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    });
};

const clearFail = (state, action) => {
    return updateObject(state, {
        error: null
    })
};

const deleteStart = ( state, action ) => {
    return updateObject( state, {
        deleting: true,
        deleted: false,
        error: null
    } );
};

const deleteSuccess = ( state, action ) => {
    return updateObject( state, {
        deleting: false,
        deleted: true,
        error: null
    } );
};

const deleteFail = ( state, action ) => {
    return updateObject( state, {
        deleting: false,
        error: action.error
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_PRODUCT_START: return start(state, action);
        case actionTypes.GET_PRODUCT_SUCCESS: return success(state, action);
        case actionTypes.GET_PRODUCT_FAIL: return fail(state, action);
        case actionTypes.UPDATE_PRODUCT_START: return start(state, action);
        case actionTypes.UPDATE_PRODUCT_SUCCESS: return success(state, action);
        case actionTypes.UPDATE_PRODUCT_FAIL: return fail(state, action);
        case actionTypes.CREATE_PRODUCT_START: return start(state, action);
        case actionTypes.CREATE_PRODUCT_SUCCESS: return success(state, action);
        case actionTypes.CREATE_PRODUCT_FAIL: return fail(state, action);
        case actionTypes.CLEAR_PRODUCT_FAIL: return clearFail(state, action);
        case actionTypes.DELETE_PRODUCT_START: return deleteStart(state, action);
        case actionTypes.DELETE_PRODUCT_SUCCESS: return deleteSuccess(state, action);
        case actionTypes.DELETE_PRODUCT_FAIL: return deleteFail(state, action);
        default:
            return state;
    }
};

export default reducer;