import {types} from "../shared/validators";

const formFields = [
    {
        key: "role",
        type: "select",
        options: {
            USER: "User",
            ADMIN: "Admin",
            LOOPER: "Looper",
            SUPER_ADMIN: "Super Admin",
        },
        validation: [
            {
                type: types.REQUIRED
            },
        ]
    }
];

export default formFields;