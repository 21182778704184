const SUPER_ADMIN = "SUPER_ADMIN";
const ADMIN = "ADMIN";
const LOOPER_ADMIN = "LOOPER_ADMIN";
const LOOPER = "LOOPER";

const permissions = {
    transactions: {
        get: [SUPER_ADMIN, ADMIN]
    },
    transaction: {
        get: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
        add: [SUPER_ADMIN, ADMIN],
        delete: [SUPER_ADMIN, ADMIN],
        cancel: [SUPER_ADMIN, ADMIN]
    },
    users: {
        get: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
    },
    user: {
        get: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
        add: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
        delete: [],
        changePassword: [SUPER_ADMIN],
        changeRole: [SUPER_ADMIN]
    },
    companies: {
        get: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
    },
    company: {
        get: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
        add: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
        delete: []
    },
    products: {
        get: [SUPER_ADMIN, ADMIN]
    },
    product: {
        get: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
        add: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
        delete: [SUPER_ADMIN, ADMIN]
    },
    "order-forms": {
        get: [SUPER_ADMIN, ADMIN],
        add: [SUPER_ADMIN, ADMIN],
        delete: [SUPER_ADMIN, ADMIN]
    },
    "order-form": {
        get: [SUPER_ADMIN, ADMIN]
    },
    "product-groups": {
        get: [SUPER_ADMIN, ADMIN],
        add: [SUPER_ADMIN, ADMIN],
        delete: [SUPER_ADMIN, ADMIN]
    },
    "coupons": {
        get: [SUPER_ADMIN, ADMIN],
        add: [SUPER_ADMIN, ADMIN],
        delete: [SUPER_ADMIN, ADMIN]
    },
    reports: {
        get: [SUPER_ADMIN, ADMIN]
    },
    "bundle-users": {
        add: [SUPER_ADMIN, ADMIN]
    },
    tasks: {
        get: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
        add: [SUPER_ADMIN, ADMIN, LOOPER_ADMIN, LOOPER],
        delete: [SUPER_ADMIN, ADMIN]
    }
};

export default permissions
