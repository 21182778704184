import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getCompaniesStart = () => {
    return {
        type: actionTypes.GET_COMPANIES_START
    }
};

export const getCompaniesSuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANIES_SUCCESS,
        data: data.content,
        pages: data.totalPages,
        currentPage: data.pageable.pageNumber
    }
};

export const getCompaniesFail = (error) => {
    return {
        type: actionTypes.GET_COMPANIES_FAIL,
        error: error
    }
};

export const invalidateCompanies = () => {
    return {
        type: actionTypes.INVALIDATE_COMPANIES
    }
};

export const getCompaniesByEmail = (email) => {
    return dispatch => {
        dispatch(getCompaniesStart());
        getData(
            "/companies?email=" + email,
            response => {
                dispatch(getCompaniesSuccess(response.data))
            },
            error => {
                dispatch(getCompaniesFail(error.response.status))
            },
            dispatch);
    };
};

export const getCompanyList = (idList) => {
    return dispatch => {
        dispatch(getCompaniesStart());
        getData(
            "/companies/list?list=" + idList.join(","),
            response => {
                dispatch(getCompaniesSuccess(response.data))
            },
            error => {
                dispatch(getCompaniesFail(error.response.status))
            },
            dispatch);
    };
};

export const getFilteredCompanies = (search, value) => {
    return fetchCompanies(null, null, null, search, value);
};

export const getCompanies = (page, sort, dir) => {
    return fetchCompanies(page, sort, dir, null, null);
};

const fetchCompanies = (page, sort, dir, search, value) => {
    let subUrl = "/companies?page=" + page;

    if (sort && dir) {
        subUrl += `&sort=${sort}&dir=${dir}`;
    }

    if (search && value) {
        subUrl = `/companies?search=${search}&value=${value}`;
    }

    return dispatch => {
        dispatch(getCompaniesStart());
        getData(
            subUrl,
            response => {
                dispatch(getCompaniesSuccess(response.data))
            },
            error => {
                dispatch(getCompaniesFail(error.response.status))
            },
            dispatch);
    };
};