import React from 'react';
import {connect} from 'react-redux';
import {searchAll} from "../../store/actions/search";
import '../../styles/components/Search.scss';

class Search extends React.Component {
    state = {
        value: ''
    };

    componentDidMount() {
        this.input.focus();
    }

    submitSearch(e) {
        e.preventDefault();
        this.props.dispatch(searchAll(this.state.value));
        this.props.history.push('/search-results/' + this.state.value);
        this.setState({value: ''});
    }

    render() {
        return (
            <div className="search-form">
                <form onSubmit={e => this.submitSearch(e)}>
                    <input
                        type="text"
                        onChange={e => this.setState({value: e.target.value})}
                        value={this.state.value}
                        className="search-input"
                        ref={input => this.input = input}
                    />
                    <button
                        type="submit"
                        className="button"
                    >
                        Search
                    </button>
                </form>
            </div>
        )
    }
}

export default connect()(Search);

