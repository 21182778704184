import * as actionTypes from './actionTypes';
import {getData} from "../../shared/utility";

export const searchStart = () => ({
    type: actionTypes.SEARCH_START
});

export const searchSuccess = searchResults => {
    return {
        type: actionTypes.SEARCH_SUCCESS,
        userSearchResults: searchResults.users,
        productSearchResults: searchResults.products,
        productGroupSearchResults: searchResults.productGroups
    };
};

export const searchError = error => ({
    type: actionTypes.SEARCH_ERROR,
    error
});

export const searchAll = value => dispatch => {
    dispatch(searchStart());
    getData(
        '/search/' + value,
        response => {
            console.log(response);
            dispatch(searchSuccess(response.data));
        },
        error => {
            console.log(error);
            dispatch(searchError(error));
        }
    )
};