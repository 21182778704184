import * as actionTypes from "./actionTypes";
import {getData, postData} from "../../shared/utility";

export const getAssetStart = () => {
    return {
        type: actionTypes.GET_ASSET_START
    }
};

export const getAssetSuccess = (data) => {
    return {
        type: actionTypes.GET_ASSET_SUCCESS,
        data: data
    }
};

export const getAssetFail = (error) => {
    return {
        type: actionTypes.GET_ASSET_FAIL,
        error: error
    }
};

export const createAssetStart = () => {
    return {
        type: actionTypes.CREATE_ASSET_START
    }
};

export const createAssetSuccess = (data) => {
    return {
        type: actionTypes.CREATE_ASSET_SUCCESS,
        data: data
    }
};

export const createAssetFail = (error) => {
    return {
        type: actionTypes.CREATE_ASSET_FAIL,
        error: error
    }
};

export const getAsset = (id) => {
    return dispatch => {
        dispatch(getAssetStart());
        getData(
            "/assets/" + id,
            response => {
                dispatch(getAssetSuccess(response.data))
            },
            error => {
                dispatch(getAssetFail(error.response.status))
            },
            dispatch);
    };
};

export const createAsset = (data) => {
    return dispatch => {
        dispatch(createAssetStart());
        postData(
            "/assets",
            data,
            response => {
                dispatch(createAssetSuccess(response.data))
            },
            error => {
                dispatch(createAssetFail(error.response.status))
            },
            dispatch
        );
    };
};