import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    productGroup: null,
    loading: false,
    error: null
};

const createProductGroupStart = (state, action) => {
    return updateObject(state, {
        loading: true
    });
};

const createProductGroupSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        productGroup: action.productGroup
    });
};

const createProductGroupError = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const clearProductGroupError = (state, action) => {
    return updateObject(state, {
        error: null
    })
};

const updateProductGroupStart = (state, action) => {
    return updateObject(state, {
        loading: true
    });
};

const updateProductGroupSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        productGroup: action.newData
    });
};

const updateProductGroupError = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case actionTypes.CREATE_PRODUCT_GROUP_START:
            return createProductGroupStart(state, action);
        case actionTypes.CREATE_PRODUCT_GROUP_SUCCESS:
            return createProductGroupSuccess(state, action);
        case actionTypes.CREATE_PRODUCT_GROUP_ERROR:
            return createProductGroupError(state, action);
        case actionTypes.CLEAR_PRODUCT_GROUP_ERROR:
            return clearProductGroupError(state, action);
        case actionTypes.UPDATE_PRODUCT_GROUP_START:
            return updateProductGroupStart(state, action);
        case actionTypes.UPDATE_PRODUCT_GROUP_SUCCESS:
            return updateProductGroupSuccess(state, action);
        case actionTypes.UPDATE_PRODUCT_GROUP_ERROR:
            return updateProductGroupError(state, action);
        default:
            return state;
    }
};

export default reducer;