import React, {Component} from 'react';
import {connect} from "react-redux";
import {getTransactionById, cancelSubscription, refundTransaction, cancelAndRefundTransaction} from "../../store/actions/transaction";
import {getProducts} from "../../store/actions";
import {cancelTransaction} from "../../store/actions";
import KeyValueBlock from "../KeyValueBlock/KeyValueBlock";
import Price from "../Price/Price";
import DateTime from "../Date/Date";
import {ClipLoader} from 'react-spinners';
import PopupDialog from "../PopupDialog/PopupDialog";
import '../../styles/components/Transactions.scss'
import View from './View';

class Transaction extends Component {
    state = {
        cancelDialogOpen: false,
        cancelSubscriptionDialogueOpen: false,
        refundTransactionDialogueOpen: false,
        cancelAndRefundSubscriptionDialogueOpen: false
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getTransactionById(this.props.match.params.id);
        }

        if (!this.props.products) {
            this.props.getProducts();
        }
    }

    openCancelDialog() {
        this.setState({
            cancelDialogOpen: true
        })
    }

    closeCancelDialog() {
        this.setState({
            cancelDialogOpen: false
        })
    }

    cancelTransaction() {
        this.props.cancelTransaction(this.props.match.params.id);
    }

    refundTransaction() {
        this.props.refundTransaction(this.props.match.params.id);
    }

    render() {
        if (!this.props.products) {
            return null;
        }

        if (this.props.transaction) {
            let link, id;

            // getting the id needed to link users to Stripe
            if (this.props.transaction.transactionId !== null) {
                id = this.props.transaction.transactionId;
            }

            if (this.props.transaction.subscriptionId !== null) {
                id = this.props.transaction.subscriptionId;
            }

            if (this.props.transaction.paymentProvider === 'BRAINTREE') {
                link = <a href={'https://www.braintreegateway.com/merchants/nnqdv9jfdfjfkgtc/transactions/' + id} target='_blank' rel='noopener noreferrer'>BrainTree Page</a>;
            } else if (this.props.transaction.paymentProvider === 'STRIPE') {
                link = <a href={'https://dashboard.stripe.com/payments/' + id} target='_blank' rel='noopener noreferrer'>Stripe Page</a>;
            }

            let buttons;
            if (this.props.transaction.subscriptionId) {
                buttons = (
                    <div className="transactions-button-container">
                        <button type="button" className="button cancel-transaction-btn" onClick={() => this.setState({cancelSubscriptionDialogueOpen: true})}>Cancel Subscription</button>
                        <button type="button" className="button refund-transaction-btn" onClick={() => this.setState({refundTransactionDialogueOpen: true})}>Refund Subscription</button>
                        <button type="button" className="button cancel-and-refund-btn" onClick={() => this.setState({cancelAndRefundSubscriptionDialogueOpen: true})}>Cancel & Refund Subscription</button>
                    </div>
                );
            } else {
                buttons = <button type="button" className="button refund-transaction-btn" onClick={() => this.setState({refundTransactionDialogueOpen: true})}>Refund Transaction</button>
            }

            let translations = {
                tag: "Tag",
                buyPrice: "Price",
                paymentState: "Payment State",
                paymentProvider: "Payment Provider",
                link: "Link",
                marketingCampaign: "Marketing Campaign",
                created: "Created"
            };
            const data = Object.assign({}, this.props.transaction, {
                "tag": `${this.props.products[this.props.transaction.productId].tag}`,
                "created": <DateTime date={this.props.transaction.created}/>,
                "buyPrice": <Price price={this.props.transaction.buyPrice}/>,
                "link": link
            });

            let actions = null;
            if (data.tag.match("slp-certification-sub")) {
                actions =
                    [
                        {
                            name: "Cancel Plan",
                            action: () => this.openCancelDialog()
                        }
                    ];
            }

            let popupDialogue = null;
            if (this.state.cancelSubscriptionDialogueOpen) {
                popupDialogue = (
                    <PopupDialog
                        close={() => this.setState({cancelSubscriptionDialogueOpen: false})}
                        message="Are you sure you want to cancel this subscription?"
                        yes="Cancel Subscription"
                        no="Never mind"
                        yesClicked={() => {
                            this.setState({cancelSubscriptionDialogueOpen: false});
                            this.props.cancelSubscription(this.props.transaction.subscriptionId);
                        }}
                    />
                );
            }

            if (this.state.cancelDialogOpen) {
                popupDialogue = (
                    <PopupDialog
                        close={() => this.closeCancelDialog()}
                        message="Are you sure you want to cancel this payment plan?"
                        yes="Cancel Payment Plan"
                        no="Never mind"
                        yesClicked={() => this.cancelTransaction()}
                    />
                );
            }

            if (this.state.refundTransactionDialogueOpen) {
                popupDialogue = (
                    <PopupDialog
                        close={() => this.setState({refundTransactionDialogueOpen: false})}
                        message="Are you sure you want to refund this transaction?"
                        yes="Refund Transaction"
                        no="Never mind"
                        yesClicked={() => {
                            this.setState({refundTransactionDialogueOpen: false});
                            this.refundTransaction();
                        }}
                    />
                );
            }

            if (this.state.cancelAndRefundSubscriptionDialogueOpen) {
                popupDialogue = (
                    <PopupDialog
                        close={() => this.setState({cancelAndRefundSubscriptionDialogueOpen: false})}
                        message="Are you sure you want to cancel & refund this subscription?"
                        yes="Cancel & Refund Subscription"
                        no="Never mind"
                        yesClicked={() => {
                            this.setState({cancelAndRefundSubscriptionDialogueOpen: false});
                            this.props.cancelAndRefundTransaction(this.props.transaction.subscriptionId, this.props.transaction.id);
                        }}
                    />
                );
            }

            return (
                <React.Fragment>
                    {
                        this.props.loading ?
                        <ClipLoader sizeUnit="px" size={100} color="black"/>
                        : null
                    }
                    {popupDialogue}
                    <View history={this.props.history}/>
                    <KeyValueBlock
                        data={data}
                        keys={["tag", "buyPrice", "paymentState", "paymentProvider", "link", "marketingCampaign", "created"]}
                        translations={translations}
                        actions={actions}
                    />
                    {buttons}
                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    transaction: state.transaction.data,
    loading: state.transaction.loading,
    products: state.products.indexedData,
    cancelling: state.transaction.cancelling,
    canceled: state.transaction.canceled,
    refunding: state.transaction.refunding,
    refunded: state.transaction.refunded
});

const mapDispatchToProps = dispatch => {
    return {
        getTransactionById: (id) => dispatch(getTransactionById(id)),
        getProducts: () => dispatch(getProducts()),
        cancelTransaction: (id) => dispatch(cancelTransaction(id)),
        cancelSubscription: (subscriptionId) => dispatch(cancelSubscription(subscriptionId)),
        refundTransaction: (id) => dispatch(refundTransaction(id)),
        cancelAndRefundTransaction: (subscriptionId, id) => dispatch(cancelAndRefundTransaction(subscriptionId, id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
