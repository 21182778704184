import React from 'react';
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

class DateRangeChooser extends React.Component {
   state = {
       showDateRangePicker: false
   };

   render() {
        return (
            <div className="date-container">
                <label htmlFor="date-checkbox">Filter By Date</label>
                <input
                    type="checkbox"
                    id="date-checkbox"
                    onClick={() => this.setState({showDateRangePicker: !this.state.showDateRangePicker})}
                /><br/>
                {
                    this.state.showDateRangePicker ?
                    <DateRangePicker
                        onChange={this.props.setDateRange}
                        value={this.props.dates}
                    /> :
                    null
                }
            </div>
        )
    }
};

export default DateRangeChooser;