import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    loading: false,
    error: null,
};

const loginStart = ( state, action ) => {
    return updateObject( state, {
        token: null,
        loading: true,
        error: null
    });
};

const loginSuccess = ( state, action ) => {
    return updateObject( state, {
        token: action.token,
        loading: false,
        error: null
    });
};

const loginFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    });
};

const logout = ( state, action ) => {
    return updateObject( state, {
        token: null,
        loading: false,
        error: null
    });
};

const clearLoginError = (state, action) => {
    return updateObject(state, {
        error: null
    })
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.LOGIN_START: return loginStart(state, action);
        case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
        case actionTypes.LOGIN_FAIL: return loginFail(state, action);
        case actionTypes.LOGOUT: return logout(state, action);
        case actionTypes.CLEAR_LOGIN_ERROR: return clearLoginError(state, action);
        default:
            return state;
    }
};

export default reducer;