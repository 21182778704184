import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    productGroups: null,
    indexedGroups: null,
    loading: false,
    error: null
};

const getProductGroupsStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })  ;
};

const getProductGroupsSuccess = (state, action) => {
    const groupsObj = {};
    action.groups.forEach(group => groupsObj[group.id] = group);

    return updateObject(state, {
        loading: false,
        productGroups: action.groups,
        indexedGroups: groupsObj
    });
};

const createProductGroupSuccess = (state, action) => {
    const groupsObj = {};
    const updatedGroups = [...state.productGroups, action.productGroup];
    updatedGroups.forEach(group => groupsObj[group.id] = group);

     return updateObject(state, {
         loading: false,
         productGroups: [...state.productGroups, action.productGroup],
         indexedGroups: groupsObj
     });
};

const updateProductGroupSuccess = (state, action) => {
    const toUpdate = state.indexedGroups[action.newData.id];
    const index = state.productGroups.indexOf(toUpdate);
    const groupsObj = {};
    state.productGroups.splice(index, 1, action.newData);
    state.productGroups.forEach(group => groupsObj[group.id] = group);

    return updateObject(state, {
        productGroups: state.productGroups,
        indexedGroups: groupsObj
    });
};

const getProductGroupsError = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCT_GROUPS_START: return getProductGroupsStart(state, action);
        case actionTypes.GET_PRODUCT_GROUPS_SUCCESS: return getProductGroupsSuccess(state, action);
        case actionTypes.CREATE_PRODUCT_GROUP_SUCCESS: return createProductGroupSuccess(state, action);
        case actionTypes.UPDATE_PRODUCT_GROUP_SUCCESS: return updateProductGroupSuccess(state, action);
        case actionTypes.GET_PRODUCT_GROUPS_ERROR: return getProductGroupsError(state, action);
        default:
            return state;
    }
};

export default reducer;