import React from 'react';
import {connect} from 'react-redux';

const IssuersOptions = props => {
    const issuersArray = [];
    props.data.forEach(({ issuerId, issuerName }) => {
        const obj = { issuerId, issuerName };
        issuersArray.push(obj);
    });

    const uniq = new Set(issuersArray.map(e => JSON.stringify(e)));
    const res = Array.from(uniq).map(e => JSON.parse(e));

    let options = res.map((issuer, index) =>
        <option value={issuer.issuerId} key={index}>
            {issuer.issuerName}
        </option>
    );

    return (
        <div className="issuer-container">
            <label htmlFor="issuer-options">Filter By Issuer</label><br/>
            <select
                id="issuer-options"
                onChange={e => props.setIssuer(e.target.value)}
                value={props.currentIssuer === null ? '---' : props.currentIssuer}
            >
                <option>---</option>
                {options}
            </select>
        </div>
    )
};

const mapStateToProps = state => ({
    data: state.orderForms.data,
});

export default connect(mapStateToProps)(IssuersOptions);