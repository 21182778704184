import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
    coupon: null,
    loading: false,
    error: null
};

const createCouponStart = (state, action) => {
    return updateObject(state, {
        loading: true
    });
};

const createCouponSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        coupon: action.coupon
    });
};

const createCouponError = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case actionTypes.CREATE_COUPON_START :
            return createCouponStart(state, action);

        case actionTypes.CREATE_COUPON_SUCCESS :
            return createCouponSuccess(state, action);

        case actionTypes.CREATE_COUPON_ERROR :
            return createCouponError(state, action);

        default :
            return state;
    }
};

export default reducer;
