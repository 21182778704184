import React, {useState} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {ClipLoader} from "react-spinners";
import {FaTimes} from "react-icons/fa";
import {clearLoginError} from "../../store/actions/auth";
import '../../styles/components/Login.scss';

const Login = (props) => {
    const [username, setUsernameState] = useState("");
    const setUsername = (event) => {
        setUsernameError("");
        setUsernameState(event.target.value);
    };
    const [password, setPasswordState] = useState("");
    const setPassword = (event) => {
        setPasswordError("");
        setPasswordState(event.target.value);
    };
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const login = () => props.login({username: username, password: password});

    const clickOrKeySubmit = e => {
        e.preventDefault();
        if (username.length === 0) {
            setUsernameError('Please enter username..');
        }

        if (password.length === 0) {
            setPasswordError('Please enter password..');
        }

        if (username.length > 0 && password.length > 0) {
            login();
        }
    };

    if (props.loggingIn) {
        return (
            <div className="root">
                <ClipLoader sizeUnit="px" size={100} color="black"/>
                <h1>Logging In...</h1>
            </div>
        );
    }

    if (props.error) {
        return (
            <div className="root">
                <FaTimes size={100}/>
                <h3>{props.error}</h3>
                <button
                    type="button"
                    className="button"
                    onClick={() => props.clearLoginError()}
                >
                    Okay
                </button>
            </div>
        )
    }

    return (
        <div className="root">
            <form onSubmit={clickOrKeySubmit}>
                <div className="login-form">
                    <h1 className="login-header">Welcome to the Wolf Dashboard</h1>
                    <h3 className="login-header">Log in below to begin</h3>

                    <input className="input" type="text" placeholder="Enter username" value={username} onChange={setUsername}/>
                    {usernameError ? <span>{usernameError}</span> : null}
                    <input className="input" type="password" placeholder="Enter password" value={password} onChange={setPassword}/>
                    {passwordError ? <span>{passwordError}</span> : null}

                    <button type="submit" className="button login-button">Login</button>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        authToken: state.auth.token,
        loggingIn: state.auth.loading,
        error: state.auth.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkLogin: () => dispatch(actions.checkToken()),
        login: (credentials) => dispatch(actions.login(credentials)),
        clearLoginError: () => dispatch(clearLoginError())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);