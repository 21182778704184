import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    list: [],
    loading: false,
    error: null,
};

const start = ( state, action ) => {
    return updateObject( state, {
        loading: true,
        error: null
    } );
};

const success = ( state, action ) => {
    const newList = state.list.slice();
    newList.push(action.report);
    return updateObject( state, {
        list: newList,
        loading: false,
        error: null
    } );
};

const fail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};

const remove = ( state, action ) => {
    return updateObject( state, {
        list: state.list.filter(r => r.fileName !== action.fileName)
    } );
};

const refresh = ( state, action ) => {
    return updateObject( state, {
        list: action.reports,
        loading: false,
        error: null
    } );
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_REPORT_START:
            return start(state, action);
        case actionTypes.GET_REPORT_SUCCESS:
            return success(state, action);
        case actionTypes.GET_REPORT_FAIL:
            return fail(state, action);
        case actionTypes.REMOVE_REPORT:
            return remove(state, action);
        case actionTypes.GET_REPORTS_SUCCESS:
            return refresh(state, action);
        default:
            return state;
    }
};

export default reducer;