import React from 'react';

const Price = props => {
    if (!props.price) {
        return null;
    } else {
        const price = props.price
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

        return <React.Fragment>${price}</React.Fragment>;
    }
};

export default Price;

