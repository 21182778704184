import React, {Component} from 'react';
import PopupForm from "../PopupForm/PopupForm";
import assetFields from "../../forms/asset";
import TableView from "../TableView/TableView";
import * as actions from "../../store/actions";
import {connect} from "react-redux";

class Assets extends Component {

    state = {
        formOpen: false
    };

    componentDidMount() {
        this.props.getAssets();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.assetCreated && this.props.assetCreated) {
            this.props.history.push('/asset/' + this.props.asset.id)
        }
    }

    openForm() {
        this.setState({
            formOpen: true
        })
    }

    closeForm() {
        this.setState({
            formOpen: false
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.formOpen ?
                    <PopupForm
                        fields={assetFields}
                        onSubmit={(data) => this.props.createAsset(data)}
                        close={() => this.closeForm()}
                    /> : null}
                <TableView
                    data={this.props.data}
                    keys={["name", "created"]}
                    headers={{
                        name: "Asset Name",
                        created: "Created"
                    }}
                    rowClick={(id) => this.props.history.push('/asset/' + id)}
                    addClick={() => this.openForm()}
                />
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.assets.loading,
        data: state.assets.data,
        error: state.assets.error,
        assetCreated: state.asset.data && !state.asset.error,
        asset: state.asset.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAssets: () => dispatch(actions.getAssets()),
        createAsset: (data) => dispatch(actions.createAsset(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assets);