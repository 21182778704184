import React from 'react';
import {connect} from 'react-redux';
import {getCoupons} from "../../store/actions/coupons";
import {createCoupon} from "../../store/actions/coupon";
import TableView from "../TableView/TableView";
import PopupForm from "../PopupForm/PopupForm";
import {couponFormFields} from '../../forms/coupon';
import Price from '../Price/Price';
import Card from '../Card/Card';
import {formatCouponCard} from "../Card/FormatCard";
import { ClipLoader } from 'react-spinners';

class Coupons extends React.Component {
    state = {
        formOpen: false
    };

    componentDidMount() {
        if (!this.props.coupons) {
            this.props.dispatch(getCoupons());
        }
    }

    formatCouponData(data) {
        const formattedData = Object.assign({}, data, {
            priceReduction: data.discount
        });
        this.props.dispatch(createCoupon(formattedData));
        this.setState({formOpen: false});
    }

    render() {
        let coupons;
        if (this.props.coupons) {
            coupons = this.props.coupons.map(coupon =>
                Object.assign({}, coupon, {
                    code: coupon.code,
                    type: coupon.type,
                    active: coupon.active.toString(),
                    discount: coupon.type === 'FLAT_RATE' ?
                        <Price price={coupon.priceReduction}/> :
                        coupon.priceReduction + '%'
                })
            );
        }

        return (
            <React.Fragment>
                <button
                    className="button"
                    type="button"
                    onClick={() => this.setState({formOpen: true})}
                >
                    Add New Coupon
                </button>
                {
                    this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/> :
                    null
                }
                {
                    this.state.formOpen ?
                    <PopupForm
                        fields={couponFormFields}
                        prefill={coupons}
                        onSubmit={data => this.formatCouponData(data)}
                        close={() => this.setState({formOpen: false})}
                    /> :
                    null
                }
                {
                    this.props.view === 'card' ?
                    <Card
                       data={coupons}
                       format={coupons => formatCouponCard(coupons)}
                       url={'/coupon/'}
                    /> :
                    <TableView
                        data={coupons}
                        headers={{code: 'Code', type: 'Type', discount: 'Discount', active: 'Active'}}
                        keys={['code', 'type', 'discount', 'active']}
                        rowClick={id => this.props.history.push('/coupon/' + id)}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    coupons: state.coupons.coupons,
    indexedCoupons: state.coupons.indexedCoupons,
    loading: state.coupons.loading,
    view: state.view.view
});

export default connect(mapStateToProps)(Coupons);
