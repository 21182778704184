import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getUsersStart = () => {
    return {
        type: actionTypes.GET_USERS_START
    }
};

export const getUsersSuccess = (data) => {
    return {
        type: actionTypes.GET_USERS_SUCCESS,
        data: data.content,
        pages: data.totalPages,
        currentPage: data.pageable.pageNumber
    }
};




export const getUsersFail = (error) => {
    return {
        type: actionTypes.GET_USERS_FAIL,
        error: error
    }
};

export const invalidateUsers = () => {
    return {
        type: actionTypes.INVALIDATE_USERS
    }
};

export const getUsersByEmail = (email) => {
    return dispatch => {
        dispatch(getUsersStart());
        getData(
            "/users?email=" + email,
            response => {
                dispatch(getUsersSuccess(response.data))
            },
            error => {
                dispatch(getUsersFail(error.response.status))
            },
            dispatch);
    };
};


export const getUserList = (idList) => {
    return dispatch => {
        dispatch(getUsersStart());
        getData(
            "/users/list?list=" + idList.join(","),
            response => {
                dispatch(getUsersSuccess(response.data))
            },
            error => {
                dispatch(getUsersFail(error.response.status))
            },
            dispatch);
    };
};

export const getFilteredUsers = (search, value) => {
    return fetchUsers(null, null, null, search, value);
};

export const getUsers = (page, sort, dir) => {
    return fetchUsers(page, sort, dir, null, null);
};

const fetchUsers = (page, sort, dir, search, value) => {
    let subUrl = "/users?page=" + page;

    if (sort && dir) {
        subUrl += `&sort=${sort}&dir=${dir}`;
    }

    if (search && value) {
        subUrl = `/users?search=${search}&value=${value}`;
    }

    return dispatch => {
        dispatch(getUsersStart());
        getData(
            subUrl,
            response => {
                dispatch(getUsersSuccess(response.data))
            },
            error => {
                dispatch(getUsersFail(error.response.status))
            },
            dispatch);
    };
};