import * as actionTypes from "./actionTypes";
import {getData, postData} from "../../shared/utility";

export const createOrderFormStart = () => {
    return {
        type: actionTypes.CREATE_ORDER_FORM_START
    }
};

export const createOrderFormSuccess = (data) => {
    return {
        type: actionTypes.CREATE_ORDER_FORM_SUCCESS,
        data: data
    }
};

export const createOrderFormFail = (error) => {
    return {
        type: actionTypes.CREATE_ORDER_FORM_FAIL,
        error: error
    }
};

export const getOrderFormStart = () => {
    return {
        type: actionTypes.GET_ORDER_FORM_START
    }
};

export const getOrderFormSuccess = (data) => {
    return {
        type: actionTypes.GET_ORDER_FORM_SUCCESS,
        data: data
    }
};

export const getOrderFormFail = (error) => {
    return {
        type: actionTypes.GET_ORDER_FORM_FAIL,
        error: error
    }
};

export const createOrderForm = (userId, data) => {
    return dispatch => {
        dispatch(createOrderFormStart());
        postData(
            "/order-forms/" + userId,
            data,
            response => {
                dispatch(createOrderFormSuccess(response.data))
            },
            error => {
                dispatch(createOrderFormFail(error.response.status))
            },
            dispatch);
    };
};

export const getOrderForm = (formId) => {
    return dispatch => {
        dispatch(getOrderFormStart());
        getData(
            "/order-forms/" + formId,
            response => {
                dispatch(getOrderFormSuccess(response.data))
            },
            error => {
                dispatch(getOrderFormFail(error.response.status))
            },
            dispatch);
    };
};