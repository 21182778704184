import * as actionTypes from "./actionTypes";
import {getData, postData} from "../../shared/utility";

export const getCompanyStart = () => {
    return {
        type: actionTypes.GET_COMPANY_START
    }
};

export const getCompanySuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANY_SUCCESS,
        data: data
    }
};

export const getCompanyFail = (error) => {
    return {
        type: actionTypes.GET_COMPANY_FAIL,
        error: error
    }
};

export const getPaymentLinkStatusStart = () => {
    return {
        type: actionTypes.GET_PAYMENT_LINK_STATUS_START
    }
};

export const getPaymentLinkStatusSuccess = (data) => {
    return {
        type: actionTypes.GET_PAYMENT_LINK_STATUS_SUCCESS,
        data: data
    }
};

export const getPaymentLinkStatusFail = (error) => {
    return {
        type: actionTypes.GET_PAYMENT_LINK_STATUS_FAIL,
        error: error
    }
};

export const createCompanyStart = () => {
    return {
        type: actionTypes.CREATE_COMPANY_START
    }
};

export const createCompanySuccess = (data) => {
    return {
        type: actionTypes.CREATE_COMPANY_SUCCESS,
        data: data
    }
};

export const createCompanyFail = (error) => {
    return {
        type: actionTypes.CREATE_COMPANY_FAIL,
        error: error
    }
};
export const getPaymentLinkStatus = (id) => {
    return dispatch => {
        dispatch(getPaymentLinkStatusStart());
        getData(
            "/adobesign/" + id + "/info",
            response => {
                dispatch(getPaymentLinkStatusSuccess(response.data))
            },
            error => {
                dispatch(getPaymentLinkStatusFail(error.response.status))
            },
            dispatch);
    };
};
        
export const getCompany = (id) => {
    return dispatch => {
        dispatch(getCompanyStart());
        getData(
            "/companies/" + id,
            response => {
                dispatch(getCompanySuccess(response.data))
            },
            error => {
                dispatch(getCompanyFail(error.response.status))
            },
            dispatch);
    };
};

export const createCompany = (data) => {
    return dispatch => {
        dispatch(createCompanyStart());
        postData(
            "/companies",
            data,
            response => {
                dispatch(createCompanySuccess(response.data))
            },
            error => {
                dispatch(createCompanyFail(error.response.status))
            },
            dispatch
        );
    };
};

export const updateCompany = (companyId, data) => {
    return dispatch => {
        dispatch(createCompanyStart());
        postData(
            "/companies/" + companyId,
            data,
            response => {
                dispatch(createCompanySuccess(response.data))
            },
            error => {
                dispatch(createCompanyFail(error.response.status))
            },
            dispatch
        );
    };
};