import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
    coupons: null,
    indexedCoupons: null,
    loading: false,
    updating: false,
    deleting: false,
    deleted: false,
    error: null
};

const getCouponsStart = (state, action) => {
    return updateObject(state, {
        loading: true
    });
};

const getCouponsSuccess = (state, action) => {
    let couponsObj = {};
    action.coupons.forEach(coupon => couponsObj[coupon.id] = coupon);

    return updateObject(state, {
        loading: false,
        coupons: action.coupons,
        indexedCoupons: couponsObj
    });
};

const getCouponsError = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const createCouponSuccess = (state, action) => {
    state.indexedCoupons[action.coupon.id] = action.coupon;

    return updateObject(state, {
        coupons: [action.coupon, ...state.coupons]
    });
};

const deleteCouponStart = (state, action) => {
    return updateObject(state, {
        deleting: true
    });
};

const deleteCouponSuccess = (state, action) => {
    const filteredObj = {};
    const filteredCoupons = state.coupons.filter(coupon => coupon.id !== parseInt(action.id));
    filteredCoupons.forEach(coupon => filteredObj[coupon.id] = coupon);

    return updateObject(state, {
        coupons: filteredCoupons,
        indexedCoupons: filteredObj,
        deleting: false,
        deleted: true
    });
};

const deleteCouponError = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const updateCouponStart = (state, action) => {
    return updateObject(state, {
        updating: true
    })
};

const updateCouponSuccess = (state, action) => {
    const index = state.coupons.findIndex(coupon => coupon.id === action.data.id);
    state.coupons.splice(index, 1, action.data);

    const indexedCoupons = {};
    state.coupons.forEach(coupon => indexedCoupons[coupon.id] = coupon);

    return updateObject(state, {
        updating: false,
        coupons: state.coupons,
        indexedCoupons: indexedCoupons
    });
};

const updateCouponError = (state, action) => {
    return updateObject(state, {
        error: action.error,
        updating: false
    })
};

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case(actionTypes.GET_COUPONS_START) :
            return getCouponsStart(state, action);
        case(actionTypes.GET_COUPONS_SUCCESS) :
            return getCouponsSuccess(state, action);
        case(actionTypes.CREATE_COUPON_SUCCESS) :
            return createCouponSuccess(state, action);
        case(actionTypes.GET_COUPONS_ERROR) :
            return getCouponsError(state, action);
        case actionTypes.DELETE_COUPON_START :
            return deleteCouponStart(state, action);
        case actionTypes.DELETE_COUPON_SUCCESS :
            return deleteCouponSuccess(state, action);
        case actionTypes.DELETE_COUPON_ERROR :
            return deleteCouponError(state, action);
        case actionTypes.UPDATE_COUPON_START :
            return updateCouponStart(state, action);
        case actionTypes.UPDATE_COUPON_SUCCESS :
            return updateCouponSuccess(state, action);
        case actionTypes.UPDATE_COUPON_ERROR :
            return updateCouponError(state, action);
        default :
            return state;
    }
};

export default reducer;
