import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import PopupForm from "../PopupForm/PopupForm";
import formFields from "../../forms/orderFormReport";
import TableView from "../TableView/TableView";
import DateTime from "../Date/Date";
import {getReportDownloadLink} from "../../shared/utility";

class Reports extends Component {

    state = {
        formShowing: false
    };

    componentDidMount() {
        this.props.getAllReports();
    }

    requestReport(data) {
        this.setState({formShowing: false});
        const startDate = data.startDate + "T00:00:00.000";
        const endDate = data.endDate + "T23:59:59.000";
        this.props.requestReport(data.reportType, startDate, endDate);
    }

    formatReport(report) {
        return (
            <React.Fragment>
                <div className="card-title">
                    <h3 className="user-name">{report.type}</h3>
                </div>
                <p className="user-role">From: {report.startDateTime}</p>
                <p className="user-phone">To: {report.endDateTime}</p>
                <p className="user-email">Created: {report.createdDateTime}</p>
                <a
                    href={getReportDownloadLink(report.fileName)}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => this.props.removeReport(report.fileName)}
                >Download</a>
            </React.Fragment>
        );
    }

    render() {
        let data = this.props.downloadableReports.map(r => {
            r["url"] = <a
                href={getReportDownloadLink(r.fileName)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => this.props.removeReport(r.fileName)}>
                Download
            </a>;

            r["createdDateTime"] = <DateTime date={r.createdDateTime}/>;
            r["startDateTime"] = <DateTime date={r.startDateTime}/>;
            r["endDateTime"] = <DateTime date={r.endDateTime}/>;
            r["id"] = r.fileName;
            return r
        });

        return (
            <React.Fragment>
                {this.state.formShowing ?
                    <PopupForm
                        close={() => this.setState({formShowing: false})}
                        fields={formFields}
                        onSubmit={(data) => this.requestReport(data)}
                    /> : null
                }
                <button onClick={() => this.setState({formShowing: true})}>Get order-form report</button>
                {
                    this.props.downloadableReports.length === 0 ? null :
                        <TableView
                            data={data}
                            headers={{
                                type: 'Type',
                                startDateTime: 'From',
                                endDateTime: 'To',
                                createdDateTime: 'Created',
                                url: ''
                            }}
                            keys={['type', 'startDateTime', 'endDateTime', 'createdDateTime', 'url']}
                            rowClick={() => {
                            }}
                        />
                }
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        downloadableReports: state.reports.list
    };
};

const mapDispatchToProps = dispatch => {
    return {
        requestReport: (reportType, startDate, endDate) => dispatch(actions.requestReport(reportType, startDate, endDate)),
        getAllReports: () => dispatch(actions.getAllReports()),
        removeReport: (fileName) => dispatch(actions.removeReport(fileName))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);