import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    data: null,
    loading: false,
    error: null,
    indexedData: null,
    pages: 0,
    currentPage: 0
};

const getTransactionsStart = (state, action) => {
    return updateObject( state, {
        pages: 0,
        currentPage: 0,
        loading: true,
        error: null
    } );
};

const getTransactionsSuccess = (state, action) => {
    const indexedData = {};
    action.data.forEach(tx => indexedData[tx.id] = tx);
    return updateObject( state, {
        data: action.data,
        indexedData: indexedData,
        pages: action.pages,
        currentPage: action.currentPage,
        loading: false,
        error: null
    } );
};

const getTransactionsFail = (state, action) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};

const filterByStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
};

const filterBySuccess = (state, action) => {
    const indexedData = {};
    action.data.forEach(tx => indexedData[tx.id] = tx);

    return updateObject(state, {
        loading: false,
        data: action.data,
        indexedData,
        pages: action.pages,
        currentPage: action.currentPage
    })
};

const filterByError = (state, action) => {
    return updateObject(state, {
        error: action.error
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRANSACTIONS_START:
            return getTransactionsStart(state, action);
        case actionTypes.GET_TRANSACTIONS_SUCCESS:
            return getTransactionsSuccess(state, action);
        case actionTypes.GET_TRANSACTIONS_FAIL:
            return getTransactionsFail(state, action);
        case actionTypes.FILTER_BY_START:
            return filterByStart(state, action);
        case actionTypes.FILTER_BY_SUCCESS:
            return filterBySuccess(state, action);
        case actionTypes.FILTER_BY_ERROR:
            return filterByError(state, action);
        default:
            return state;
    }
};

export default reducer;