import * as actionTypes from "./actionTypes";
import {getData, postData} from "../../shared/utility";

export const getReportStart = () => {
    return {
        type: actionTypes.GET_REPORT_START
    }
};

export const getReportSuccess = (report) => {
    return {
        type: actionTypes.GET_REPORT_SUCCESS,
        report: report,
    }
};

export const getReportFail = (error) => {
    return {
        type: actionTypes.GET_REPORT_FAIL,
        error: error
    }
};

export const removeReport = (fileName) => {
    return {
        type: actionTypes.REMOVE_REPORT,
        fileName: fileName
    }
};

export const getReportsSuccess = (reports) => {
    return {
        type: actionTypes.GET_REPORTS_SUCCESS,
        reports: reports,
    }
};

export const getAllReports = () => {
    return dispatch => {
        dispatch(getReportStart());
        getData(
            "/reports",
            response => {
                dispatch(getReportsSuccess(response.data))
            },
            error => {
                dispatch(getReportFail(error.response.data))
            },
            dispatch
        )
    }
};

export const requestReport = (reportType, startDate, endDate) => {
    return dispatch => {
        const data = {
            startDateTime: startDate,
            endDateTime: endDate
        };
        dispatch(getReportStart());
        postData(
            "/reports/" + reportType,
            data,
            response => {
                dispatch(getReportSuccess(response.data))
            },
            error => {
                dispatch(getReportFail(error.response.status))
            },
            dispatch
        );
    };
};