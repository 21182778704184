import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const start = ( state, action ) => {
    return updateObject( state, {
        data: null,
        loading: true,
        error: null
    } );
};

const success = ( state, action ) => {
    return updateObject( state, {
        data: action.data,
        loading: false,
        error: null
    } );
};

const fail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSET_START:
            return start(state, action);
        case actionTypes.GET_ASSET_SUCCESS:
            return success(state, action);
        case actionTypes.GET_ASSET_FAIL:
            return fail(state, action);
        case actionTypes.CREATE_ASSET_START:
            return start(state, action);
        case actionTypes.CREATE_ASSET_SUCCESS:
            return success(state, action);
        case actionTypes.CREATE_ASSET_FAIL:
            return fail(state, action);
        default:
            return state;
    }
};

export default reducer;