import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getProductsStart = () => {
    return {
        type: actionTypes.GET_PRODUCTS_START
    }
};

export const getProductsSuccess = (data) => {
    return {
        type: actionTypes.GET_PRODUCTS_SUCCESS,
        data: data,
    }
};

export const getProductsFail = (error) => {
    return {
        type: actionTypes.GET_PRODUCTS_FAIL,
        error: error
    }
};

export const getProducts = () => {
    let subUrl = "/products";

    return dispatch => {
        dispatch(getProductsStart());
        getData(
            subUrl,
            response => {
                dispatch(getProductsSuccess(response.data))
            },
            error => {
                dispatch(getProductsFail(error.response.status))
            },
            dispatch
        );
    };
};