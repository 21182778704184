import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getTransactionsStart = () => {
    return {
        type: actionTypes.GET_TRANSACTIONS_START
    }
};

export const getTransactionsSuccess = (data) => {
    return {
        type: actionTypes.GET_TRANSACTIONS_SUCCESS,
        data: data.content,
        pages: data.totalPages,
        currentPage: data.pageable.pageNumber
    }
};

export const getTransactionsFail = (error) => {
    return {
        type: actionTypes.GET_TRANSACTIONS_FAIL,
        error: error
    }
};

export const getTransactionsForUser = (userId) => {
    return dispatch => {
        dispatch(getTransactionsStart());
        getData(
            "/transactions/user/" + userId,
            response => {
                console.log(response);
                dispatch(getTransactionsSuccess(response.data))
            },
            error => {
                console.log(error);
                dispatch(getTransactionsFail(error.response.status))
            },
            dispatch
        );
    };
};

export const getTransactionsForCompany = (companyId) => {
    return dispatch => {
        dispatch(getTransactionsStart());
        getData(
            "/transactions/company/" + companyId,
            response => {
                dispatch(getTransactionsSuccess(response.data))
            },
            error => {
                dispatch(getTransactionsFail(error.response.status))
            },
            dispatch
        );
    };
};


export const getTransactions = (page, sort, dir) => {
    let subUrl = "/transactions?page=" + page;

    return dispatch => {
        dispatch(getTransactionsStart());
        getData(
            !sort && !dir ? subUrl : subUrl += `&sort=${sort}&dir=${dir}`,
            response => {
                dispatch(getTransactionsSuccess(response.data))
            },
            error => {
                dispatch(getTransactionsFail(error.response.status))
            },
            dispatch
        );
    };
};

export const filterByStart = () => ({
    type: actionTypes.FILTER_BY_START
});

export const filterBySuccess = filteredData => ({
    type: actionTypes.FILTER_BY_SUCCESS,
    data: filteredData.content,
    pages: filteredData.totalPages,
    currentPage: filteredData.pageable.pageNumber
});

export const filterByError = error => ({
    type: actionTypes.FILTER_BY_ERROR,
    error: error
});

export const filterBy = (startDate, endDate, userId, paymentState, productId) => dispatch => {
    let subUrl = `/transactions/filter-by?`;

    if (startDate) {
        subUrl += `startDate=${startDate}&`;
    }

    if (endDate) {
        subUrl += `endDate=${endDate}&`;
    }

    if (userId) {
        subUrl += `userId=${userId}&`;
    }

    if (paymentState) {
        subUrl += `paymentState=${paymentState}&`;
    }

    if (productId) {
        subUrl += `productId=${productId}&`;
    }

    dispatch(filterByStart());
    getData(
        subUrl,
        response => {
            console.log('filter success', response);
            dispatch(filterBySuccess(response.data))
        },
        error => {
            console.log('filter error', error);
            dispatch(filterByError(error));
        }
    )
};