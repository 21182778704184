import React from 'react';
import classes from "../Users/Users.module.css";

const Paginator = (props) => {
    let pages = null;
    if (props.pages > 1) {
        const offset = props.currentPage - 5;
        let pageNumbers = [...Array(11).keys()]
            .map(i => offset + i);
        if (!pageNumbers.includes(0)) {
            pageNumbers.unshift(0);
        }
        if (!pageNumbers.includes(props.pages-1)) {
            pageNumbers.push(props.pages-1);
        }
        pages = pageNumbers
            .map(page => {
                if (page < 0 || page >= props.pages) {
                    return null;
                }
                const cssClass = props.currentPage === page ? classes.activePage : classes.page;
                return <li key={page} className={cssClass} onClick={() => props.pageClick(page)}>{page + 1}</li>
            });
    }
    return (
        <div>{pages ? <ul className={classes.pages}>{pages}</ul> : null}</div>
    );
};

export default Paginator;