import {types} from "../shared/validators";

const formFields = [
    {
        key: "tag",
        type: "text",
        placeholder: "Tag",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "descriptiveName",
        type: "text",
        placeholder: "Name",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "visible",
        type: "select",
        options: {
            true: "Set visible",
            false: "Set invisible"
        },
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "order",
        type: "number",
        placeholder: "Numeric order"
    },
    {
        key: "links",
        type: "text",
        placeholder: "Image URL"
    },
    {
        key: "description",
        type: "paragraph",
        placeholder: "Description",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    }
];

export default formFields;