import React, {Component} from 'react';
import {ClipLoader} from "react-spinners";
import PopupForm from "../PopupForm/PopupForm";
import companyFields from "../../forms/company";
import KeyValueBlock from "../KeyValueBlock/KeyValueBlock";
import Transactions from "../Transactions/Transactions";
import Bundles from "../Bundles/Bundles";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import DateTime from "../Date/Date";

class Company extends Component {

    state = {
        editFormOpen: false
    };

    componentDidMount() {
        this.props.invalidateCompanies();
        if (this.props.match.params.id) {
            this.props.getCompany(this.props.match.params.id);
            this.props.getPaymentLinkStatus(this.props.match.params.id);
        }
    }

    openEditForm() {
        this.setState({
            editFormOpen: true
        });
    }

    closeEditForm() {
        this.setState({
            editFormOpen: false
        });
    }

    render() {
        if (!this.props.data) {
            return null;
        }
        let translations = {
            "name": "Company Name",
            "email": "Email Address",
            "phone": "Phone Number",
            "country": "Country",
            "state": "State",
            "city": "City",
            "zipCode": "Zip Code",
            "accountOwnerId": "Owner User Id",
            "created": "Created",
            "agreement": "Payment Link Delivery"
        };
        let link;
        if (this.props.agreement.paymentLinkDelivery === "SENT") {
            link = <a href={`https://shop.jordanbelfort.com/bundle?cuid=${this.props.agreement.id}`} target='_blank'
                      rel='noopener noreferrer'>Delivered Successfully</a>;
        } else if(this.props.agreement.paymentLinkDelivery === "UNATTEMPTED" || this.props.agreement.paymentLinkDelivery === "FAILURE"){
            link = "Unattempted or failed delivery."
        }
        let location = this.props.data.location ? this.props.data.location : {};
        let data = Object.assign({}, this.props.data, {
            country: location.country,
            state: location.state,
            city: location.city,
            zipCode: location.postal,
            created: <DateTime date={this.props.data.created}/>,
            agreement: link
        });

        return (
            <React.Fragment>
                {this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/>
                    : null
                }
                {this.state.editFormOpen ?
                    <PopupForm
                        fields={companyFields}
                        prefill={this.props.data}
                        onSubmit={(data) => this.props.updateCompany(this.props.match.params.id, data)}
                        close={() => this.closeEditForm()}
                    /> : null
                }
                <h2>Company Info</h2>
                <KeyValueBlock
                    data={data}
                    keys={["email", "name", "phone", "country", "state", "city", "zipCode", "accountOwnerId", "created", "agreement"]}
                    translations={translations}
                    editClick={() => this.openEditForm()}
                    actions={actions}
                />
                <h2>Transactions</h2>
                <Transactions
                    companyId={this.props.match.params.id}
                    history={this.props.history}
                />
                <h2>Bundles for SLP Certification</h2>
                <Bundles
                    companyId={this.props.match.params.id}
                    history={this.props.history}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.company.loading,
        data: state.company.data,
        agreement: state.company.agreement,
        error: state.company.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCompany: (id) => dispatch(actions.getCompany(id)),
        getPaymentLinkStatus: (id) => dispatch(actions.getPaymentLinkStatus(id)),
        invalidateCompanies: () => dispatch(actions.invalidateCompanies()),
        updateCompany: (id, data) => dispatch(actions.updateCompany(id, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
