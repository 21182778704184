import { types } from "../shared/validators";

const formFields = (productOptions, setPrice, providerOptions, funnelOptions, sourceOptions) => [
  {
    key: "productId",
    type: "select",
    options: productOptions,
    validation: [
      {
        type: types.REQUIRED
      },
    ]
  },
  {
    key: "price",
    type: "number",
    placeholder: "Price (in dollars)",
    disabled: setPrice,
    validation: [
      {
        type: types.MONEY
      }
    ]
  },
  {
    key: "paymentProvider",
    type: "select",
    placeholder: "Payment Provider",
    options: providerOptions,
    validation: [
      {
        type: types.REQUIRED
      }
    ]
  },
  {
    key: "funnel",
    type: "paragraph",
    placeholder: "Funnel",
    validation: [
      {
        type: types.REQUIRED
      }
    ]
  },
  {
    key: "adSource",
    type: "paragraph",
    placeholder: "Ad Source",
    validation: [
      {
        type: types.REQUIRED
      }
    ]
  },
];

export default formFields;
