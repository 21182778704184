import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import UserBadge from "./UserBadge";
import UserOptions from "./UserOptions";
import PopupForm from "../PopupForm/PopupForm";
import passwordFields from "../../forms/password";
import Select from '../Select/Select';
import {changeView} from "../../store/actions/view";
import {toggleSideMenu} from "../../store/actions/sidemenu";
import '../../styles/components/Header.scss';
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa';

class Header extends Component {
    state = {
        optionsOpen: false,
        passwordFormOpen: false,
    };

    componentDidMount() {
        this.props.getMe();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.passwordChanged && this.props.passwordChanged) {
            this.closePasswordForm()
        }
    }

    openOptions() {
        this.setState({
            optionsOpen: true
        })
    }

    closeOptions() {
        this.setState({
            optionsOpen: false
        })
    }

    openPasswordForm() {
        this.setState({
            optionsOpen: false,
            passwordFormOpen: true
        })
    }

    closePasswordForm() {
        this.setState({
            passwordFormOpen: false
        })
    }

    changePassword(data) {
        this.props.changePassword({
            oldPassword: data.oldPassword,
            newPassword: data.newPassword
        })
    }

    render() {
        let sideMenuButtonText;
        !this.props.sideMenuHidden ? sideMenuButtonText = 'Hide Side Menu' : sideMenuButtonText = 'Show Side Menu';

        const leftArrow = !this.props.sideMenuHidden ? <div className="left-arrow-container"><FaArrowLeft/></div> : null;
        const rightArrow = this.props.sideMenuHidden ? <div className="right-arrow-container"><FaArrowRight/></div> : null;

        return (
            <div className="header-root">
                <div className="header-menu">
                    <button type="button" onClick={() => this.props.toggleSideMenu()} className="button sideMenu-button">
                        {leftArrow}
                        {sideMenuButtonText}
                        {rightArrow}
                    </button>
                    <Select
                        list={['card', 'list']}
                        selectLabel={'Choose your view'}
                        currentOption={this.props.view}
                        changeOption={this.props.changeView}
                    />
                </div>
                <UserBadge data={this.props.data} openOptions={() => this.openOptions()}/>
                {this.state.optionsOpen ?
                    <UserOptions
                        closeOptions={() => this.closeOptions()}
                        openPasswordForm={() => this.openPasswordForm()}
                        logout={() => this.props.logout()}
                    /> :
                    null
                }
                {this.state.passwordFormOpen ?
                    <PopupForm close={() => this.closePasswordForm()} onSubmit={(data) => this.changePassword(data)} fields={passwordFields} formError={this.props.error}/> :
                    null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.me.loading,
        data: state.me.data,
        error: state.me.error,
        passwordChanged: state.me.passwordChanged,
        view: state.view.view,
        sideMenuHidden: state.sideMenu.hidden
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMe: () => dispatch(actions.getMe()),
        changePassword: (data) => dispatch(actions.changePassword(data)),
        logout: () => dispatch(actions.logout()),
        changeView: newView => dispatch(changeView(newView)),
        toggleSideMenu: () => dispatch(toggleSideMenu())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);