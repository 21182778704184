import {types} from "../shared/validators";

const formFields = [
    {
        key: "oldPassword",
        type: "password",
        placeholder: "Old Password",
        validation: [
            {
                type: types.REQUIRED
            },
        ]
    },
    {
        key: "newPassword",
        type: "password",
        placeholder: "New Password",
        validation: [
            {
                type: types.REQUIRED
            },
        ]
    },
    {
        key: "newPasswordRepeat",
        type: "password",
        placeholder: "Repeat New Password",
        validation: [
            {
                type: types.REQUIRED
            },
            {
                type: types.MATCH_FIELD,
                criteria: "newPassword"
            },
        ]
    },
];

export default formFields;