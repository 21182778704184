import React, {Component} from 'react';
import {connect} from 'react-redux';
import tableClasses from "../../sharedCss/Tables.module.css";
import tableViewClasses from "./TableView.module.css";
import Paginator from "../Paginator/Paginator";
import TableRow from "./TableRow";
import {FaPlus, FaSortUp, FaSortDown} from 'react-icons/fa';
import PropTypes from 'prop-types';

class TableView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: null,
            sortDirection: "asc"
        }
    }

    changeSortDirection(key) {
        let dir = "asc";
        if (this.state.sortDirection === "asc") {
            dir = "desc"
        }

        this.setState({sortDirection: dir});
        this.props.sort(key, dir);
    }

    render() {
        let tableCssClass;
        this.props.sideMenuHidden ? tableCssClass = tableViewClasses.extraWidth : tableCssClass = tableClasses.table;

        let rows = null;
        if (this.props.data) {
            rows = this.props.data.map(rowData =>
              <TableRow key={rowData.id} keys={this.props.keys} data={rowData}
                          click={() => this.props.rowClick(rowData.id)}/>);
        }

        let headers = this.props.keys.map((key) => {
                let arrow = null;
                if (key === this.state.sortBy && this.state.sortDirection === "asc") {
                    arrow = <FaSortDown/>
                } else if (key === this.state.sortBy) {
                    arrow = <FaSortUp/>
                }

                return <th
                    className={tableClasses.headerCell} key={"header-" + key}
                    onClick={() => {
                        this.setState({sortBy: key});
                        this.changeSortDirection(key);
                        }
                    }
                >
                    {this.props.headers[key]}
                    {arrow}
                </th>
            }
        );

        let paginator = null;
        if (this.props.pages && this.props.pages > 1) {
            paginator = <Paginator pages={this.props.pages} currentPage={this.props.currentPage}
                                   pageClick={(page) => this.props.pageClick(page, this.state.sortBy, this.state.sortDirection)}/>;
        }
        return (
            <div className={tableClasses.root}>
                <div className={tableClasses.buttonRow}>
                    {this.props.addClick ?
                        <button className={tableClasses.addButton} onClick={(event) => this.props.addClick(event)}>
                            <FaPlus/>
                        </button> : null}
                </div>
                <table className={tableCssClass}>
                    <tbody>
                    <tr>
                        {headers}
                    </tr>
                    {rows}
                    </tbody>
                </table>
                {paginator}
            </div>
        )
    }

}

const mapStateToProps = state => ({
    sideMenuHidden: state.sideMenu.hidden
});

export default connect(mapStateToProps)(TableView);

TableView.propTypes = {
    addClick: PropTypes.func,
    currentPage: PropTypes.number,
    data: PropTypes.array,
    headers: PropTypes.object.isRequired,
    keys: PropTypes.array.isRequired,
    pageClick: PropTypes.func,
    pages: PropTypes.number,
    rowClick: PropTypes.func
};