import React from 'react';

const DateTime = props => {
    if (!props.date) {
        return null;
    } else {
        let date = new Date(props.date);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return <React.Fragment>{date.toLocaleString()}</React.Fragment>;
    }
};

export default DateTime;