import * as actionTypes from "./actionTypes";
import {postData} from "../../shared/utility";

export const createProductGroupStart = () => ({
    type: actionTypes.CREATE_PRODUCT_GROUP_START
});

export const createProductGroupSuccess = productGroup => ({
    type: actionTypes.CREATE_PRODUCT_GROUP_SUCCESS,
    productGroup
});

export const createProductGroupError = error => ({
    type: actionTypes.CREATE_PRODUCT_GROUP_ERROR,
    error
});

export const clearProductGroupError = () => ({
    type: actionTypes.CLEAR_PRODUCT_GROUP_ERROR
});

export const updateProductGroupStart = () => ({
    type: actionTypes.UPDATE_PRODUCT_GROUP_START
});

export const updateProductGroupSuccess = newData => ({
    type: actionTypes.UPDATE_PRODUCT_GROUP_SUCCESS,
    newData
});

export const updateProductGroupError = error => ({
    type: actionTypes.UPDATE_PRODUCT_GROUP_ERROR,
    error
});

export const createProductGroup = groupData => dispatch => {
    dispatch(createProductGroupStart());
    return postData(
        "/product-groups",
        groupData,
        response => {
            console.log('created', response);
            dispatch(createProductGroupSuccess(response.data));
        },
        error => {
            console.log(error);
            dispatch(createProductGroupError(error.response.status));
        },
        dispatch
    );
};

export const updateProductGroup = (id, newData) => dispatch => {
    dispatch(updateProductGroupStart());
    return postData(
        "/product-groups/" + id,
        newData,
        response => {
            console.log('updated', response);
            dispatch(updateProductGroupSuccess(response.data));
        },
        error => {
            console.log(error);
            dispatch(updateProductGroupError(error));
        },
        dispatch
    );
};