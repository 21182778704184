import React from 'react';
import {FaExclamation} from 'react-icons/fa';

const DateError = props => {
    if (props.orderFormError === 409) {
        return (
            <div className="backdrop">
                <div className="form">
                    <FaExclamation size={90}/>
                    <h3>The date you entered is invalid.</h3>
                    <button type="button" onClick={() => props.clearError()} className="button">Okay</button>
                </div>
            </div>
        )
    } else {
        return null;
    }
};

export default DateError;