import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    data: null,
    indexedData: null,
    pages: 0,
    currentPage: 0,
    loading: false,
    error: null,
};

const getCompaniesStart = ( state, action ) => {
    return updateObject( state, {
        data: null,
        indexedData: null,
        pages: 0,
        currentPage: 0,
        loading: true,
        error: null
    } );
};

const getCompaniesSuccess = ( state, action ) => {
    const indexedData = {};
    action.data.forEach(user => indexedData[user.id] = user);
    return updateObject( state, {
        data: action.data,
        indexedData: indexedData,
        pages: action.pages,
        currentPage: action.currentPage,
        loading: false,
        error: null
    } );
};

const getCompaniesFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};

const invalidateCompanies = ( state, action ) => {
    return updateObject( state, {
        data: null,
        indexedData: null
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_COMPANIES_START: return getCompaniesStart(state, action);
        case actionTypes.GET_COMPANIES_SUCCESS: return getCompaniesSuccess(state, action);
        case actionTypes.GET_COMPANIES_FAIL: return getCompaniesFail(state, action);
        case actionTypes.CREATE_COMPANY_SUCCESS: return invalidateCompanies(state, action);
        case actionTypes.INVALIDATE_COMPANIES: return invalidateCompanies(state, action);
        default:
            return state;
    }
};

export default reducer;