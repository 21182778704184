import React, {Component} from 'react';
import {connect} from "react-redux";
import {getBundleById} from "../../store/actions/bundle";
import * as actions from "../../store/actions";
import {getProducts} from "../../store/actions";
import KeyValueBlock from "../KeyValueBlock/KeyValueBlock";
import DateTime from "../Date/Date";
import {ClipLoader} from 'react-spinners';
import * as papaparse from "papaparse";
import {csvFile, formFields} from "../../forms/bundleUsers";
import _ from "lodash";
import classes from "../Users/Users.module.css";
import PopupForm from "../PopupForm/PopupForm";

class Bundle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addBundleUsersFormOpen: false,
            pasteView: true,
            uploadView: false,
            pastedCsv: '',
            uploadedFile: '',
            fileName: ''
        };
        this.handleStringChange = this.handleStringChange.bind(this);
        this.handleViewChange = this.handleViewChange.bind(this);
        this.handleFileSelection = this.handleFileSelection.bind(this);
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getBundleById(this.props.match.params.id);
        }

        if (!this.props.products) {
            this.props.getProducts();
        }
    }

    submitBundleUsers() {
        if (!this.state.pastedCsv && !this.state.uploadedFile) {
            alert("Please paste a user list or choose a CSV file to upload.");
            return;
        }
        const seatsAvailable = (this.props.bundle.seatsTotal - (this.props.bundle.seatUsers ? this.props.bundle.seatUsers.length : 0));
        let data = this.state.pastedCsv ? this.state.pastedCsv : this.state.uploadedFile;
        if (data.bundleUsersList.length > seatsAvailable) {
            alert(`This bundle has  ${seatsAvailable} spaces available, "but you attempted to add + ${data.bundleUsersList.length} more users. Please correct your list and try again.`);
        } else {
            console.log("submitted!", data);
            this.props.addUsersToCompanySeats(data);
            this.closeAddBundleUsersForm();
            this.setState({
                pastedCsv: '',
                uploadedFile: ''
            })
        }
    }

    submitFile() {
        if (this.state.uploadedFile.bundleUsersList.length === this.props.bundle.seatsTotal) {
            alert("This bundle has " + (this.props.bundle.seatsTotal - this.props.bundle.seatUsers.length) + " spaces available, " +
                "but you attempted to add " + this.state.uploadedFile.bundleUsersList.length + " more users. Please correct your list and try again.");
        } else {
            this.props.addUsersToCompanySeats(this.state.uploadedFile);
            console.log("file posted ", this.state.uploadedFile);
            this.closeAddBundleUsersForm();
        }
    }

    handleFileSelection(evt) {
        const onSuccess = (result, file) => {
            let data;
            data = {
                "bundleId": this.props.match.params.id,
                "bundleUsersList": result.data.flat()
            };
            this.setState({
                uploadedFile: data,
                fileName: file.name
            })
        };
        const file = evt.target.files[0];
        papaparse.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: function (results) {
                console.log(results);
                onSuccess(results, file);
            }
        });
    }

    handleStringChange(string) {
        let data;
        let val = string.target.value;
        console.log("val", val);
        let parsed = papaparse.parse(val, {header: true});
        this.setState({parsed: parsed.data});
        data = {
            "bundleId": this.props.match.params.id,
            "bundleUsersList": parsed.data.flat()
        };
        this.setState({
            pastedCsv: data
        })
    }

    handleViewChange() {
        if (this.state.pasteView) {
            this.setState({
                pasteView: false,
                uploadView: true
            });

        } else if (this.state.uploadView) {
            this.setState({
                uploadView: false,
                pasteView: true
            });
        }
    }

    closeAddBundleUsersForm() {
        this.setState({
            addBundleUsersFormOpen: false
        });
    }

    render() {
        if (!this.props.products) {
            return null;
        }

        if (this.props.bundle) {
            let translations = {
                tag: "Product",
                seatsTotal: "Seats Total",
                seatUsers: "Seated Users",
                created: "Created"
            };
            const data = Object.assign({}, this.props.bundle, {
                "tag": `${this.props.products[this.props.bundle.productId].tag}`,
                "created": <DateTime date={this.props.bundle.created}/>,
                "seatsTotal": `${this.props.bundle.seatsTotal}`,
                "seatUsers": `${this.props.bundle.seatUsers ? this.props.bundle.seatUsers : 0}`
            });


            let actions = null;
            let fields;
            if (this.state.pasteView) {
                fields = formFields
            } else {
                fields = csvFile;
            }
            console.log(this.state.fileName);
            return (
                <React.Fragment>
                    {this.props.loading ?
                        <ClipLoader sizeUnit="px" size={100} color="black"/>
                        : null
                    }

                    {
                        this.state.addBundleUsersFormOpen ?
                            <PopupForm
                                fields={fields}
                                onSubmit={_.debounce(() => this.submitBundleUsers(), 300, {
                                    'leading': true,
                                    'trailing': false
                                })}
                                fileChosen={this.state.fileName ? this.state.fileName : null}
                                view={() => this.handleViewChange()}
                                close={() => this.closeAddBundleUsersForm()}
                                csvChange={this.state.pasteView ? this.handleStringChange : this.handleFileSelection}
                            />
                            : null
                    }
                    <button type="button"
                            onClick={() => {
                                console.log("button clicked");
                                this.setState({
                                    addBundleUsersFormOpen: true,
                                    pasteView: true
                                });
                            }}
                            className={classes.addButton}>
                        Add Users To Bundle
                    </button>

                    <KeyValueBlock
                        data={data}
                        keys={["tag", "seatsTotal", "created", "seatUsers"]}
                        translations={translations}
                        actions={actions}
                    />

                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    bundle: state.bundle.data,
    loading: state.bundle.loading,
    products: state.products.indexedData,
    cancelling: state.bundle.cancelling,
    canceled: state.bundle.canceled
});
const mapDispatchToProps = dispatch => {
    return {
        getBundleById: (id) => dispatch(getBundleById(id)),
        getProducts: () => dispatch(getProducts()),
        addUsersToCompanySeats: (data) => dispatch(actions.addUsersToCompanySeats(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bundle);
