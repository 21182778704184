import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getBundleStart = () => {
    return {
        type: actionTypes.GET_BUNDLE_START
    }
};

export const getBundleSuccess = (data) => {
    return {
        type: actionTypes.GET_BUNDLE_SUCCESS,
        data: data
    }
};

export const getBundleFail = (error) => {
    return {
        type: actionTypes.GET_BUNDLE_FAIL,
        error: error
    }
};



export const getBundleById = id => dispatch => {
    dispatch(getBundleStart());
    return getData(
        `/bundle/${id}`,
        response => {
            dispatch(getBundleSuccess(response.data));
        },
        response => {
            dispatch(getBundleFail(response.error));
        },
        dispatch
    );
};

