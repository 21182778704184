import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    data: null,
    indexedData: null,
    pages: 0,
    currentPage: 0,
    loading: false,
    error: null,
};

const getUsersStart = ( state, action ) => {
    return updateObject( state, {
        data: null,
        indexedData: null,
        pages: 0,
        currentPage: 0,
        loading: true,
        error: null
    } );
};

const getUsersSuccess = ( state, action ) => {
    const indexedData = {};
    action.data.forEach(user => indexedData[user.id] = user);
    return updateObject( state, {
        data: action.data,
        indexedData: indexedData,
        pages: action.pages,
        currentPage: action.currentPage,
        loading: false,
        error: null
    } );
};

const getUsersFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};

const invalidateUsers = ( state, action ) => {
    return updateObject( state, {
        data: null,
        indexedData: null
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_USERS_START: return getUsersStart(state, action);
        case actionTypes.GET_USERS_SUCCESS: return getUsersSuccess(state, action);
        case actionTypes.GET_USERS_FAIL: return getUsersFail(state, action);
        case actionTypes.CREATE_USER_SUCCESS: return invalidateUsers(state, action);
        case actionTypes.INVALIDATE_USERS: return invalidateUsers(state, action);
        default:
            return state;
    }
};

export default reducer;