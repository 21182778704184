import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import PopupForm from "../PopupForm/PopupForm";
import taskFields from "../../forms/task";
import {ClipLoader} from 'react-spinners';
import Card from '../Card/Card';
import TableView from '../TableView/TableView';
import {formatTaskCard} from "../Card/FormatCard";
import {clearTaskFail} from '../../store/actions/task';
import DuplicateNotification from "../Duplicate/DuplicateNotification";
import classes from "./Tasks.module.css";

class Tasks extends Component {
    state = {
        formOpen: false,
        status: null,
        sortKey: null,
        direction: null

    };

    componentDidMount() {
        this.props.getTasks();


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.taskCreated && this.props.taskCreated) {
            this.props.history.push('/task/' + this.props.task.id)
        }
        if (this.state.status && this.state.status !== prevState.status) {
            this.props.getTasks("created", "desc", this.state.status);
        }
        if (!this.state.status &&  prevState.status) {
            this.props.getTasks(this.state.sortKey, this.state.direction);
        }

    }


    closeForm() {
        this.setState({
            formOpen: false
        })
    }

    taskCreate(data) {
        const deadline = data.deadline ? data.deadline + "T00:00:00.000" : null;
        const completedOn = data.completedOn ? data.completedOn + "T00:00:00.000" : null;

        const task = {
            name: data.name,
            description: data.description,
            status: data.status,
            priority: data.priority,
            deadline: deadline,
            completedOn: completedOn,
            onBehalfOf: data.onBehalfOf
        };
        this.props.createTask(task)
    }

    render() {
        let data;

        if (this.props.data) {
            data = this.props.data.map(obj => {
                return Object.assign({}, obj, {
                    name: obj.name,
                    deadline: obj.deadline ? obj.deadline + "T00:00:00.000" : null,
                    completedOn: obj.completedOn ? obj.completedOn + "T00:00:00.000" : null

                });
            });
        }
        const taskPriorityOptions = {
            LOW: "LOW",
            NORMAL: "NORMAL",
            HIGH: "HIGH"
        };
        const taskStatusOptions = {
            TO_DO: "To_Do",
            IN_PROGRESS: "IN_PROGRESS",
            COMPLETE: "COMPLETE"

        };
        let fields;

        fields = taskFields(taskPriorityOptions, taskStatusOptions);


        return (
            <React.Fragment>
                {
                    this.props.loading ?
                        <ClipLoader sizeUnit="px" size={100} color="black"/> :
                        null
                }
                {
                    this.state.formOpen ?
                        <PopupForm
                            fields={fields}
                            onSubmit={(data) => this.taskCreate(data)}
                            close={() => this.closeForm()}
                        /> :
                        null
                }
                <button
                    type="button"
                    onClick={() => this.setState({formOpen: true})}
                    className="button tasksButton"
                >
                    Add New Task
                </button>

                <div className={classes.buttonRow}>
                    <button type="button"
                            className={classes.button}
                            onClick={() => {
                                this.setState({status: null});
                            }}
                    >All Tasks
                    </button>

                    <button type="button"
                            className={classes.button}
                            onClick={() => {
                                //  this.props.getTasksByStatus("TO_DO", "status", "desc");
                                this.setState({status: "TO_DO"})
                            }}
                    >To-Do
                    </button>


                    <button type="button"
                            className={classes.button}
                            onClick={() => {
                                // this.props.getTasksByStatus("IN_PROGRESS", "status", "desc")
                                this.setState({status: "IN_PROGRESS"})

                            }}
                    >In-Progress
                    </button>

                    <button type="button"
                            className={classes.button}
                            onClick={() => {
                                //this.props.getTasksByStatus("COMPLETE", "status", "desc")
                                this.setState({status: "COMPLETE"})

                            }}
                    >Completed
                    </button>
                </div>

                {
                    this.props.view === 'card' ?
                        <Card
                            data={this.props.data}
                            format={tasks => formatTaskCard(tasks)}
                            url={'/task/'}
                        /> :
                        <TableView
                            data={data}
                            headers={{
                                created: 'Created',
                                priority: 'Priority',
                                name: 'Task Name',
                                status: 'Status',
                                onBehalfOf: 'Requester'
                            }}
                            keys={['created', 'priority', 'name', 'status', 'onBehalfOf']}
                            rowClick={id => this.props.history.push('/task/' + id)}
                            sort={(sort, dir) => {
                                console.log(sort, dir);
                                this.setState({
                                    sortKey: sort,
                                    direction: dir
                                });
                                this.props.getTasks(sort, dir, this.state.status )
                            }}
                        />


                }
                <DuplicateNotification
                    statusCode={this.props.createTaskError}
                    clearError={this.props.clearTaskFail}
                    message={'That task already exists..'}
                />
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.tasks.loading,
        data: state.tasks.data,
        error: state.tasks.error,
        taskLoading: state.task.loading,
        taskCreated: state.task.data && !state.task.error,
        task: state.task.data,
        view: state.view.view,
        createTaskError: state.task.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTasks: (sort, dir, status) => dispatch(actions.getTasks(sort, dir, status)),
        createTask: (data) => dispatch(actions.createTask(data)),
        clearTaskFail: () => dispatch(clearTaskFail()),
        getTasksByStatus: (status, sort, dir) => dispatch(actions.getTasksByStatus(status, sort, dir))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);