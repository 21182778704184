import {types} from "../shared/validators";

const formFields = (productGroupOptions) => [
    {
        key: "tag",
        type: "text",
        placeholder: "Tag",
        validation: [
            {
                type: types.REQUIRED
            },
            {
                type: types.MIN_LENGTH,
                criteria: 3
            }
        ]
    },
    {
        key: "descriptiveName",
        type: "text",
        placeholder: "Name",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "group",
        type: "select",
        options: productGroupOptions,
        placeholder: "Product Group",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "visible",
        type: "select",
        options: {
            true: "Set visible",
            false: "Set invisible"
        },
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "orderFormVisible",
        type: "select",
        options: {
            true: "Set visible in order form",
            false: "Set invisible in order form"
        }
    },
    {
        key: "price",
        type: "number",
        placeholder: "Price (in dollars)",
        validation: [
            {
                type: types.REQUIRED
            },
            {
                type: types.MONEY
            }
        ]
    },
    {
        key: "interval",
        type: "select",
        options: {
            ONE_TIME: "One-time payment",
            MONTHLY: "Monthly payment",
            ANNUALLY: "Annual payment",
            QUARTERLY: "Quarterly Payment",
            CUSTOM: "Other"
        },
        validation: [
            {
                type: types.REQUIRED
            },
        ]
    },
    {
        key: "funnelPrice",
        type: "number",
        placeholder: "Funnel price (in dollars)",
        validation: [
            {
                type: types.MONEY
            }
        ]
    },
    {
        key: "description",
        type: "paragraph",
        placeholder: "Description"
    },
    {
        key: "planId",
        type: "text",
        placeholder: "Braintree plan id"
    },
    {
        key: "stripePlanId",
        type: "text",
        placeholder: "Stripe plan id"
    },
    {
        key: "trialDays",
        type: "number",
        placeholder: "Free trial days"
    },
    {
        key: "salesforceId",
        type: "text",
        placeholder: "Salesforce Product Id"
    },
    {
        key: "memberpressId",
        type: "text",
        placeholder: "Memberpress Membership Id"
    },
    {
        key: "learnuponPath",
        type: "number",
        placeholder: "LearnUpon Learning Path Id"
    },
    {
        key: "shopifyId",
        type: "number",
        placeholder: "Shopify Variant Product ID"
    },
    {
        key: "hubspotProductId",
        type: "number",
        placeholder: "Hubspot Product ID"
    },
    {
        key: "clickfunnelsProductId",
        type: "number",
        placeholder: "Clickfunnels Product ID"
    },
];

export default formFields;