import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    data: null,
    loading: false,
    error: null,
    indexedData: null,
    pages: 0,
    currentPage: 0
};

const getBundlesStart = (state, action) => {
    return updateObject( state, {
        pages: 0,
        currentPage: 0,
        loading: true,
        error: null
    } );
};

const getBundlesSuccess = (state, action) => {
    const indexedData = {};
    action.data.forEach(tx => indexedData[tx.id] = tx);
    return updateObject( state, {
        data: action.data,
        indexedData: indexedData,
        pages: action.pages,
        currentPage: action.currentPage,
        loading: false,
        error: null
    } );
};

const getBundlesFail = (state, action) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BUNDLES_START:
            return getBundlesStart(state, action);
        case actionTypes.GET_BUNDLES_SUCCESS:
            return getBundlesSuccess(state, action);
        case actionTypes.GET_BUNDLES_FAIL:
            return getBundlesFail(state, action);
        default:
            return state;
    }
};

export default reducer;