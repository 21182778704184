import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import KeyValueBlock from "../KeyValueBlock/KeyValueBlock";
import PopupForm from "../PopupForm/PopupForm";
import taskFields from "../../forms/task";
import PopupDialog from "../PopupDialog/PopupDialog";
import {ClipLoader} from 'react-spinners';

class Task extends Component {

    state = {
        formOpen: false,
        deleteDialogOpen: false
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getTask(this.props.match.params.id)
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.deleted === false && this.props.deleted === true) {
            this.props.history.goBack();
        }
    }

    openForm() {
        this.setState({
            formOpen: true
        })
    }

    closeForm() {
        this.setState({
            formOpen: false
        })
    }

    openDeleteDialog() {
        this.setState({
            deleteDialogOpen: true
        })
    }

    closeDeleteDialog() {
        this.setState({
            deleteDialogOpen: false
        })
    }

    taskEdit(id, data) {

        let deadline;
        let completedOn;
        console.log(`thisprops: ${this.props.data.deadline}  ,  datadead ${data.deadline}  `);

        if (data.deadline && this.props.data.deadline !== data.deadline) {
            deadline = data.deadline + "T00:00:00.000"
        } else if (!data.deadline) {
            deadline = null
        } else {
            deadline = this.props.data.deadline
        }
        if (data.completedOn && this.props.data.completedOn !== data.completedOn) {
            completedOn = data.completedOn + "T00:00:00.000"
        } else if (!data.completedOn) {
            completedOn = null
        } else {
            completedOn = this.props.data.completedOn
        }
        /* const deadline = data.deadline ? data.deadline + "T00:00:00.000" : null;
         const completedOn = data.completedOn ? data.completedOn + "T00:00:00.000" : null;
 */

        console.log(`deadline and completed to submit: ${deadline}  ,  ${completedOn}  `);
        const task = {
            name: data.name,
            description: data.description,
            status: data.status,
            priority: data.priority,
            deadline: deadline,
            completedOn: completedOn,
            onBehalfOf: data.onBehalfOf
        };
        this.props.editTask(this.props.match.params.id, task)

    }

    deleteTask() {
        this.props.deleteTask(this.props.match.params.id)
    }

    render() {
        if (!this.props.data) {
            return null;
        }
        let translations = {
            name: "Name",
            priority: "Priority",
            description: "Description",
            status: "Status",
            deadline: "Deadline",
            onBehalfOf: "On Behalf Of",
            issuingUser: "Issuer",
            completedOn: "Completed On"


        };

        let actions = [
            {
                name: "Delete",
                action: () => this.openDeleteDialog()
            }
        ];


        const preFillData = Object.assign({}, this.props.data, {
            orderFormVisible: this.props.data.orderFormVisible ? this.props.data.orderFormVisible.toString() : 'false',
            visible: this.props.data.visible ? this.props.data.visible.toString() : 'false',
            name: this.props.data.name,
            deadline: this.props.data.deadline ? this.props.data.deadline  : null,
            completedOn: this.props.data.completedOn ? this.props.data.completedOn  : null

        });

        const keyValueBlockData = Object.assign({}, this.props.data, {
            name: this.props.data.name,
            description: this.props.data.description ? this.props.data.description.split('\n').map((item, index) => {
                return (
                    <span className="task-text" key={index}>
                        {item}
                        <br/>
                    </span>
                );
            }) : null
        });

        const taskPriorityOptions = {
            LOW: "LOW",
            NORMAL: "NORMAL",
            HIGH: "HIGH"
        };
        const taskStatusOptions = {
            TO_DO: "To_Do",
            IN_PROGRESS: "IN_PROGRESS",
            COMPLETE: "COMPLETE"

        };
        let fields;

        fields = taskFields(taskPriorityOptions, taskStatusOptions);

        return (
            <React.Fragment>
                {this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/>
                    : null
                }
                {this.state.deleteDialogOpen ?
                    <PopupDialog
                        close={() => this.closeDeleteDialog()}
                        message="Are you sure you want to delete this task?"
                        no="Cancel"
                        yes="Delete"
                        yesClicked={() => this.deleteTask()}/>
                    : null
                }
                {this.state.formOpen ?
                    <PopupForm
                        fields={fields}
                        prefill={preFillData}
                        onSubmit={(data) => {
                            this.taskEdit(this.props.match.params.id, data);
                            this.closeForm();
                        }}
                        close={() => this.closeForm()}
                    /> : null
                }
                <KeyValueBlock
                    data={keyValueBlockData}
                    keys={["name", "priority", "description", "status", "deadline", "onBehalfOf", "issuingUser", "completedOn"]}
                    translations={translations}
                    editClick={() => this.openForm()}
                    actions={actions}
                />
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.task.loading,
        data: state.task.data,
        error: state.task.error,
        deleting: state.task.deleting,
        deleted: state.task.deleted,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTask: (id) => dispatch(actions.getTask(id)),
        editTask: (id, data) => dispatch(actions.updateTask(id, data)),
        deleteTask: (id) => dispatch(actions.deleteTask(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Task);