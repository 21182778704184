import React from 'react';
import {connect} from 'react-redux';
import KeyValueBlock from "../KeyValueBlock/KeyValueBlock";
import PopupDialog from "../PopupDialog/PopupDialog";
import Price from '../Price/Price';
import * as actions from "../../store/actions";
import {updateCouponFields} from '../../forms/coupon';
import PopupForm from "../PopupForm/PopupForm";

class Coupon extends React.Component {
    state = {
        deleteDialogueOpen: false,
        updateFormOpen: false
    };

    componentDidMount() {
        if (!this.props.indexedCoupons) {
            this.props.getCoupons();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.deleted && this.props.deleted) {
            this.props.history.goBack();
        }
    }

    submitCouponUpdate(id, active) {
        const dataToSubmit = {
            id: id,
            active: JSON.parse(active)
        };

        this.props.updateCoupon(dataToSubmit);
        this.setState({updateFormOpen: false});
    }

    render() {
        let actions = [
            {
                name: "Delete",
                action: () => this.setState({deleteDialogueOpen: true})
            },
            {
                name: "Update",
                action: () => this.setState({updateFormOpen: true})
            }
        ];

        let translations = {
            code: "Code",
            type: "Type",
            discount: "Discount",
            active: "Active"
        };

        if (!this.props.indexedCoupons) {
            return null;
        }

        let couponData = this.props.indexedCoupons[this.props.match.params.id];
        if (!couponData) {
            return null;
        }

        couponData = Object.assign({}, couponData, {
            active: couponData.active.toString(),
            discount: couponData.type === 'FLAT_RATE' ?
                <Price price={couponData.priceReduction}/> :
                couponData.priceReduction + '%'
        });

        return (
            <React.Fragment>
                {
                    this.state.deleteDialogueOpen ?
                        <PopupDialog
                            close={() => this.setState({deleteDialogueOpen: false})}
                            message="Are you sure you want to delete this coupon?"
                            no="Cancel"
                            yes="Delete"
                            yesClicked={() => this.props.deleteCoupon(this.props.match.params.id)}
                        /> :
                        null
                }
                {
                    this.state.updateFormOpen ?
                    <PopupForm
                        close={() => this.setState({updateFormOpen: false})}
                        fields={updateCouponFields}
                        onSubmit={data => this.submitCouponUpdate(couponData.id, data.active)}
                    /> :
                    null
                }
                <KeyValueBlock
                    data={couponData}
                    keys={["code", "type", "discount", "active"]}
                    translations={translations}
                    actions={actions}
                />
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => ({
    indexedCoupons: state.coupons.indexedCoupons,
    deleted: state.coupons.deleted
});

const mapDispatchToProps = dispatch => ({
    getCoupons: () => dispatch(actions.getCoupons()),
    deleteCoupon: id => dispatch(actions.deleteCoupon(id)),
    updateCoupon: data => dispatch(actions.updateCoupon(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
