import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getAssetsStart = () => {
    return {
        type: actionTypes.GET_ASSETS_START
    }
};

export const getAssetsSuccess = (data) => {
    return {
        type: actionTypes.GET_ASSETS_SUCCESS,
        data: data,
    }
};

export const getAssetsFail = (error) => {
    return {
        type: actionTypes.GET_ASSETS_FAIL,
        error: error
    }
};

export const invalidateAssets = () => {
    return {
        type: actionTypes.INVALIDATE_USERS
    }
};

export const getAssets = () => {
    return dispatch => {
        dispatch(getAssetsStart());
        getData(
            "/assets",
            response => {
                dispatch(getAssetsSuccess(response.data))
            },
            error => {
                dispatch(getAssetsFail(error.response.status))
            },
            dispatch);
    };
};