import * as actionTypes from "./actionTypes";
import {deleteData, getData, postData} from "../../shared/utility";

export const getProductStart = () => {
    return {
        type: actionTypes.GET_PRODUCT_START
    }
};

export const getProductSuccess = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_SUCCESS,
        data: data,
    }
};

export const getProductFail = (error) => {
    return {
        type: actionTypes.GET_PRODUCT_FAIL,
        error: error
    }
};

export const deleteProductStart = () => {
    return {
        type: actionTypes.DELETE_PRODUCT_START
    }
};

export const deleteProductSuccess = () => {
    return {
        type: actionTypes.DELETE_PRODUCT_SUCCESS,
    }
};

export const deleteProductFail = (error) => {
    return {
        type: actionTypes.DELETE_PRODUCT_FAIL,
        error: error
    }
};

export const createProductStart = () => {
    return {
        type: actionTypes.CREATE_PRODUCT_START
    }
};

export const createProductSuccess = (data) => {
    return {
        type: actionTypes.CREATE_PRODUCT_SUCCESS,
        data: data,
    }
};

export const createProductFail = (error) => {
    return {
        type: actionTypes.CREATE_PRODUCT_FAIL,
        error: error
    }
};

export const clearProductFail = () => {
    return {
        type: actionTypes.CLEAR_PRODUCT_FAIL
    }
};

export const updateProductStart = () => {
    return {
        type: actionTypes.UPDATE_PRODUCT_START
    }
};

export const updateProductSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_PRODUCT_SUCCESS,
        data: data,
    }
};

export const updateProductFail = (error) => {
    return {
        type: actionTypes.UPDATE_PRODUCT_FAIL,
        error: error
    }
};

export const getProduct = (id) => {
    return dispatch => {
        dispatch(getProductStart());
        getData(
            "/products/" + id,
            response => {
                dispatch(getProductSuccess(response.data))
            },
            error => {
                dispatch(getProductFail(error.response.status))
            },
            dispatch
        );
    };
};

export const createProduct = (data) => {
    return dispatch => {
        dispatch(createProductStart());
        postData(
            "/products",
            data,
            response => {
                dispatch(createProductSuccess(response.data))
            },
            error => {
                dispatch(createProductFail(error.response.status))
            },
            dispatch
        );
    };
};

export const updateProduct = (id, data) => {
    return dispatch => {
        dispatch(updateProductStart());
        postData(
            "/products/" + id,
            data,
            response => {
                dispatch(updateProductSuccess(response.data))
            },
            error => {
                dispatch(updateProductFail(error.response.status))
            },
            dispatch
        );
    };
};

export const deleteProduct = (id) => {
    return dispatch => {
        dispatch(deleteProductStart());
        deleteData(
            "/products/" + id,
            response => {
                dispatch(deleteProductSuccess())
            },
            error => {
                dispatch(deleteProductFail(error.response.status))
            },
            dispatch
        );
    };
};