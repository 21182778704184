import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    data: null,
    indexedData: null,
    loading: false,
    error: null,
};

const getTasksByStatusStart = ( state, action ) => {
    return updateObject( state, {
        data: null,
        loading: true,
        error: null
    } );
};

const getTasksByStatusSuccess = ( state, action ) => {
    const indexedData = {};
    action.data.forEach(task => indexedData[task.id] = task);
    return updateObject( state, {
        data: action.data,
        indexedData: indexedData,
        loading: false,
        error: null
    } );
};

const getTasksByStatusFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};


const getTasksStart = ( state, action ) => {
    return updateObject( state, {
        data: null,
        loading: true,
        error: null
    } );
};

const getTasksSuccess = ( state, action ) => {
    const indexedData = {};
    action.data.forEach(task => indexedData[task.id] = task);
    return updateObject( state, {
        data: action.data,
        indexedData: indexedData,
        loading: false,
        error: null
    } );
};

const getTasksFail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    } );
};

const createProductSuccess = ( state, action ) => {
    const newData = state.data.slice();
    newData.push(action.data);
    const newIndexedData = Object.assign({}, state.indexedData);
    return updateObject( state, {
        data: newData,
        indexedData: newIndexedData
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_TASKS_START: return getTasksStart(state, action);
        case actionTypes.GET_TASKS_SUCCESS: return getTasksSuccess(state, action);
        case actionTypes.GET_TASKS_FAIL: return getTasksFail(state, action);
        case actionTypes.GET_TASKS_BY_STATUS_START: return getTasksByStatusStart(state, action);
        case actionTypes.GET_TASKS_BY_STATUS_SUCCESS: return getTasksByStatusSuccess(state, action);
        case actionTypes.GET_TASKS_BY_STATUS_FAIL: return getTasksByStatusFail(state, action);
        case actionTypes.CREATE_TASK_SUCCESS: return createProductSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;