import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import KeyValueBlock from "../KeyValueBlock/KeyValueBlock";
import PopupForm from "../PopupForm/PopupForm";
import productFields from "../../forms/product";
import PopupDialog from "../PopupDialog/PopupDialog";
import Price from "../Price/Price";
import {ClipLoader} from 'react-spinners';
import {getProductGroups} from "../../store/actions/productGroups";

class Product extends Component {

    state = {
        formOpen: false,
        deleteDialogOpen: false
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getProduct(this.props.match.params.id)
        }

        if (!this.props.groups) {
            this.props.getProductGroups();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.deleted === false && this.props.deleted === true) {
            this.props.history.goBack();
        }
    }

    openForm() {
        this.setState({
            formOpen: true
        })
    }

    closeForm() {
        this.setState({
            formOpen: false
        })
    }

    openDeleteDialog() {
        this.setState({
            deleteDialogOpen: true
        })
    }

    closeDeleteDialog() {
        this.setState({
            deleteDialogOpen: false
        })
    }

    deleteProduct() {
        this.props.deleteProduct(this.props.match.params.id)
    }

    render() {
        if (!this.props.data) {
            return null;
        }
        let translations = {
            tag: "Product Tag",
            name: "Name",
            group: "Product Group",
            orderFormVisible: "Visible in Order Form",
            visible: "Visible",
            price: "Price",
            interval: "Payment Interval",
            funnelPrice: "Funnel Price",
            description: "Description",
            planId: "Braintree Plan Id",
            stripePlanId: "Stripe Plan Id",
            trialDays: "Free Trial Days",
            salesforceId: "Salesforce Product Id",
            memberpressId: "Memberpress Membership Id",
            learnuponPath: "LearnUpon Learning path Id",
            shopifyId: "Shopify ID",
            hubspotProductId: "Hubspot Product ID",
            clickfunnelsProductId: "Clickfunnels Product ID"
        };

        let actions = [
            {
                name: "Delete",
                action: () => this.openDeleteDialog()
            }
        ];

        let groupName = null;
        if (this.props.indexedGroups) {
            let indexedGroup = this.props.indexedGroups[this.props.data.group];
            if (indexedGroup) {
                groupName = indexedGroup.tag;
            }
        }

        const preFillData = Object.assign({}, this.props.data, {
            orderFormVisible: this.props.data.orderFormVisible ? this.props.data.orderFormVisible.toString() : 'false',
            visible: this.props.data.visible ? this.props.data.visible.toString() : 'false',
            name: this.props.data.descriptiveName
        });

        const keyValueBlockData = Object.assign({}, this.props.data, {
            name: this.props.data.descriptiveName,
            price: <Price price={this.props.data.price}/>,
            funnelPrice: <Price price={this.props.data.funnelPrice}/>,
            group: groupName,
            orderFormVisible: this.props.data.orderFormVisible ? this.props.data.orderFormVisible.toString() : 'false',
            visible: this.props.data.visible ? this.props.data.visible.toString() : 'false',
            description: this.props.data.description ? this.props.data.description.split('\n').map((item, index) => {
                return (
                    <span className="product-text" key={index}>
                        {item}
                        <br/>
                    </span>
                );
            }) : null
        });

        let groupOptions = {};
        let fields;
        if (this.props.groups) {
            this.props.groups.forEach(group => groupOptions[group.id] = group.tag);
            fields = productFields(groupOptions);
        }

        return (
            <React.Fragment>
                {this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/>
                    : null
                }
                {this.state.deleteDialogOpen ?
                    <PopupDialog
                        close={() => this.closeDeleteDialog()}
                        message="Are you sure you want to delete this product?"
                        no="Cancel"
                        yes="Delete"
                        yesClicked={() => this.deleteProduct()}/>
                    : null
                }
                {this.state.formOpen ?
                    <PopupForm
                        fields={fields}
                        prefill={preFillData}
                        onSubmit={(data) => {
                            this.props.editProduct(this.props.match.params.id, data);
                            this.closeForm();
                        }}
                        close={() => this.closeForm()}
                    /> : null
                }
                <KeyValueBlock
                    data={keyValueBlockData}
                    keys={["tag", "name", "group", "orderFormVisible", "visible", "price", "funnelPrice", "interval", "description", "planId", "stripePlanId", "trialDays", "salesforceId", "memberpressId", "learnuponPath", "shopifyId", "hubspotProductId", "clickfunnelsProductId"]}
                    translations={translations}
                    editClick={() => this.openForm()}
                    actions={actions}
                />
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.product.loading,
        data: state.product.data,
        error: state.product.error,
        deleting: state.product.deleting,
        deleted: state.product.deleted,
        groups: state.productGroups.productGroups,
        indexedGroups: state.productGroups.indexedGroups
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProduct: (id) => dispatch(actions.getProduct(id)),
        editProduct: (id, data) => dispatch(actions.updateProduct(id, data)),
        deleteProduct: (id) => dispatch(actions.deleteProduct(id)),
        getProductGroups: () => dispatch(getProductGroups())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);