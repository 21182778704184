import * as actionTypes from "./actionTypes";
import {postData} from "../../shared/utility";

export const loginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    }
};

export const loginSuccess = (token) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        token: token
    }
};

export const loginFail = (error) => {
    return {
        type: actionTypes.LOGIN_FAIL,
        error: error
    }
};

export const clearLoginError = () => {
    return {
        type: actionTypes.CLEAR_LOGIN_ERROR
    }
};

export const logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("authExpire");
    return {
        type: actionTypes.LOGOUT,
    }
};

export const login = (credentials) => {
    return dispatch => {
        dispatch(loginStart());
        postData(
            "/login",
            credentials,
            response => {
                console.log("success", response);
                localStorage.setItem("authToken", response.headers.authorization);
                localStorage.setItem("authExpire", response.headers.expires);
                dispatch(loginSuccess(response.headers.authorization))
            },
            error => {
                if (error.response && error.response.status === 403) {
                    dispatch(loginFail("Access Denied"))
                }
            },
             dispatch);
    };
};

export const checkToken = () => {
    return dispatch => {
        const token = localStorage.getItem("authToken");
        const expiration = localStorage.getItem("authExpire");
        if (token !== null && expiration !== null) {
            const expireDate = new Date(Number(expiration));
            const now = new Date();
            if (now < expireDate) {
                dispatch(loginSuccess(token))
            }
        }
    };
};