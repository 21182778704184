import {types} from "../shared/validators";

const formFields = [
    {
        key: "reportType",
        type: "select",
        placeholder: "Report Type",
        options: {
            "order-forms": "All Order Forms"
        },
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "startDate",
        type: "date",
        placeholder: "Start Date",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "endDate",
        type: "date",
        placeholder: "End Date",
        validation: [
            {
                type: types.REQUIRED
            },
        ]
    },
];

export default formFields