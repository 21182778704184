import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getProductGroupsStart = () => {
    return {
        type: actionTypes.GET_PRODUCT_GROUPS_START
    };
};

export const getProductGroupsSuccess = groups => {
    return {
        type: actionTypes.GET_PRODUCT_GROUPS_SUCCESS,
        groups
    };
};

export const getProductGroupsError = error => {
    return {
        type: actionTypes.GET_PRODUCT_GROUPS_ERROR,
        error
    };
};

export const getProductGroups = () => {
    return dispatch => {
        dispatch(getProductGroupsStart());
        getData(
            "/product-groups",
            response => {
                dispatch(getProductGroupsSuccess(response.data));
            },
            error => {
                dispatch(getProductGroupsError(error))
            },
            dispatch
        );
    }
};