import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import PopupForm from "../PopupForm/PopupForm";
import productFields from "../../forms/product";
import Price from "../Price/Price";
import { ClipLoader } from 'react-spinners';
import { getProductGroups } from '../../store/actions/productGroups'
import Card from '../Card/Card';
import TableView from '../TableView/TableView';
import {formatProductCard} from "../Card/FormatCard";
import {clearProductFail} from '../../store/actions/product';
import DuplicateNotification from "../Duplicate/DuplicateNotification";

class Products extends Component {
    state = {
        formOpen: false
    };

    componentDidMount() {
        this.props.getProducts();

        if (!this.props.groups) {
            this.props.getProductGroups();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.productCreated && this.props.productCreated) {
            this.props.history.push('/product/' + this.props.product.id)
        }
    }

    openForm() {
        this.setState({
            formOpen: true
        })
    }

    closeForm() {
        this.setState({
            formOpen: false
        })
    }

    render() {
        let data;

        if (this.props.data) {
            data = this.props.data.map(obj => {
                return Object.assign({}, obj, {
                    price: <Price price={obj.price}/>,
                    funnelPrice: <Price price={obj.funnelPrice}/>
                });
            });
        }

        let groupOptions = {};
        let fields;
        if (this.props.groups) {
            this.props.groups.forEach(group => groupOptions[group.id] = group.tag);
            fields = productFields(groupOptions);
        }

        return (
            <React.Fragment>
                {
                    this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/> :
                    null
                }
                {
                    this.state.formOpen ?
                    <PopupForm
                        fields={fields}
                        onSubmit={(data) => this.props.createProduct(data)}
                        close={() => this.closeForm()}
                    /> :
                    null
                }
                <button
                    type="button"
                    onClick={() => this.setState({formOpen: true})}
                    className="button productsButton"
                >
                    Add New Product
                </button>
                {
                    this.props.view === 'card' ?
                    <Card
                        data={this.props.data}
                        format={products => formatProductCard(products)}
                        url={'/product/'}
                    /> :
                    <TableView
                        data={data}
                        headers={{
                            descriptiveName: 'Product Name',
                            interval: 'Interval',
                            price: 'Price',
                            funnelPrice: 'Funnel Price',
                            description: 'Description'
                        }}
                        keys={['descriptiveName', 'interval', 'price', 'funnelPrice', 'description']}
                        rowClick={id => this.props.history.push('/product/' + id)}
                    />
                }
                <DuplicateNotification
                    statusCode={this.props.createProductError}
                    clearError={this.props.clearProductFail}
                    message={'That product already exists..'}
                />
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        loading: state.products.loading,
        data: state.products.data,
        error: state.products.error,
        productLoading: state.product.loading,
        productCreated: state.product.data && !state.product.error,
        product: state.product.data,
        groups: state.productGroups.productGroups,
        indexedGroups: state.productGroups.indexedGroups,
        view: state.view.view,
        createProductError: state.product.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProducts: (sort, dir) => dispatch(actions.getProducts(sort, dir)),
        createProduct: (data) => dispatch(actions.createProduct(data)),
        getProductGroups: () => dispatch(getProductGroups()),
        clearProductFail: () => dispatch(clearProductFail())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);