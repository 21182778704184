import React from 'react';
import classes from './PopupDialog.module.css';
import PropTypes from "prop-types";

const PopupDialog = (props) => {
    return (
        <React.Fragment>
            <div className={classes.backdrop} onClick={props.close}/>
            <div className={classes.popup}>
                <p className={classes.message}>
                    {props.message}
                </p>
                <div className={classes.buttonRow}>
                <button className={classes.button} onClick={props.yesClicked}>{props.yes}</button>
                <button className={classes.button} onClick={props.close}>{props.no}</button>
                </div>
            </div>
        </React.Fragment>
    )
};

export default PopupDialog;

PopupDialog.propTypes = {
  close: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  no: PropTypes.string.isRequired,
  yes: PropTypes.string.isRequired,
  yesClicked: PropTypes.func.isRequired
};