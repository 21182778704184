import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import thunk from 'redux-thunk';
import AuthReducer from './store/reducers/auth';
import UsersReducer from './store/reducers/users';
import UserReducer from './store/reducers/user';
import CompaniesReducer from './store/reducers/companies';
import CompanyReducer from './store/reducers/company';
import ProductsReducer from './store/reducers/products';
import ProductReducer from './store/reducers/product';
import ProductGroupsReducer from './store/reducers/productGroups';
import ProductGroupReducer from './store/reducers/productGroup';
import OrderFormsReducer from './store/reducers/orderForms';
import OrderFormReducer from './store/reducers/orderForm';
import TransactionsReducer from './store/reducers/transactions';
import TransactionReducer from './store/reducers/transaction';
import BundlesReducer from './store/reducers/bundles';
import BundleReducer from './store/reducers/bundle';
import AssetsReducer from './store/reducers/assets';
import AssetReducer from './store/reducers/asset';
import MeReducer from './store/reducers/me';
import CouponsReducer from './store/reducers/coupons';
import CouponReducer from './store/reducers/coupon';
import SearchReducer from './store/reducers/search';
import ViewReducer from './store/reducers/view';
import ReportsReducer from './store/reducers/reports';
import BundleUsersReducer from './store/reducers/bundleUsers'
import SideMenuReducer from './store/reducers/sidemenu';
import TasksReducer from './store/reducers/tasks';
import TaskReducer from './store/reducers/task';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: AuthReducer,
    users: UsersReducer,
    user: UserReducer,
    companies: CompaniesReducer,
    company: CompanyReducer,
    products: ProductsReducer,
    product: ProductReducer,
    productGroups: ProductGroupsReducer,
    productGroup: ProductGroupReducer,
    orderForms: OrderFormsReducer,
    orderForm: OrderFormReducer,
    transactions: TransactionsReducer,
    transaction: TransactionReducer,
    bundles: BundlesReducer,
    bundle: BundleReducer,
    assets: AssetsReducer,
    asset: AssetReducer,
    me: MeReducer,
    coupons: CouponsReducer,
    coupon: CouponReducer,
    search: SearchReducer,
    view: ViewReducer,
    reports: ReportsReducer,
    bundleUsers: BundleUsersReducer,
    sideMenu: SideMenuReducer,
    tasks: TasksReducer,
    task: TaskReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
