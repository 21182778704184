import React from 'react';
import {connect} from 'react-redux';
import tableClasses from "../../sharedCss/Tables.module.css";
import tableViewClasses from "./TableView.module.css";

const TableRow = (props) => {
    let cells = null;
    let tableCellClass;
    props.sideMenuHidden ? tableCellClass = tableViewClasses.extraPadding : tableCellClass = tableClasses.cell;

    if (props.data) {
        cells = props.keys.map(key => {
            return <td key={"cell-" + key} className={tableCellClass}>{props.data[key]}</td>;
        })
    }
    return (
        <tr className={tableClasses.row} onClick={props.click}>
            {cells}
        </tr>
    );
};

const mapStateToProps = state => ({
    sideMenuHidden: state.sideMenu.hidden
});

export default connect(mapStateToProps)(TableRow);