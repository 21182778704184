import React from 'react';
import DateRangeChooser from '../Date/DateRangeChooser';
import IssuersOptions from '../Issuers/IssuersOptions';
import {FaExclamation} from 'react-icons/fa';
import '../../styles/components/OrderForms.scss';

class IssuersAndDatesForm extends React.Component {
    state = {
        date: null,
        showDateRangePicker: false,
        issuer: null,
        filtersError: false
    };

    setDateRange = date => this.setState({date});
    setIssuer = issuer => this.setState({issuer});

    submitFilters(e) {
        e.preventDefault();
        let startDate, endDate;

        if (this.state.date) {
            if (this.state.date[0]) {
                startDate = this.state.date[0].toISOString();
            }

            if (this.state.date[1]) {
                endDate = this.state.date[1].toISOString();
            }
        }

        if (this.state.date && !this.state.issuer) {
            this.props.filter(startDate, endDate, null, null);
        } else if (this.state.issuer && this.state.date) {
            this.props.filter(startDate, endDate, null, this.state.issuer);
        } else if (this.state.issuer && !this.state.date) {
            this.props.filter(null, null, null, this.state.issuer);
        } else {
            this.setState({filtersError: true});
        }
    }

    showFilterError() {
        if (this.state.filtersError) {
            return (
                <div className="backdrop">
                    <div className="form">
                        <FaExclamation size={90}/>
                        <h3>Please choose dates and/or issuers to filter.</h3>
                        <button
                            type="button"
                            className="button"
                            onClick={() => this.setState({filtersError: false})}
                        >
                            Okay
                        </button>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.showFilterError()}
                <form
                    onSubmit={e => this.submitFilters(e)}
                    className="issuers-and-dates-form"
                >
                    <IssuersOptions setIssuer={this.setIssuer} currentIssuer={this.state.issuer}/>
                    <DateRangeChooser setDateRange={this.setDateRange} dates={this.state.date}/>
                    <div className="filter-buttons-container">
                        <button
                            type="submit"
                            className="button"
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            className="button"
                            onClick={() => this.setState({issuer: null, date: null})}
                        >
                            Reset
                        </button>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default IssuersAndDatesForm;