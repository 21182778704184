import React, { Component } from 'react';
import './App.scss';
import { connect } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import Users from "./components/Users/Users";
import * as actions from "./store/actions";
import SideMenu from "./components/SideMenu/SideMenu";
import User from "./components/Users/User";
import Products from "./components/Products/Products";
import Product from "./components/Products/Product";
import ProductGroups from "./components/Products/ProductGroups";
import ProductGroup from "./components/Products/ProductGroup";
import Assets from "./components/Assets/Assets";
import Asset from "./components/Assets/Asset";
import Transactions from "./components/Transactions/Transactions";
import Transaction from "./components/Transactions/Transaction";
import Bundles from "./components/Bundles/Bundles";
import Bundle from "./components/Bundles/Bundle";
import OrderForms from "./components/OrderForms/OrderForms";
import OrderForm from "./components/OrderForms/OrderForm";
import Coupons from "./components/Coupons/Coupons";
import Coupon from "./components/Coupons/Coupon";
import Companies from "./components/Companies/Companies";
import Company from "./components/Companies/Company";
import SearchResults from "./components/Search/SearchResults";
import { getStoredView } from "./store/actions/view";
import Reports from "./components/Reports/Reports";
import Header from './components/Header/Header';
import Tasks from "./components/Tasks/Tasks";
import Task from "./components/Tasks/Task";

class App extends Component {
    componentDidMount() {
        if (!this.props.token) {
            this.props.checkLogin()
        }

        this.props.getStoredView();
    }

    render() {
        let routes = <Switch>
            <Route path="/" component={Login}/>
        </Switch>;

        if (this.props.authToken) {
            routes = <Switch>
                <Route path="/users" component={Users}/>
                <Route path="/user/:id" component={User}/>
                <Route path="/companies" component={Companies}/>
                <Route path="/company/:id" component={Company}/>
                <Route path="/products" component={Products}/>
                <Route path="/product/:id" component={Product}/>
                <Route path="/product-groups" component={ProductGroups}/>
                <Route path="/product-group/:id" component={ProductGroup}/>
                <Route path="/tasks" component={Tasks}/>
                <Route path="/task/:id" component={Task}/>
                <Route path="/assets" component={Assets}/>
                <Route path="/asset/:id" component={Asset}/>
                <Route path="/transactions" component={Transactions}/>
                <Route path="/transaction/:id" component={Transaction}/>
                <Route path="/bundles" component={Bundles}/>
                <Route path="/bundle/:id" component={Bundle}/>
                <Route path="/order-forms" component={OrderForms}/>
                <Route path="/order-form/:id" component={OrderForm}/>
                <Route path="/coupons" component={Coupons}/>
                <Route path="/coupon/:id" component={Coupon}/>
                <Route path="/search-results" component={SearchResults}/>
                <Route path="/search-results/:searchValue" component={SearchResults}/>
                <Route path="/reports" component={Reports}/>
                <Route path="/" component={Home}/>
            </Switch>;

            return (
              <div className="App">
                  <SideMenu/>
                  <div className="root">
                      <Header/>
                      <div className="window">
                          {routes}
                      </div>
                  </div>
              </div>
            );
        } else {
            return (
                    <div className="App">
                        {routes}
                    </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authToken: state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkLogin: () => dispatch(actions.checkToken()),
        login: (credentials) => dispatch(actions.login(credentials)),
        getStoredView: () => dispatch(getStoredView())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
