import React, {Component} from 'react';
import Card from "../Card/Card";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {ClipLoader} from "react-spinners";
import PopupForm from "../PopupForm/PopupForm";
import companyFields from "../../forms/company";
import Paginator from "../Paginator/Paginator";
import TableView from "../TableView/TableView";
import {formatCompanyCard} from "../Card/FormatCard";
import '../../styles/components/Companies.scss';

class Companies extends Component {

    state = {
        email: "",
        formOpen: false,
        filters: {},
        sortBy: null,
        sortDirection: "asc"
    };

    componentDidMount() {
        this.props.getCompanies(0)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.companyCreated && this.props.companyCreated) {
            this.props.history.push('/company/' + this.props.company.id)
        }
    }

    closeForm() {
        this.setState({
            formOpen: false
        })
    }

    render() {
        let paginator = null;
        if (this.props.pages && this.props.pages > 1) {
            paginator = <Paginator
                pages={this.props.pages}
                currentPage={this.props.currentPage}
                pageClick={page => this.props.getCompanies(page, null, null)}
            />
        }

        let popupForm = this.state.formOpen ?
            <PopupForm
                fields={companyFields}
                onSubmit={(data) => this.props.createCompany(data)}
                close={() => this.closeForm()}
            /> : null;

        return (
            <React.Fragment>
                {this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/>
                    : null
                }
                {popupForm}
                <div className="companies-menu">
                    <button
                        type="button"
                        onClick={() => this.setState({formOpen: true})}
                        className="button"
                    >
                        Add New Company
                    </button>
                    {paginator}
                </div>
                {
                    this.props.view === 'card' ?
                        <Card
                            data={this.props.data}
                            format={company => formatCompanyCard(company)}
                            currentPage={this.props.currentPage}
                            pages={this.props.pages}
                            url={"/company/"}
                        /> :
                        <TableView
                            data={this.props.data}
                            headers={{
                                name: 'Company Name',
                                status: 'Status',
                                role: 'Role',
                                phone: 'Phone',
                                email: 'E-mail'
                            }}
                            keys={['name', 'status', 'role', 'phone', 'email']}
                            rowClick={id => this.props.history.push('/company/' + id)}
                        />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.companies.loading,
        data: state.companies.data,
        pages: state.companies.pages,
        currentPage: state.companies.currentPage,
        error: state.companies.error,
        companyCreated: state.company.data && !state.company.error,
        company: state.company.data,
        view: state.view.view
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCompanies: (page, sort, dir) => dispatch(actions.getCompanies(page, sort, dir)),
        getFilteredCompanies: (search, value) => dispatch(actions.getFilteredCompanies(search, value)),
        getCompaniesByEmail: (email) => dispatch(actions.getCompaniesByEmail(email)),
        createCompany: (data) => dispatch(actions.createCompany(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);