import {types} from "../shared/validators";

const formFields = [
    {
        key: "email",
        type: "text",
        placeholder: "Email Address",
        validation: [
            {
                type: types.REQUIRED
            },
            {
                type: types.EMAIL
            }
        ]
    },
    {
        key: "password",
        type: "password",
        placeholder: "Password",
        validation: [
            {
                type: types.REQUIRED
            },
        ]
    },
    {
        key: "firstName",
        type: "text",
        placeholder: "First Name",
        validation: [
            {
                type: types.REQUIRED
            },
        ]
    },
    {
        key: "lastName",
        type: "text",
        placeholder: "Last Name",
        validation: [
            {
                type: types.REQUIRED
            },
        ]
    },
    {
        key: "phone",
        type: "text",
        placeholder: "Phone Number",
    },
    {
        key: "hubspotUserId",
        type: "number",
        placeholder: "Hubspot User ID"
    },
];

export default formFields