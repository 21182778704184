import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    data: null,
    loading: false,
    error: null,
    cancelling: false,
    canceled: false,
    refunding: false,
    refunded: false
};

const getTransactionStart = (state, action) => {
    return updateObject(state, {
        data: null,
        loading: true,
        error: null
    });
};

const getTransactionSuccess = (state, action) => {
    return updateObject(state, {
        data: action.data,
        loading: false,
        error: null
    });
};

const getTransactionFail = (state, action) => {
    return updateObject(state, {
        data: null,
        loading: false,
        error: action.error
    });
};

const cancelStart = (state, action) => {
    return updateObject(state, {
        cancelling: true,
        canceled: false,
        error: null
    });
};

const cancelSuccess = (state, action) => {
    return updateObject(state, {
        cancelling: false,
        canceled: true,
        refunded: false,
        error: null
    });
};

const cancelFail = (state, action) => {
    return updateObject(state, {
        cancelling: false,
        error: action.error
    });
};

const refundStart = (state, action) => {
    return updateObject(state, {
        refunding: true,
        refunded: false,
        error: null
    })
};

const refundSuccess = (state, action) => {
    return updateObject(state, {
        refunding: false,
        refunded: true,
        canceled: false,
        error: null
    })
};

const refundFail = (state, action) => {
    return updateObject(state, {
        refunding: false,
        refunded: false,
        error: action.error
    })
};

const cancelAndRefundStart = (state, action) => {
    return updateObject(state, {
        cancelling: true,
        refunding: true,
        canceled: false,
        refunded: false
    })
};

const cancelAndRefundSuccess = (state, action) => {
    return updateObject(state, {
        cancelling: false,
        refunding: false,
        canceled: true,
        refunded: true
    })
};

const cancelAndRefundFail = (state, action) => {
    return updateObject(state, {
        cancelling: false,
        refunding: false,
        canceled: false,
        refunded: false,
        error: action.error
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRANSACTION_START:
            return getTransactionStart(state, action);
        case actionTypes.GET_TRANSACTION_SUCCESS:
            return getTransactionSuccess(state, action);
        case actionTypes.GET_TRANSACTION_FAIL:
            return getTransactionFail(state, action);
        case actionTypes.CANCEL_TRANSACTION_START:
            return cancelStart(state, action);
        case actionTypes.CANCEL_TRANSACTION_SUCCESS:
            return cancelSuccess(state, action);
        case actionTypes.CANCEL_TRANSACTION_FAIL:
            return cancelFail(state, action);
        case actionTypes.REFUND_TRANSACTION_START:
            return refundStart(state, action);
        case actionTypes.REFUND_TRANSACTION_SUCCESS:
            return refundSuccess(state, action);
        case actionTypes.REFUND_TRANSACTION_FAIL:
            return refundFail(state, action);
        case actionTypes.CANCEL_SUBSCRIPTION_START:
            return cancelStart(state, action);
        case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
            return cancelSuccess(state, action);
        case actionTypes.CANCEL_SUBSCRIPTION_FAIL:
            return cancelFail(state, action);
        case actionTypes.CANCEL_AND_REFUND_TRANSACTION_START:
            return cancelAndRefundStart(state, action);
        case actionTypes.CANCEL_AND_REFUND_TRANSACTION_SUCCESS:
            return cancelAndRefundSuccess(state, action);
        case actionTypes.CANCEL_AND_REFUND_TRANSACTION_FAIL:
            return cancelAndRefundFail(state, action);
        default:
            return state;
    }
};

export default reducer;