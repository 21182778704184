import * as actionTypes from "./actionTypes";
import {getData, postData} from "../../shared/utility";

export const getUserStart = () => {
    return {
        type: actionTypes.GET_USER_START
    }
};

export const getUserSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_SUCCESS,
        data: data
    }
};

export const getUserFail = (error) => {
    return {
        type: actionTypes.GET_USER_FAIL,
        error: error
    }
};

export const createUserStart = () => {
    return {
        type: actionTypes.CREATE_USER_START
    }
};

export const createUserSuccess = (data) => {
    return {
        type: actionTypes.CREATE_USER_SUCCESS,
        data: data
    }
};

export const createUserFail = (error) => {
    return {
        type: actionTypes.CREATE_USER_FAIL,
        error: error.status,
        errorResponse: error.data.message
    }
};

export const clearUserFail = () => {
    return {
        type: actionTypes.CLEAR_USER_FAIL
    }
};

export const changeUserRoleStart = () => {
    return {
        type: actionTypes.CHANGE_USER_ROLE_START
    }
};

export const changeUserRoleSuccess = (data) => {
    return {
        type: actionTypes.CHANGE_USER_ROLE_SUCCESS,
        data: data
    }
};

export const changeUserRoleFail = (error) => {
    return {
        type: actionTypes.CHANGE_USER_ROLE_FAIL,
        error: error
    }
};

export const changeUserPasswordStart = () => {
    return {
        type: actionTypes.CHANGE_USER_PASSWORD_START
    }
};

export const changeUserPasswordSuccess = () => {
    return {
        type: actionTypes.CHANGE_USER_PASSWORD_SUCCESS,
    }
};

export const changeUserPasswordFail = (error) => {
    return {
        type: actionTypes.CHANGE_USER_PASSWORD_FAIL,
        error: error
    }
};

export const getUser = (id) => {
    return dispatch => {
        dispatch(getUserStart());
        getData(
            "/users/" + id,
            response => {
                dispatch(getUserSuccess(response.data))
            },
            error => {
                dispatch(getUserFail(error.response.status))
            },
            dispatch);
    };
};


export const getUserByEmail = (email) => {
    return dispatch => {
        dispatch(getUserStart());
        getData(
            "/users/email?email=" + email,
            response => {
                dispatch(getUserSuccess(response.data))
            },
            error => {
                dispatch(getUserSuccess(error.response.status))
            },
            dispatch);
    };
};

export const createUser = (data) => {
    return dispatch => {
        dispatch(createUserStart());
        postData(
            "/users",
            data,
            response => {
                dispatch(createUserSuccess(response.data))
            },
            error => {
                console.log(error.response.data.message);
                dispatch(createUserFail(error.response))
            },
            dispatch
        );
    };
};

export const updateUser = (userId, data) => {
    return dispatch => {
        dispatch(createUserStart());
        postData(
            "/users/" + userId,
            data,
            response => {
                dispatch(createUserSuccess(response.data))
            },
            error => {
                dispatch(createUserFail(error.response.status))
            },
            dispatch
        );
    };
};

export const changeUserRole = (id, data) => {
    return dispatch => {
        dispatch(changeUserRoleStart());
        postData(
            "/users/" + id + "/role",
            data,
            response => {
                dispatch(changeUserRoleSuccess(response.data))
            },
            error => {
                dispatch(changeUserRoleFail(error.response.status))
            },
            dispatch
        );
    };
};

export const changeUserPassword = (id, data) => {
    return dispatch => {
        dispatch(changeUserPasswordStart());
        postData(
            "/users/" + id + "/password",
            data,
            response => {
                dispatch(changeUserPasswordSuccess())
            },
            error => {
                dispatch(changeUserPasswordFail(error.response.status))
            },
            dispatch
        );
    };
};