import * as actionTypes from './actionTypes';
import {getData, deleteData, postData} from "../../shared/utility";

export const getCouponsStart = () => ({
    type: actionTypes.GET_COUPONS_START
});

export const getCouponsSuccess = coupons => ({
    type: actionTypes.GET_COUPONS_SUCCESS,
    coupons
});

export const getCouponsError = error => ({
    type: actionTypes.GET_COUPONS_ERROR,
    error
});

export const getCoupons = () => dispatch => {
    dispatch(getCouponsStart());
    getData(
        "/coupon",
        response => {
            console.log(response);
            dispatch(getCouponsSuccess(response.data))
        },
        error => {
            console.log(error);
            dispatch(getCouponsError(error));
        }
    );
};

export const deleteCouponStart = () => ({
    type: actionTypes.DELETE_COUPON_START
});

export const deleteCouponSuccess = id => ({
    type: actionTypes.DELETE_COUPON_SUCCESS,
    id
});

export const deleteCouponError = error => ({
    type: actionTypes.DELETE_COUPON_ERROR,
    error
});

export const deleteCoupon = id => dispatch => {
    dispatch(deleteCouponStart());
    deleteData(
        "/coupon/" + id,
        () => dispatch(deleteCouponSuccess(id)),
        error => dispatch(deleteCouponError(error)),
        dispatch
    )
};

export const updateCouponStart = () => ({
    type: actionTypes.UPDATE_COUPON_START
});

export const updateCouponSuccess = data => ({
    type: actionTypes.UPDATE_COUPON_SUCCESS,
    data
});

export const updateCouponError = error => ({
    type: actionTypes.UPDATE_COUPON_ERROR,
    error
});

export const updateCoupon = data => dispatch => {
    dispatch(updateCouponStart());
    postData(
        "/coupon/update",
        data,
        res => {
            console.log(res.data);
            dispatch(updateCouponSuccess(res.data))
        },
        error => {
            console.log(error);
            dispatch(updateCouponError(error));
        },
        dispatch
    )
};
