import React, {useState} from 'react';
import classes from './SideMenu.module.css';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import permissions from '../../shared/permissions';
import '../../styles/components/SideMenu.scss';

const SideMenu = (props) => {
    const [location, setLocation] = useState(null);

    if (!props.me) {
        return null;
    }

    const links = [
        "users",
        "companies",
        "products",
        "product-groups",
        "assets",
        "transactions",
        "order-forms",
        "reports",
        "coupons",
        "tasks"
    ].map(page => {
        // console.log(props.me.role, permissions[page]);
        let cssClass = classes.link;
        if (location === page) {
            cssClass += ' ' + classes.active;
        }

        if (permissions[page] && permissions[page].get.includes(props.me.role)) {
            return <Link
                key={page}
                className={cssClass}
                to={"/" + page}
                onClick={() => setLocation(page)}
            >
                {page.charAt(0).toUpperCase() + page.slice(1)}
            </Link>
        } else {
            return null;
        }
    });

    let toggleClass;
    !props.toggleSideMenu ? toggleClass = classes.root : toggleClass = classes.hideRoot;

    return (
        <div className={toggleClass}>
            <ul className={classes.links}>
                {links}
            </ul>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        loading: state.me.loading,
        me: state.me.data,
        error: state.me.error,
        toggleSideMenu: state.sideMenu.hidden
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
