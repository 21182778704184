import axios from "axios";
import { logout } from "../store/actions";
import { loginFail } from "../store/actions/auth";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const getReportDownloadLink = (fileName) => {
    if (process.env.NODE_ENV !== 'production') {
        return 'https://thewolfhub.com/reports/download/' + fileName;
    }
    return 'http://localhost:5001/reports/download/' + fileName;
};

export const getData = (subUrl, onSuccess, onFail, dispatch) => {
    let url = 'https://thewolfhub.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url = 'http://localhost:5001' + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const authToken = localStorage.getItem("authToken");
    if (authToken !== null) {
        config.headers["Authorization"] = authToken
    }

    axios.get(url, config)
        .then(response => {
            try {
                onSuccess(response);
            } catch(error) {
                console.error(error);
            }
        })
        .catch(err => {
            if (!err.response) {
                onFail({response: {message: "Something unexpected went wrong"}});
                return;
            }
            if (err.response && err.response.status === 403) {
                dispatch(logout());
                return;
            }
            onFail(err)
        });
};

export const deleteData = (subUrl, onSuccess, onFail, dispatch) => {
    let url = 'https://thewolfhub.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url = 'http://localhost:5001' + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const authToken = localStorage.getItem("authToken");
    if (authToken !== null) {
        config.headers["Authorization"] = authToken
    }

    axios.delete(url, config)
        .then(response => {
            try {
                onSuccess(response);
            } catch(error) {
                console.error(error);
            }
        })
        .catch(err => {
            if (!err.response) {
                onFail({response: {message: "Something unexpected went wrong"}});
                return;
            }
            if (err.response && err.response.status === 403) {
                dispatch(logout());
                return;
            }
            onFail(err)
        });
};

export const putData = (subUrl, data, onSuccess, onFail, dispatch) => {
    let url = 'https://thewolfhub.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url = 'http://localhost:5001' + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    const authToken = localStorage.getItem("authToken");
    if (authToken !== null) {
        config.headers["Authorization"] = authToken
    }

    axios.put(url, data, config)
        .then(response => {
            try {
                onSuccess(response);
            } catch(error) {
                console.error(error);
            }
        })
        .catch(err => {
            if (!err.response) {
                onFail({response: {message: "Something unexpected went wrong"}});
                return;
            }
            if (err.response && err.response.status === 403) {
                dispatch(logout());
                return;
            }
            onFail(err)
        });
};

export const postData = (subUrl, data, onSuccess, onFail, dispatch) => {
    let url = 'https://thewolfhub.com' + subUrl;
    if (process.env.NODE_ENV !== 'production') {
        url = 'http://localhost:5001' + subUrl;
    }

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    const authToken = localStorage.getItem("authToken");
    if (authToken !== null) {
        config.headers["Authorization"] = authToken
    }

    axios.post(url, data, config)
        .then(response => {
            try {
                onSuccess(response);
            } catch(error) {
                console.error(error);
            }
        })
        .catch(err => {
            if (!err.response) {
                onFail({response: {message: "Something unexpected went wrong"}});
                return;
            }
            if (err.response && err.response.status === 403) {
                dispatch(loginFail("Incorrect username or password.."));
                return;
            }
            onFail(err)
        });
};
