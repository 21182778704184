import {types} from "../shared/validators";

const formFields = [
    {
        key: "email",
        type: "text",
        placeholder: "Email Address",
        validation: [
            {
                type: types.REQUIRED
            },
            {
                type: types.EMAIL
            }
        ]
    },
    {
        key: "accountOwnerEmail",
        type: "text",
        placeholder: "Owner Email Address",
        validation: [
            {
                type: types.REQUIRED
            },
            {
                type: types.EMAIL
            }
        ]
    },
    {
        key: "name",
        type: "text",
        placeholder: "Company Name",
        validation: [
            {
                type: types.REQUIRED
            },
        ]
    },
    {
        key: "phone",
        type: "text",
        placeholder: "Phone Number",
    },
];

export default formFields