import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {getFilteredUsers} from "../../store/actions/users";
import {clearUserFail} from "../../store/actions/user";
import {connect} from "react-redux";
import classes from './Users.module.css';
import PopupForm from "../PopupForm/PopupForm";
import userFields from "../../forms/user";
import {ClipLoader} from 'react-spinners';
import Filters from "./Filters";
import Card from '../Card/Card'
import Paginator from '../Paginator/Paginator';
import Search from "../Search/Search";
import TableView from '../TableView/TableView';
import {formatUserCard} from "../Card/FormatCard";
import DuplicateNotification from "../Duplicate/DuplicateNotification";

class Users extends Component {
    state = {
        email: "",
        formOpen: false,
        filters: {},
        sortBy: null,
        sortDirection: "asc"
    };

    componentDidMount() {
        this.props.getUsers(0)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.userCreated && this.props.userCreated) {
            this.props.history.push('/user/' + this.props.user.id)
        }
    }

    changeSortDirection(key) {
        let dir = "asc";
        if (this.state.sortDirection === "asc") {
            dir = "desc"
        }

        this.setState({sortDirection: dir});
        this.props.sort(key, dir);
    }

    setEmail(event) {
        this.setState({
            email: event.target.value
        })
    }

    search() {
        if (this.state.email === "") {
            return;
        }
        this.props.getUsersByEmail(this.state.email)
    }

    openForm() {
        this.setState({
            formOpen: true
        })
    }

    closeForm() {
        this.setState({
            formOpen: false
        })
    }

    render() {
        let paginator = null;
        if (this.props.pages && this.props.pages > 1) {
            paginator = <Paginator
                            pages={this.props.pages}
                            currentPage={this.props.currentPage}
                            pageClick={page => this.props.getUsers(page, null, null)}
                        />
        }

        return (
            <React.Fragment>
                {
                    this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/>
                    : null
                }
                {
                    this.state.formOpen ?
                    <PopupForm
                        fields={userFields}
                        onSubmit={(data) => this.props.createUser(data)}
                        close={() => this.closeForm()}
                    />
                    : null
                }
                <Search
                    history={this.props.history}
                />
                <div className={classes.searchForm}>
                    <button
                        type="button"
                        onClick={() => this.setState({formOpen: true})}
                        className={classes.addButton}
                    >
                        Add New User
                    </button>
                    <div className={classes.userPaginator}>
                        {paginator}
                    </div>
                </div>
                {
                    Object.keys(this.state.filters).length > 0 ?
                    <Filters
                        data={this.state.filters}
                        onFilterClick={() => {
                            this.setState({filters: {}});
                            this.props.getUsers(0);
                            }
                        }
                    /> :
                    ''
                }
                {
                    this.props.view === 'card' ?
                    <Card
                        data={this.props.data}
                        format={user => formatUserCard(user)}
                        currentPage={this.props.currentPage}
                        pages={this.props.pages}
                        url={'/user/'}
                    /> :
                    <TableView
                        data={this.props.data}
                        headers={{
                            firstName: 'First Name',
                            lastName: 'Last Name',
                            status: 'Status',
                            role: 'Role',
                            phone: 'Phone',
                            email: 'E-mail'
                        }}
                        keys={['firstName', 'lastName', 'status', 'role', 'phone', 'email']}
                        rowClick={id =>
                            this.props.history.push('/user/' + id)

                        }
                    />
                }
                <DuplicateNotification
                    statusCode={this.props.createUserError}
                    clearError={this.props.clearUserFail}
                    message={'That email address is already in use..'}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.users.loading,
        data: state.users.data,
        pages: state.users.pages,
        currentPage: state.users.currentPage,
        error: state.users.error,
        userCreated: state.user.data && !state.user.error,
        user: state.user.data,
        view: state.view.view,
        createUserError: state.user.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUsers: (page, sort, dir) => dispatch(actions.getUsers(page, sort, dir)),
        getFilteredUsers: (search, value) => dispatch(getFilteredUsers(search, value)),
        getUsersByEmail: (email) => dispatch(actions.getUsersByEmail(email)),
        createUser: (data) => dispatch(actions.createUser(data)),
        clearUserFail: () => dispatch(clearUserFail())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
