import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {ClipLoader} from 'react-spinners';
import DateTime from "../Date/Date";
import Card from '../Card/Card';
import '../../styles/components/Transactions.scss';
import TableView from "../TableView/TableView";
import {formatBundleCard} from "../Card/FormatCard";

class Bundles extends Component {
    state = {
        formOpen: false
    };

    componentDidMount() {
        if (this.props.userId) {
            this.props.getBundlesForUser(this.props.userId)
        } else if (this.props.companyId) {
            this.props.getBundlesForCompany(this.props.companyId)
        } else {
            this.props.getBundles(this.props.currentPage || 0)
        }
    }

    render() {
        if (!this.props.data) {
            return null;
        }

        const data = this.props.data.map(bundle => {
            return Object.assign({}, bundle, {
                created: <DateTime date={bundle.created}/>
            })
        });

        return (
            <React.Fragment>
                {
                    this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/> :
                    null
                }
                {
                    this.props.view === 'card' ?
                    <Card
                        data={data}
                        format={bundles => formatBundleCard(bundles)}
                        url={'/bundle/'}
                    /> :
                    <TableView
                        data={data}
                        headers={{
                            seatsTotal: 'Total Seats',
                            numberSeated: 'Users Seated',
                            created: 'Created'
                        }}
                        keys={['seatsTotal', 'numberSeated', 'created']}
                        rowClick={id => {
                            console.log(id);
                            console.log(data);
                            this.props.history.push('/bundle/' + id)
                        }
                        }
                    />
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.bundles.loading,
        data: state.bundles.data,
        error: state.bundles.error,
        pages: state.bundles.pages,
        currentPage: state.bundles.currentPage,
        view: state.view.view
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBundles: (page, sort, dir) => dispatch(actions.getBundles(page, sort, dir)),
        getBundlesForUser: (userId) => dispatch(actions.getBundlesForUser(userId)),
        getBundlesForCompany: (companyId) => dispatch(actions.getBundlesForCompany(companyId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bundles);
