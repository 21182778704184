import React, {Component} from 'react';
import PopupForm from "../PopupForm/PopupForm";
import orderFormFields from "../../forms/orderForm";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import Price from "../Price/Price";
import {ClipLoader} from 'react-spinners';
import Card from '../Card/Card';
import Paginator from '../Paginator/Paginator';
import '../../styles/components/OrderForms.scss';
import TableView from '../TableView/TableView';
import {formatOrderFormCard} from "../Card/FormatCard";
import IssuersAndDatesForm from './IssuersAndDatesForm';
import DateRangeChooser from '../Date/DateRangeChooser';
import DateError from '../Date/DateError';

class OrderForms extends Component {
    state = {
        formOpen: false,
        linkShowing: null,
        date: null,
        showDateRangePicker: false
    };

    componentDidMount() {
        if (this.props.userId) {
            this.props.getOrderFormsForUser(this.props.userId);
        } else {
            this.props.getAllOrderForms(this.props.currentPage === undefined ? 0 : this.props.currentPage, null, null);
        }

        this.props.getProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.date === null && this.state.date !== null) {
            const startDate = this.state.date[0].toISOString();
            const endDate = this.state.date[1].toISOString();

            if (this.props.userId) {
                this.props.filterBy(startDate, endDate, this.props.userId, null);
            } else {
                this.props.filterBy(startDate, endDate, null, null);
            }
        }
    }

    openCreationForm() {
        this.setState({
            ...this.state,
            formOpen: true,
            linkShowing: null
        })
    }

    closeCreationForm() {
        this.setState({
            ...this.state,
            formOpen: false,
            linkShowing: null
        })
    }

    setDateRange = date => this.setState({date});

    render() {
        if (!this.props.data) {
            return null;
        }

        let productOptions = {};
        const sourceOptions = [
            "Facebook",
            "LinkedIn",
            "YouTube",
            "Google",
            "No Ad Source Provided"
        ];
        const funnelOptions = ["The Wolf Network", "B2B", "B2C", "MM", "No Funnel Provided"];
        let fields;
        let path;

        if (this.props.match) {
            path = this.props.match.path;
        } else if (this.props.history.location.pathname) {
            path = this.props.history.location.pathname;
        }

        if (this.props.products) {
            this.props.products
                .filter(product => product.orderFormVisible)
                .forEach(product => {
                    productOptions[product.id] = product.descriptiveName;
                });

            const myFunction = (data) => {
                return data.productId && this.props.indexedProducts[data.productId].interval !== 'ONE_TIME';
            };

            const optionsFunction = (data) => {
                const productId = data.productId;
                const products = this.props.indexedProducts;
                const product = products[productId];

                let result = {};
                if (product) {
                    if (product.planId) {
                        result["BRAINTREE"] = "Braintree";
                    }

                    if (product.stripePlanId) {
                        result["STRIPE"] = "Stripe";
                    }

                    if (product.interval === "ONE_TIME" && product.id === 92) { //SLSC australia product ID is stripe only
                        result["STRIPE"] = "Stripe";
                    } else if (product.interval === "ONE_TIME" || (!product.planId && !product.stripePlanId)) {
                        result["BRAINTREE"] = "Braintree";
                        result["STRIPE"] = "Stripe";
                    }

                }
                return result;
            };

            fields = orderFormFields(productOptions,
                myFunction,
                optionsFunction,
                funnelOptions,
                sourceOptions);
        }

        let paginator = null;
        if (this.props.pages && this.props.pages > 1) {
            paginator = <Paginator
                pages={this.props.pages}
                currentPage={this.props.currentPage}
                pageClick={page => this.props.getAllOrderForms(page, null, null)}
            />
        }

        const data = this.props.data.map(orderform => {
            return {
                id: orderform.id,
                tag: orderform.productTag,
                buyPrice: <Price price={orderform.price}/>,
                paymentState: orderform.state ? orderform.state : "Unknown",
                issuerName: orderform.issuerName
            }
        });

        return (
            <React.Fragment>
                {
                    this.props.loading ?
                        <ClipLoader sizeUnit="px" size={100} color="black"/>
                        : null
                }
                {
                    this.state.formOpen ?
                        <PopupForm
                            fields={fields}
                            onSubmit={(data) => {
                                this.props.createOrderForm(this.props.userId, data);
                                this.closeCreationForm();
                            }}
                            close={() => this.closeCreationForm()}
                            userId={this.props.userId}
                        /> :
                        null
                }
                <div className="orderforms-buttons-container">
                    {
                        path.includes('/user') ?
                            <DateRangeChooser
                                setDateRange={this.setDateRange}
                                dates={this.state.date}
                            /> :
                            <IssuersAndDatesForm
                                data={this.props.data}
                                filter={this.props.filterBy}
                            />
                    }
                    {
                        path === "/order-forms" ?
                            <div className="paginator-container">
                                {paginator}
                            </div> :
                            <button
                                type="button"
                                onClick={() => this.openCreationForm()}
                                className="button orderform-button"
                            >
                                Add New Order Form
                            </button>
                    }
                </div>
                {
                    this.props.hideCard ?
                        null :
                        this.props.view === 'card' ?
                            <Card
                                data={data}
                                format={orderForms => formatOrderFormCard(orderForms)}
                                url={'/order-form/'}
                                userShowAll={this.props.userShowAll}
                                hideShowAllButton={this.props.hideShowAllButton}
                            /> :
                            <TableView
                                data={data}
                                headers={{
                                    tag: 'Tag',
                                    paymentState: 'Payment State',
                                    buyPrice: 'Price',
                                    issuerName: 'Issuer Name'
                                }}
                                keys={['tag', 'paymentState', 'buyPrice', 'issuerName']}
                                rowClick={id => this.props.history.push('/order-form/' + id)}
                            />
                }
                <DateError orderFormError={this.props.orderFormError} clearError={this.props.clearOrderFormError}/>
            </React.Fragment>
        );
    }
}

const
    mapStateToProps = (state) => {
        return {
            data: state.orderForms.data,
            loading: state.orderForms.loading,
            error: state.products.error,
            orderFormError: state.orderForms.error,
            products: state.products.data,
            indexedProducts: state.products.indexedData,
            productsLoading: state.products.loading,
            pages: state.orderForms.pages,
            currentPage: state.orderForms.currentPage,
            view: state.view.view
        };
    };

const
    mapDispatchToProps = dispatch => {
        return {
            getProducts: () => dispatch(actions.getProducts()),
            createOrderForm: (id, data) => dispatch(actions.createOrderForm(id, data)),
            getAllOrderForms: (number, sort, dir) => dispatch(actions.getOrderForms(number, sort, dir)),
            getOrderFormsForUser: (userId) => dispatch(actions.getOrderFormsForUser(userId)),
            filterBy: (startDate, endDate, userId, issuerId) => dispatch(actions.filterByOrderForms(startDate, endDate, userId, issuerId)),
            clearOrderFormError: () => dispatch(actions.clearOrderFormsError())
        };
    };

export default connect(mapStateToProps, mapDispatchToProps)(OrderForms);
