import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    data: null,
    loading: false,
    error: null,
    agreement: ''
};

const start = (state, action) => {
    return updateObject(state, {
        data: null,
        loading: true,
        error: null
    });
};

const success = (state, action) => {
    return updateObject(state, {
        data: action.data,
        loading: false,
        error: null
    });
};

const fail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};
const getPaymentLinkStatusStart = (state, action) => {
    return updateObject(state, {
        data: null,
        loading: true,
        error: null
    });
};

const getPaymentLinkStatusSuccess = (state, action) => {
    return updateObject(state, {
        agreement: action.data,
        loading: false,
        error: null
    });
};

const getPaymentLinkStatusFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANY_START:
            return start(state, action);
        case actionTypes.GET_COMPANY_SUCCESS:
            return success(state, action);
        case actionTypes.GET_COMPANY_FAIL:
            return fail(state, action);
        case actionTypes.GET_PAYMENT_LINK_STATUS_START:
            return getPaymentLinkStatusStart(state, action);
        case actionTypes.GET_PAYMENT_LINK_STATUS_SUCCESS:
            return getPaymentLinkStatusSuccess(state, action);
        case actionTypes.GET_PAYMENT_LINK_STATUS_FAIL:
            return getPaymentLinkStatusFail(state, action);
        case actionTypes.CREATE_COMPANY_START:
            return start(state, action);
        case actionTypes.CREATE_COMPANY_SUCCESS:
            return success(state, action);
        case actionTypes.CREATE_COMPANY_FAIL:
            return fail(state, action);
        default:
            return state;
    }
};

export default reducer;