import React from 'react';
import classes from './Header.module.css';

const UserBadge = (props) => {
    if (!props.data) {
        return null;
    }
    return (
        <div className={classes.badge} onClick={props.openOptions}>
            {props.data.firstName + " " + props.data.lastName}
        </div>
    )
};

export default UserBadge;