import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import '../../styles/components/Card.scss';

const Card = props => {
    const [showAll, setShowAll] = useState(false);

    if (!props.data) {
        return null;
    }

    function showCards() {
        let cards;
        if (showAll === false && props.userShowAll === false) {
            cards = props.data.slice(0, 6).map(item =>
                <Link
                    className="card"
                    key={item.id}
                    to={props.url + item.id}
                >
                    {props.format(item)}
                </Link>
            );

            return cards;
        }

        cards = props.data.map(item =>
            <Link
                className="card"
                key={item.id}
                to={props.url + item.id}
            >
                {props.format(item)}
            </Link>
        );

        return cards;
    }

    return (
        <div className="cards">
            {
                props.userShowAll === undefined ||
                props.hideShowAllButton ?
                null :
                <div className="collapse-btn-container">
                    <button
                        type="button"
                        onClick={() => setShowAll(!showAll)}
                        className="button"
                    >
                        {showAll ? 'Hide' : 'Show All'}
                    </button>
                </div>
            }
            {showCards()}
        </div>
    );
};

export default Card;
