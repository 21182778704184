import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
    hidden: false
};

const toggleSideMenu = (state, action) => {
    return updateObject(state, {
        hidden: !state.hidden
    });
};

const reducer = (state=initialState, action) => {
    if (action.type === actionTypes.TOGGLE_SIDE_MENU) {
        return toggleSideMenu(state, action);
    }
    return state;
};

export default reducer;