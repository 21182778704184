import React, {Component} from 'react';

class Asset extends Component {

    render() {
        return (
            <React.Fragment>Asset</React.Fragment>
        )
    }

}

export default Asset;