import React from 'react';
import {connect} from 'react-redux';
import { getProductGroups } from '../../store/actions/productGroups';
import PopupForm from '../PopupForm/PopupForm';
import productGroupFields from "../../forms/productGroup";
import {createProductGroup, clearProductGroupError} from "../../store/actions/productGroup";
import {formatProductGroupCard} from "../Card/FormatCard";
import Card from '../Card/Card';
import TableView from '../TableView/TableView';
import DuplicateNotification from "../Duplicate/DuplicateNotification";
import '../../styles/components/Buttons.scss';

class ProductGroups extends React.Component {
    state = {
        showPopup: false
    };

    componentDidMount() {
        if (!this.props.loading && !this.props.groups) {
            this.props.getProductGroups();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.productGroupCreated && this.props.productGroupCreated) {
            this.props.history.push('/product-group/' + this.props.productGroup.id);
        }
    }

    formatData(data) {
        if (data.links !== null) {
            data.links = {thumbnail: data.links}
        }

        this.props.createProductGroup(data);
        this.setState({showPopup: false});
    }

    render() {
        let groups;
        if (this.props.groups) {
            groups = this.props.groups
                .sort((a, b) => a.order - b.order)
                .map(group => {
                    return Object.assign({}, group, {
                        name: group.descriptiveName,
                        visible: group.visible.toString()
                });
            });
        }

        return (
            <React.Fragment>
                {
                    this.state.showPopup ?
                        <PopupForm
                            fields={productGroupFields}
                            onSubmit={(data) => this.formatData(data)}
                            close={() => this.setState({showPopup: false})}/> :
                        null
                }
                <button
                    type="button"
                    onClick={() => this.setState({showPopup: true})}
                    className="button productGroupButton"
                >
                    Add New Product Group
                </button>
                {
                    this.props.view === 'card' ?
                    <Card
                        data={groups}
                        format={groups => formatProductGroupCard(groups)}
                        url={'/product-group/'}
                    /> :
                    <TableView
                        data={groups}
                        headers={{
                            descriptiveName: 'Group Name',
                            order: 'Order',
                            visible: 'Visibility'
                        }}
                        keys={['descriptiveName', 'order', 'visible']}
                        rowClick={id => this.props.history.push('/product-group/' + id)}
                    />
                }
                <DuplicateNotification
                    statusCode={this.props.createProductGroupError}
                    clearError={this.props.clearProductGroupError}
                    message={'That product group already exists..'}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    groups: state.productGroups.productGroups,
    loading: state.productGroups.loading,
    view: state.view.view,
    createProductGroupError: state.productGroup.error,
    productGroupCreated: state.productGroup.productGroup && !state.productGroup.error,
    productGroup: state.productGroup.productGroup
});

const mapDispatchToProps = dispatch => ({
    getProductGroups: () => dispatch(getProductGroups()),
    createProductGroup: data => dispatch(createProductGroup(data)),
    clearProductGroupError: () => dispatch(clearProductGroupError())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroups);