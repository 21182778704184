import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getBundlesStart = () => {
    return {
        type: actionTypes.GET_BUNDLES_START
    }
};

export const getBundlesSuccess = (data) => {
    return {
        type: actionTypes.GET_BUNDLES_SUCCESS,
        data: data.content,
        pages: data.totalPages,
        currentPage: data.pageable.pageNumber
    }
};

export const getBundlesFail = (error) => {
    return {
        type: actionTypes.GET_BUNDLES_FAIL,
        error: error
    }
};

export const getBundlesForUser = (userId) => {
    return dispatch => {
        dispatch(getBundlesStart());
        getData(
            "/bundle/user/" + userId,
            response => {
                dispatch(getBundlesSuccess(response.data))
            },
            error => {
                dispatch(getBundlesFail(error.response.status))
            },
            dispatch
        );
    };
};

export const getBundlesForCompany = (companyId) => {
    return dispatch => {
        dispatch(getBundlesStart());
        getData(
            "/bundle/company/" + companyId,
            response => {
                dispatch(getBundlesSuccess(response.data))
            },
            error => {
                dispatch(getBundlesFail(error.response.status))
            },
            dispatch
        );
    };
};


export const getBundles = (page, sort, dir) => {
    let subUrl = "/bundle?page=" + page;

    return dispatch => {
        dispatch(getBundlesStart());
        getData(
            !sort && !dir ? subUrl : subUrl += `&sort=${sort}&dir=${dir}`,
            response => {
                dispatch(getBundlesSuccess(response.data))
            },
            error => {
                dispatch(getBundlesFail(error.response.status))
            },
            dispatch
        );
    };
};
