import React from 'react';
import {connect} from 'react-redux';
import Card from '../Card/Card';
import TableView from '../TableView/TableView';
import Search from './Search';
import {formatUserCard, formatProductCard, formatProductGroupCard} from "../Card/FormatCard";
import { ClipLoader } from 'react-spinners';
import {FaThumbsDown} from 'react-icons/fa';
import Price from "../Price/Price";

const SearchResults = props => {
    let didFindUsers, didFindProducts, didFindProductGroups;
    let listProductSearchResults, listProductGroupSearchResults;
    let searchFailMessage;

    if (props.userSearchResults) {
        didFindUsers = props.userSearchResults.length;
    }

    if (props.productSearchResults) {
        didFindProducts = props.productSearchResults.length;
        listProductSearchResults = props.productSearchResults.map(product => {
            return Object.assign({}, product, {
                price: <Price price={product.price}/>,
                funnelPrice: <Price price={product.funnelPrice}/>
            })
        });
    }

    if (props.productGroupSearchResults) {
        didFindProductGroups = props.productGroupSearchResults.length;
        listProductGroupSearchResults = props.productGroupSearchResults.map(group => {
            return Object.assign({}, group, {
                visible: group.visible.toString()
            })
        });
    }

    if (!didFindUsers && !didFindProducts && !didFindProductGroups) {
        searchFailMessage = (
            <React.Fragment>
                <FaThumbsDown size={75}/><br/>
                <h3>Search not successful, please try a new search..</h3>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Search history={props.history}/>
            {
                props.loading ?
                <ClipLoader sizeUnit="px" size={100} color="black"/> :
                null
            }
            {
                didFindUsers && props.view === 'card' ?
                <React.Fragment>
                    <h1>Users</h1>
                    <Card
                        data={props.userSearchResults}
                        format={results => formatUserCard(results)}
                        url={'/user/'}
                    />
                </React.Fragment> :
                didFindUsers && props.view === 'list' ?
                <React.Fragment>
                    <h1>Users</h1>
                    <TableView
                        data={props.userSearchResults}
                        headers={{
                            firstName: 'First Name',
                            lastName: 'Last Name',
                            status: 'Status',
                            role: 'Role',
                            phone: 'Phone',
                            email: 'E-mail'
                        }}
                        keys={['firstName', 'lastName', 'status', 'role', 'phone', 'email']}
                        rowClick={id => props.history.push('/user/' + id)}
                    />
                </React.Fragment> :
                null
            }
            {
                didFindProducts && props.view === 'card' ?
                <React.Fragment>
                    <h1>Products</h1>
                    <Card
                        data={props.productSearchResults}
                        format={results => formatProductCard(results)}
                        url={'/product/'}
                    />
                </React.Fragment> :
                didFindProducts && props.view === 'list' ?
                <React.Fragment>
                    <h1>Products</h1>
                    <TableView
                        data={listProductSearchResults}
                        headers={{
                            descriptiveName: 'Product Name',
                            interval: 'Interval',
                            price: 'Price',
                            funnelPrice: 'Funnel Price',
                            description: 'Description'
                        }}
                        keys={['descriptiveName', 'interval', 'price', 'funnelPrice', 'description']}
                        rowClick={id => props.history.push('/product/' + id)}
                    />
                </React.Fragment> :
                null
            }
            {
                didFindProductGroups && props.view === 'card' ?
                <React.Fragment>
                    <h1>Product Groups</h1>
                    <Card
                        data={props.productGroupSearchResults}
                        format={results => formatProductGroupCard(results)}
                        url={'/product-group/'}
                    />
                </React.Fragment> :
                didFindProductGroups && props.view === 'list' ?
                <React.Fragment>
                    <h1>Product Groups</h1>
                    <TableView
                        data={listProductGroupSearchResults}
                        headers={{
                            descriptiveName: 'Group Name',
                            order: 'Order',
                            visible: 'Visibility'
                        }}
                        keys={['descriptiveName', 'order', 'visible']}
                        rowClick={id => props.history.push('/product-group/' + id)}
                    />
                </React.Fragment> :
                null
            }
            {searchFailMessage}
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    userSearchResults: state.search.userSearchResults,
    productSearchResults: state.search.productSearchResults,
    productGroupSearchResults: state.search.productGroupSearchResults,
    loading: state.search.loading,
    view: state.view.view
});

export default connect(mapStateToProps)(SearchResults);