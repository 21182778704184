import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getTasksStart = () => {
    return {
        type: actionTypes.GET_TASKS_START
    }
};

export const getTasksSuccess = (data) => {
    return {
        type: actionTypes.GET_TASKS_SUCCESS,
        data: data,
    }
};

export const getTasksFail = (error) => {
    return {
        type: actionTypes.GET_TASKS_FAIL,
        error: error
    }
};


export const getTasksByStatusStart = () => {
    return {
        type: actionTypes.GET_TASKS_BY_STATUS_START
    }
};

export const getTasksByStatusSuccess = (data) => {
    return {
        type: actionTypes.GET_TASKS_BY_STATUS_SUCCESS,
        data: data,
    }
};

export const getTasksByStatusFail = (error) => {
    return {
        type: actionTypes.GET_TASKS_BY_STATUS_FAIL,
        error: error
    }
};
export const getTasksByStatus = (status, sort, dir) => {
    let subUrl = `/tasks/filter-by?`;

    if (status) {
        subUrl += `status=${status}&`;
    }
    if (sort) {
        subUrl += `sort=${sort}&`;
    }
    if (dir) {
        subUrl += `dir=${dir}&`;
    }

    return dispatch => {
        dispatch(getTasksByStatusStart());
        getData(
            subUrl,
            response => {
                dispatch(getTasksByStatusSuccess(response.data))
            },
            error => {
                dispatch(getTasksByStatusFail(error.response.status))
            },
            dispatch
        );
    };

};

export const getTasks = (sort, dir, status) => {
    let subUrl = `/tasks/?`;

    if (status) {
        subUrl += `status=${status}&`;
    }
    if (sort) {
        subUrl += `sort=${sort}&`;
    }
    if (dir) {
        subUrl += `dir=${dir}&`;
    }



    return dispatch => {
        dispatch(getTasksStart());
        getData(
            subUrl,
            response => {
                dispatch(getTasksSuccess(response.data))
            },
            error => {
                dispatch(getTasksFail(error.response.status))
            },
            dispatch
        );
    };
};