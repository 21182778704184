import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from "../../store/actions";
import {ClipLoader} from 'react-spinners';
import Price from "../Price/Price";
import KeyValueBlock from "../KeyValueBlock/KeyValueBlock";
import classes from './OrderForms.module.css'

const OrderForm = props => {
    const {getOrderForm, match} = props;

    useEffect(() => {
        if (match.params.id) {
            getOrderForm(match.params.id);
        }
    }, [getOrderForm, match]);

    const orderLink = "https://shop.thewolfnetwork.com/order-form/" + props.match.params.id;

    if (props.orderForm) {
        let translations = {
            productTag: "Product name",
            price: "Price",
            state: "State",
            issuerName: "Issuer Name",
                issuerEmail: "Issuer Email",
                userName: "User Name",
                userEmail: "User Email",
            link: "Link"
        };

        const data = Object.assign({}, props.orderForm, {
            "link": <a href={orderLink} target="_blank" rel="noopener noreferrer" className={classes.link}>{orderLink}</a>,
            "price": <Price price={props.orderForm.price}/>
        });

        return (
            <React.Fragment>
                { props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/>
                    : null
                }
                <KeyValueBlock
                    data={data}
                    keys={["productTag", "price", "state", "issuerName", "issuerEmail", "userName", "userEmail", "link"]}
                    translations={translations}
                />
            </React.Fragment>
            );
        } else {
            return null;
        }
    };


const mapStateToProps = state => ({
    orderForm: state.orderForm.data,
    loading: state.orderForm.loading
});

const mapDispatchToProps = dispatch => ({
    getOrderForm: (formId) => dispatch(actions.getOrderForm(formId))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);