import * as actionTypes from "./actionTypes";
import {getData} from "../../shared/utility";

export const getOrderFormsStart = () => {
    return {
        type: actionTypes.GET_ORDER_FORMS_START
    }
};

export const getOrderFormsSuccess = (data) => {
    return {
        type: actionTypes.GET_ORDER_FORMS_SUCCESS,
        data: data.content,
        pages: data.totalPages,
        currentPage: data.pageable.pageNumber
    }
};

export const getOrderFormsFail = (error) => {
    return {
        type: actionTypes.GET_ORDER_FORMS_FAIL,
        error: error
    }
};

export const getOrderFormsForUser = (userId) => {
    return dispatch => {
        dispatch(getOrderFormsStart());
        getData(
            "/order-forms/user/" + userId,
            response => {
                dispatch(getOrderFormsSuccess(response.data))
            },
            error => {
                dispatch(getOrderFormsFail(error.response.status))
            },
            dispatch);
    };
};

export const getOrderForms = (page, sort, dir) => {
    let subUrl = "/order-forms?page=" + page;

    return dispatch => {
        dispatch(getOrderFormsStart());
        getData(
            !sort && !dir ? subUrl : subUrl += `&sort=${sort}&dir=${dir}`,
            response => {
                dispatch(getOrderFormsSuccess(response.data))
            },
            error => {
                dispatch(getOrderFormsFail(error.response.status))
            },
            dispatch);
    };
};

export const getOrderFormsIssuedStart = () => {
    return {
        type: actionTypes.GET_ORDER_FORMS_ISSUED_START
    }
};

export const getOrderFormsIssuedSuccess = orderFormsIssued => {
    return {
        type: actionTypes.GET_ORDER_FORMS_ISSUED_SUCCESS,
        orderFormsIssued
    }
};

export const getOrderFormsIssuedError = error => {
    return {
        type: actionTypes.GET_ORDER_FORMS_ISSUED_ERROR,
        error
    }
};

export const getOrderFormsIssued = id => dispatch => {
    dispatch(getOrderFormsIssuedStart());
    getData(
        '/order-forms/issued-by/' + id,
        response => {
            console.log(response);
            dispatch(getOrderFormsIssuedSuccess(response.data));
        },
        error => {
            console.log(error);
            dispatch(getOrderFormsIssuedError(error));
        }
    )
};

export const filterByOrderFormsStart = () => ({
    type: actionTypes.FILTER_BY_ORDER_FORMS_START
});

export const filterByOrderFormsSuccess = filteredData => ({
    type: actionTypes.FILTER_BY_ORDER_FORMS_SUCCESS,
    data: filteredData.content,
    pages: filteredData.totalPages,
    currentPage: filteredData.pageable.pageNumber
});

export const filterByOrderFormsError = error => ({
    type: actionTypes.FILTER_BY_ORDER_FORMS_ERROR,
    error: error
});

export const filterByOrderForms = (startDate, endDate, userId, issuerId) => dispatch => {
    let subUrl = `/order-forms/filter-by?`;

    if (startDate) {
        subUrl += `startDate=${startDate}&`;
    }

    if (endDate) {
        subUrl += `endDate=${endDate}&`;
    }

    if (userId) {
        subUrl += `userId=${userId}&`;
    }

    if (issuerId) {
        subUrl += `issuerId=${issuerId}&`;
    }

    dispatch(filterByOrderFormsStart());
    getData(
        subUrl,
        response => {
            console.log('order form filter success', response);
            dispatch(filterByOrderFormsSuccess(response.data));
        },
        error => {
            console.log('order form filter error', error.response.data);
            dispatch(filterByOrderFormsError(error.response.data.status));
        }
    )
};

export const clearOrderFormsError = () => ({
    type: actionTypes.CLEAR_ORDER_FORMS_ERROR
});