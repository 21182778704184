import React, {Component} from 'react';
import classes from './PopupForm.module.css';
import * as validators from '../../shared/validators';
import PropTypes from "prop-types";

class PopupForm extends Component {

    state = {
        data: {},
        validationErrors: {},
    };

    componentDidMount() {
        if (this.props.prefill) {
            const newValues = {};
            this.props.fields.forEach(field => newValues[field.key] = this.props.prefill[field.key]);
            this.setState({
                data: newValues
            })
        }
    }

    disableInputField(field) {
        if (field.disabled) {
            // console.log(this.state.data);
            return field.disabled(this.state.data);
        }
        return false;
    }

    mapField(field) {
        // console.log('field', field);
        // console.log('data', this.state.data);
        let validationError = this.state.validationErrors[field.key];
        let input;
        let options;

        if (field.type === "select") {
            let optionsInput = field.options;
            if (optionsInput instanceof Function) {
                optionsInput = optionsInput(this.state.data);
            }

            options = Object.keys(optionsInput).map(option => {
                // console.log('id', option);
                // console.log('object', optionsInput);
                // console.log(optionsInput[option]);
                return <option key={option} value={option}>{optionsInput[option]}</option>;
            });

            input = <select
                id={this.disableInputField(field) ? classes.grayedOut : null}
                disabled={this.disableInputField(field)}
                className={validationError ? classes.input + " " + classes.errorInput : classes.input}
                value={this.state.data[field.key] || ""}
                onChange={(event) => {
                    // console.log(this.state.data);
                    // console.log(field.key);
                    // console.log('event value', event.target.value);
                    this.setState({data: {...this.state.data, [field.key]: event.target.value}});
                }}
            >
                <option value="">---</option>
                {options}
            </select>
        } else if (field.type === "paragraph") {
            input = <textarea
                id={this.disableInputField(field) ? classes.grayedOut : null}
                disabled={this.disableInputField(field)}
                className={validationError ? `${classes.input} ${classes.textarea} ${classes.errorInput}`: `${classes.input} ${classes.textarea}`}
                value={this.state.data[field.key] || ""}
                onChange={event => {
                    // console.log('textarea event value', event.target.value);
                    this.setState({data: {...this.state.data, [field.key]: event.target.value}});
                }}
                placeholder={field.placeholder}
            >
                {this.state.data[field.key]}
            </textarea>
        } else if (field.type === "paste") {
            input =
                <div>
                    <p className={classes.pasteInstructions}>Please follow format: <span
                        className={classes.pasteExample}>email,firstName,lastName </span>
                    </p>
                    <textarea
                        placeholder={`email_one@example.com,firstname,lastname \b email_two@example.com,firstname,lastname \b "email_three@example.com,firstname,lastname`}
                        onChange={(string) => this.props.csvChange(string)}
                        className={classes.paste}/>
                </div>
        } else if (field.type === "file") {
            input = <div className={classes.csvUpload}>
                <div className={classes.templateLink}>
                    <a href={"https://docs.google.com/spreadsheets/u/3/d/1RV6RxHTZ9NDoXMX6YGSvMaMgIJfgHxx_bi9og0e8N_Y/export?format=csv&id=1RV6RxHTZ9NDoXMX6YGSvMaMgIJfgHxx_bi9og0e8N_Y&gid=1172767155"}
                    >Download a template</a>
                </div>
                <button className={classes.chooseFile}>
                    <label htmlFor="csv-file" className={classes.uploadButton}>Choose
                        Csv File </label>
                </button>

                <input onChange={(string) => this.props.csvChange(string)}
                       type="file" id="csv-file" name="files"/>
                {this.props.fileChosen ?
                    <div className={classes.fileChosen}> file selected: {this.props.fileChosen} </div> : null}

            </div>

        } else {
            input = <input
                id={this.disableInputField(field) ? classes.grayedOut : null}
                disabled={this.disableInputField(field)}
                key={"input-" + field.key}
                className={validationError ? classes.input + " " + classes.errorInput : classes.input}
                type={field.type}
                placeholder={field.placeholder}
                value={this.state.data[field.key] || ""}
                onChange={(event) => this.setState({data: {...this.state.data, [field.key]: event.target.value}})}
            />;
        }

        return <React.Fragment key={field.key}>
            {input}
            {validationError ?
                <div key={"error-" + field.error}
                     className={classes.error}>{validationError}</div>
                : null
            }
        </React.Fragment>
    }

    validate() {
        const errors = {};
        let hasErrors = false;
        this.props.fields.forEach(field => {
            if (field.validation) {
                field.validation.forEach(validation => {
                    const result = PopupForm.handleValidation(validation, this.state.data[field.key], this.state.data);
                    if (result) {
                        errors[field.key] = result;
                        hasErrors = true
                    }
                })
            }
        });
        this.setState({
            validationErrors: errors
        });
        return hasErrors;
    }

    static handleValidation(validation, value, data) {
        const success = validators.validators[validation.type](value, validation.criteria, data);
        if (!success) {
            return validators.messages[validation.type](validation.criteria);
        }
    }

    submit() {
        console.log("submitting");
        const hasError = this.validate();
        console.log("hasError", hasError);
        if (hasError) {
            return;
        }

        this.props.onSubmit(this.state.data);
    }

    render() {
        let fields = this.props.fields.map(field => this.mapField(field));

        return (
            <React.Fragment>
                <div className={classes.backdrop} onClick={this.props.close}/>
                <div className={classes.form}>
                    {
                        this.props.fields[0].type === "paste" ?
                        <button className={classes.button} onClick={this.props.view}>Upload A List</button>
                        :
                        <button className={classes.button} onClick={this.props.view}>Paste A List</button>
                    }
                    {this.props.formError ? <div className={classes.formError}>{this.props.formError}</div> : null}
                    {fields}
                    <div className={classes.buttonRow}>
                        <button className={classes.button} onClick={() =>this.submit()}>Submit</button>
                        <button className={classes.button} onClick={this.props.close}>Cancel</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default PopupForm;

PopupForm.propTypes = {
  close: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  prefill: PropTypes.object,
  formError: PropTypes.string
};
