import * as actionTypes from "./actionTypes";
import {getData, postData} from "../../shared/utility";

export const getTransactionStart = () => {
    return {
        type: actionTypes.GET_TRANSACTION_START
    }
};

export const getTransactionSuccess = (data) => {
    return {
        type: actionTypes.GET_TRANSACTION_SUCCESS,
        data: data
    }
};

export const getTransactionFail = (error) => {
    return {
        type: actionTypes.GET_TRANSACTION_FAIL,
        error: error
    }
};

export const cancelTransactionStart = () => {
    return {
        type: actionTypes.CANCEL_TRANSACTION_START
    }
};
export const cancelTransactionSuccess = () => {
    return {
        type: actionTypes.CANCEL_TRANSACTION_SUCCESS,
    }
};
export const cancelTransactionFail = (error) => {
    return {
        type: actionTypes.CANCEL_TRANSACTION_FAIL,
        error: error
    }
};

export const cancelSubscriptionStart = () => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION_START
    }
};
export const cancelSubscriptionSuccess = () => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
    }
};
export const cancelSubscriptionFail = (error) => {
    return {
        type: actionTypes.CANCEL_SUBSCRIPTION_FAIL,
        error: error
    }
};

export const refundTransactionStart = () => {
    return {
        type: actionTypes.REFUND_TRANSACTION_START
    }
};

export const refundTransactionSuccess = () => {
    return {
        type: actionTypes.REFUND_TRANSACTION_SUCCESS
    }
};

export const refundTransactionFail = (error) => {
    return {
        type: actionTypes.REFUND_TRANSACTION_FAIL,
        error
    }
};

export const cancelAndRefundTransactionStart = () => {
    return {
        type: actionTypes.CANCEL_AND_REFUND_TRANSACTION_START
    }
};

export const cancelAndRefundTransactionSuccess = () => {
    return {
        type: actionTypes.CANCEL_AND_REFUND_TRANSACTION_SUCCESS
    }
};

export const cancelAndRefundTransactionFail = () => {
    return {
        type: actionTypes.CANCEL_AND_REFUND_TRANSACTION_FAIL
    }
};

export const getTransactionById = id => dispatch => {
    dispatch(getTransactionStart());
    return getData(
        `/transactions/${id}`,
        response => {
            dispatch(getTransactionSuccess(response.data));
        },
        response => {
            dispatch(getTransactionFail(response.error));
        },
        dispatch
    );
};

export const cancelTransaction = id => {
    return dispatch => {
        dispatch(cancelTransactionStart());
        postData(
            "/transactions/" + id + "/cancel",
            null,
            response => {
                dispatch(cancelTransactionSuccess(response.data))
            },
            error => {
                dispatch(cancelTransactionFail(error.response))
            },
            dispatch
        );
    };
};

export const cancelSubscription = subscriptionId => dispatch => {
    dispatch(cancelSubscriptionStart());
    postData(
        "/transactions/subscription/" + subscriptionId + "/cancel",
        null,
        response => {
            console.log(response);
            dispatch(cancelSubscriptionSuccess(response.data))
        },
        error => {
            console.log(error);
            dispatch(cancelSubscriptionFail(error))
        }
    )
};

export const refundTransaction = transactionId => dispatch => {
    dispatch(refundTransactionStart());
    postData(
        "/transactions/refund/" + transactionId,
        null,
        response => {
            console.log(response);
            dispatch(refundTransactionSuccess())
        },
        error => {
            console.log(error);
            dispatch(refundTransactionFail(error))
        }
    )
};

export const cancelAndRefundTransaction = (subscriptionId, id) => dispatch => {
    dispatch(cancelAndRefundTransactionStart());
    postData(
        "/transactions/subscription/" + subscriptionId + "/cancel",
        null,
        response => {
            console.log(response);
            postData("/transactions/refund/" + id, null, null, dispatch(refundTransactionFail(response)));
            dispatch(cancelAndRefundTransactionSuccess());
        },
        error => {
            console.log(error);
            dispatch(cancelAndRefundTransactionFail(error));
        }
    )
};