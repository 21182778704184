import {types} from "../shared/validators";

const formFields = (taskPriorityOptions, taskStatusOptions) => [
    {
        key: "name",
        type: "text",
        placeholder: "Task Name",
        validation: [
            {
                type: types.REQUIRED
            },
            {
                type: types.MIN_LENGTH,
                criteria: 3
            }
        ]
    },
    {
        key: "description",
        type: "text",
        placeholder: "Detailed Description",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "onBehalfOf",
        type: "text",
        placeholder: "Employee Requester"
    },
    {
        key: "priority",
        type: "select",
        options: taskPriorityOptions,
        placeholder: "Priority",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "status",
        type: "select",
        options: taskStatusOptions,
        placeholder: "Status"
    },
    {
        key: "deadline",
        type: "date",
        placeholder: "Deadline Date"
    },
    {
        key: "issuingUser",
        type: "text",
        placeholder: "IssuingUser"
    },
    {
        key: "completedOn",
        type: "date",
        placeholder: "Completed On"
    },
];

export default formFields;