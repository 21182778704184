import React from 'react';
import {FaExclamation} from 'react-icons/fa';
import '../../styles/components/Product.scss'

const DuplicateNotification = props => {
    if (props.statusCode === 500 || props.statusCode === 409) {
        return (
            <div className="backdrop">
                <div className="form">
                    <FaExclamation size={90}/>
                    <h3>{props.message}</h3>
                    <button
                        type="button"
                        className="button"
                        onClick={() => props.clearError()}
                    >
                        Okay
                    </button>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default DuplicateNotification;