import React from 'react';
import classes from './Users.module.css';

const Filters = props => {
    const keys = Object.keys(props.data);

    let key = '';
    key = keys.map((title, index) =>
        <div key={index}>
            <button
                type="button"
                className={classes.exitFilter}
                onClick={() => props.onFilterClick()}
            >
                <span className={classes.exitIcon}>X</span>
            </button>
            <p className={classes.filterItem}>
                {title} : {props.data[title]}
            </p>
        </div>
    );

    return (
        <React.Fragment>
            <div className="all-filters">
                {key}
            </div>
        </React.Fragment>
    );
};

export default Filters;