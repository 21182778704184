import React from 'react';
import {connect} from 'react-redux';
import {ClipLoader} from "react-spinners";

const View = props => {
    let loadingMessage;
    if (props.cancelling) {
        loadingMessage = 'Cancelling . . .';
    }

    if (props.refunding) {
        loadingMessage = 'Refunding . . .';
    }

    if (props.cancelling && props.refunding) {
        loadingMessage = 'Cancelling & Refunding . . . ';
    }

    const loadingView = (
        <div className="backdrop">
            <div className="notification-container">
                <h1>{loadingMessage}</h1>
                <ClipLoader sizeUnit="px" size={100} color="black"/>
            </div>
        </div>
    );

    let finalMessage;
    if (props.canceled) {
        finalMessage = 'You have successfully cancelled the subscription.'
    }

    if (props.refunded) {
        finalMessage = 'You have successfully refunded the subscription.'
    }

    if (props.canceled && props.refunded) {
        finalMessage = 'You have successfully cancelled & refunded the subscription.'
    }

    const finalView = (
        <div className="backdrop">
            <div className="notification-container">
                <h1>{finalMessage}</h1>
                <button
                    type="button"
                    className="button"
                    onClick={() => props.history.go()}
                >
                    Okay
                </button>
            </div>
        </div>
    );

    if (props.cancelling || props.refunding || (props.cancelling && props.refunding)) {
        return loadingView;
    }

    if (props.canceled || props.refunded || (props.canceled && props.refunded)) {
        return finalView;
    } else {
        return null;
    }
};

const mapStateToProps = state => ({
    cancelling: state.transaction.cancelling,
    canceled: state.transaction.canceled,
    refunding: state.transaction.refunding,
    refunded: state.transaction.refunded
});

export default connect(mapStateToProps)(View);

