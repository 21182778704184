import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
    userSearchResults: null,
    productSearchResults: null,
    productGroupSearchResults: null,
    loading: false,
    error: null
};

const searchStart = (state, action) => {
    return updateObject(state, {
        loading: true,
    });
};

const searchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        userSearchResults: action.userSearchResults,
        productSearchResults: action.productSearchResults,
        productGroupSearchResults: action.productGroupSearchResults
    });
};

const searchError = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const reducer = (state=initialState, action) => {
    switch(action.type) {
        case actionTypes.SEARCH_START : return searchStart(state, action);
        case actionTypes.SEARCH_SUCCESS : return searchSuccess(state, action);
        case actionTypes.SEARCH_ERROR : return searchError(state, action);
        default: return state;
    }
};

export default reducer;