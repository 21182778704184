import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import KeyValueBlock from "../KeyValueBlock/KeyValueBlock";
import OrderForms from "../OrderForms/OrderForms";
import Transactions from "../Transactions/Transactions";
import PopupForm from "../PopupForm/PopupForm";
import roleFields from "../../forms/role";
import passwordFields from "../../forms/passwordOverwrite";
import permissions from "../../shared/permissions";
import userFields from "../../forms/updateUser";
import {ClipLoader} from 'react-spinners';
import {getOrderFormsIssued} from "../../store/actions/orderForms";
import Card from '../Card/Card';
import Price from '../Price/Price';
import '../../styles/components/User.scss';
import TableView from '../TableView/TableView';

class User extends Component {

    state = {
        roleFormOpen: false,
        passwordFormOpen: false,
        editFormOpen: false
    };

    componentDidMount() {
        this.props.invalidateUsers();
        if (this.props.match.params.id) {
            this.props.getUser(this.props.match.params.id);
            this.props.getOrderFormsIssued(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.roleChanged && this.props.roleChanged) {
            this.closeRoleForm();
        }
        if (!prevProps.passwordChanged && this.props.passwordChanged) {
            this.closePasswordForm();
        }
        if (!prevProps.orderFormCreated && this.props.orderFormCreated) {
            this.props.history.push('/order-form/' + this.props.orderForm.id);
        }
    }

    openRoleForm() {
        this.setState({
            roleFormOpen: true
        });
    }

    closeRoleForm() {
        this.setState({
            roleFormOpen: false
        });
    }

    openEditForm() {
        this.setState({
            editFormOpen: true
        });
    }

    closeEditForm() {
        this.setState({
            editFormOpen: false
        });
    }

    openPasswordForm() {
        this.setState({
            passwordFormOpen: true
        });
    }

    closePasswordForm() {
        this.setState({
            passwordFormOpen: false
        });
    }

    changePassword(data) {
        this.props.changePassword(this.props.match.params.id, {newPassword: data.newPassword})
    }

    formatIssued(issued) {
        return (
            <React.Fragment>
                <div className="card-title">
                    <h3 className="user-name">{issued.userName}</h3>
                    <h3 className="user-status">{issued.state}</h3>
                </div>
                <p className="user-role">{issued.productTag}</p>
                <Price price={issued.price}/>
            </React.Fragment>
        );
    }

    render() {
        if (!this.props.data || !this.props.me) {
            return null;
        }

        let orderFormsIssuedLength;
        if (this.props.orderFormsIssued) {
            orderFormsIssuedLength = this.props.orderFormsIssued.length;
        }

        let orderFormsLength;
        let transactionsLength;
        if (this.props.orderForms && this.props.transactions) {
            orderFormsLength = this.props.orderForms.length;
            transactionsLength = this.props.transactions.length;
        }

        let translations = {
            "firstName": "First Name",
            "lastName": "Last Name",
            "email": "Email Address",
            "username": "Username",
            "phone": "Phone Number",
            "hubspotUserId": "Hubspot User Id"
        };
        let actions = [
            {
                name: "Change Role",
                action: () => this.openRoleForm(),
                permissions: permissions.user.changeRole
            },
            {
                name: "Change Password",
                action: () => this.openPasswordForm(),
                permissions: permissions.user.changePassword
            }
        ].filter(action => action.permissions.includes(this.props.me.role));

        let issuedData;
        if (this.props.orderFormsIssued) {
            issuedData = this.props.orderFormsIssued.map(orderForm => {
                return Object.assign({}, orderForm, {
                    price: <Price price={orderForm.price}/>
                })
            });
        }

        return (
            <React.Fragment>
                {this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/>
                    : null
                }
                {this.state.roleFormOpen ?
                    <PopupForm close={() => this.closeRoleForm()} fields={roleFields}
                               onSubmit={(data) => this.props.changeRole(this.props.match.params.id, data)}/>
                    : null
                }
                {this.state.passwordFormOpen ?
                    <PopupForm close={() => this.closePasswordForm()} fields={passwordFields}
                               onSubmit={(data) => this.changePassword(data)}/>
                    : null
                }
                {this.state.editFormOpen ?
                    <PopupForm
                        fields={userFields}
                        prefill={this.props.data}
                        onSubmit={(data) => this.props.updateUser(this.props.match.params.id, data)}
                        close={() => this.closeEditForm()}
                    /> : null
                }
                <KeyValueBlock
                    data={this.props.data}
                    keys={["email", "username", "firstName", "lastName", "phone", "hubspotUserId"]}
                    translations={translations}
                    editClick={() => this.openEditForm()}
                    actions={actions}
                />
                <br/><br/>

                {orderFormsLength < 1 && transactionsLength < 1 ? null : <h1>Order Forms</h1>}
                <OrderForms
                    userId={this.props.match.params.id}
                    userShowAll={false}
                    hideCard={orderFormsLength < 1 && transactionsLength < 1}
                    hideShowAllButton={orderFormsLength < 7 && transactionsLength < 7}
                    history={this.props.history}
                />
                <br/><br/>

                {
                    orderFormsIssuedLength > 1 && this.props.view === 'card' ?
                    <div className="issued-container">
                        <h1 className="issued-header">Issued</h1>
                        <Card
                            data={this.props.orderFormsIssued}
                            format={issued => this.formatIssued(issued)}
                            url={'/order-form/'}
                        />
                        <br/><br/>
                    </div> :
                    <div className="issued-container">
                        <h1 className="issued-header">Issued</h1>
                        <TableView
                            data={issuedData}
                            headers={{
                                userName: 'User Name',
                                state: 'Payment State',
                                productTag: 'Product Tag',
                                price: 'Price'
                            }}
                            keys={['userName', 'state', 'productTag', 'price']}
                            rowClick={id => {
                               // console.log(this.props);
                                this.props.history.push('/order-form/' + id)
                            }
                            }
                        />
                        <br/><br/>
                    </div>
                }

                {orderFormsLength < 1 && transactionsLength < 1 ? null : <h1>Transactions</h1>}
                <Transactions
                    userId={this.props.match.params.id}
                    userShowAll={false}
                    hideCard={orderFormsLength < 1 && transactionsLength < 1}
                    hideShowAllButton={orderFormsLength < 7 && transactionsLength < 7}
                    history={this.props.history}
                />
                <br/><br/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        orderFormCreated: state.orderForm.data && !state.orderForm.error,
        orderForm: state.orderForm.data,
        orderForms: state.orderForms.data,
        transactions: state.transactions.data,
        loading: state.user.loading,
        data: state.user.data,
        error: state.user.error,
        roleChanged: state.user.roleChanged,
        passwordChanged: state.user.passwordChanged,
        me: state.me.data,
        orderFormsIssued: state.orderForms.orderFormsIssued,
        view: state.view.view
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUser: (id) => dispatch(actions.getUser(id)),
        invalidateUsers: () => dispatch(actions.invalidateUsers()),
        changeRole: (userId, data) => dispatch(actions.changeUserRole(userId, data)),
        changePassword: (userId, data) => dispatch(actions.changeUserPassword(userId, data)),
        updateUser: (id, data) => dispatch(actions.updateUser(id, data)),
        getOrderFormsIssued: (id) => dispatch(getOrderFormsIssued(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
