import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
    view: null
};

const getStoredView = (state, action) => {
    return updateObject(state, {
        view: action.view
    })
};

const changeView = (state, action) => {
    return updateObject({
        view: action.view
    })
};

const reducer = (state=initialState, action) => {
  switch(action.type) {
      case actionTypes.GET_STORED_VIEW : return getStoredView(state, action);
      case actionTypes.CHANGE_VIEW : return changeView(state, action);
      default : return state;
  }
};

export default reducer;


