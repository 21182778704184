import * as actionTypes from './actionTypes';
import {storeView, loadView} from "../../shared/localStorage";

export const getStoredView = () => {
    let view = loadView();
    view = view === null ? 'card' : view;

    return {
        type: actionTypes.GET_STORED_VIEW,
        view: view
    }
};

export const changeView = view => {
    storeView(view);
    return {
        type: actionTypes.CHANGE_VIEW,
        view
    };
};
