export const loadView = () => {
    return localStorage.getItem('view');
};

export const storeView = view => {
    try {
        localStorage.setItem('view', view);
    } catch (e) {
        console.error(e);
    }
};
