export const types = {
    REQUIRED: "REQUIRED",
    MAX_LENGTH: "MAX_LENGTH",
    MIN_LENGTH: "MIN_LENGTH",
    EMAIL: "EMAIL",
    MONEY: "MONEY",
    MATCH_FIELD: "MATCH_FIELD"
};

export const validators = {
    [types.REQUIRED]: (value) => value !== null && value !== undefined,
    [types.EMAIL]: (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !value || re.test(String(value).toLowerCase());
    },
    [types.MONEY]: (value) => !value || /^[0-9]+(\.[0-9]{1,2})?$/gm.test(value),
    [types.MIN_LENGTH]: (value, criteria) => !value || value.length >= criteria,
    [types.MAX_LENGTH]: (value, criteria) => !value || value.length <= criteria,
    [types.MATCH_FIELD]: (value, criteria, data) => !value || data[criteria] === value
};

export const messages = {
    [types.REQUIRED]: (value) => "This field is required",
    [types.EMAIL]: (value) => "Must be a valid email address",
    [types.MONEY]: (value) => "Must be a valid money amount",
    [types.MAX_LENGTH]: (value) =>  "Max length is " + value,
    [types.MIN_LENGTH]: (value) =>  "Min length is " + value,
    [types.MATCH_FIELD]: (value) => "Fields do not match"
};