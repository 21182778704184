import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    data: null,
    orderFormsIssued: null,
    loading: false,
    error: null,
    indexedData: null,
    pages: 0,
    currentPage: 0,
};

const start = ( state, action ) => {
    return updateObject( state, {
        loading: true,
        error: null,
        data: null,
        indexedData: null,
        pages: 0,
        currentPage: 0,
    } );
};

const success = ( state, action ) => {
    const indexedData = {};
    action.data.forEach(of => indexedData[of.id] = of);
    return updateObject( state, {
        data: action.data,
        indexedData: indexedData,
        pages: action.pages,
        currentPage: action.currentPage,
        loading: false,
        error: null
    } );
};

const fail = ( state, action ) => {
    return updateObject( state, {
        loading: false,
        error: action.error,
    } );
};

const getOrderFormsIssuedStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
};

const getOrderFormsIssuedSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        orderFormsIssued: action.orderFormsIssued
    })
};

const getOrderFormsIssuedError = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
};

const filterByOrderFormsStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
};

const filterByOrderFormsSuccess = (state, action) => {
    const indexedData = {};
    action.data.forEach(item => indexedData[item.id] = item);

    return updateObject(state, {
        loading: false,
        data: action.data,
        indexedData,
        pages: action.pages,
        currentPage: action.currentPage,
    })
};

const filterByOrderFormsError = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
};

const clearOrderFormsError = (state, action) => {
    return updateObject(state, {
        error: null
    })
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_ORDER_FORMS_START: return start(state, action);
        case actionTypes.GET_ORDER_FORMS_SUCCESS: return success(state, action);
        case actionTypes.GET_ORDER_FORMS_FAIL: return fail(state, action);
        case actionTypes.GET_ORDER_FORMS_ISSUED_START: return getOrderFormsIssuedStart(state, action);
        case actionTypes.GET_ORDER_FORMS_ISSUED_SUCCESS: return getOrderFormsIssuedSuccess(state, action);
        case actionTypes.GET_ORDER_FORMS_ISSUED_ERROR: return getOrderFormsIssuedError(state, action);
        case actionTypes.FILTER_BY_ORDER_FORMS_START: return filterByOrderFormsStart(state, action);
        case actionTypes.FILTER_BY_ORDER_FORMS_SUCCESS: return filterByOrderFormsSuccess(state, action);
        case actionTypes.FILTER_BY_ORDER_FORMS_ERROR: return filterByOrderFormsError(state, action);
        case actionTypes.CLEAR_ORDER_FORMS_ERROR: return clearOrderFormsError(state, action);
        default:
            return state;
    }
};

export default reducer;