import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {ClipLoader} from 'react-spinners';
import Price from "../Price/Price";
import DateTime from "../Date/Date";
import Card from '../Card/Card';
import Paginator from '../Paginator/Paginator';
import {filterBy} from "../../store/actions/transactions";
import '../../styles/components/Transactions.scss';
import TableView from '../TableView/TableView';
import {formatTransactionCard} from "../Card/FormatCard";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

class Transactions extends Component {
    state = {
        formOpen: false,
        showDateRangePicker: false,
        date: null
    };

    componentDidMount() {
        if (!this.props.indexedProducts && !this.props.productsLoading) {
            this.props.getProducts();
        }
        if (this.props.userId) {
            this.props.getTransactionsForUser(this.props.userId);
        } else if (this.props.companyId) {
            this.props.getTransactionsForCompany(this.props.companyId)
        } else {
            this.props.getTransactions(this.props.currentPage || 0)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.date === null && this.state.date !== null) {
            // taking the date that is stored on the local state, converting the date format,
            // & then dispatching the filterByDate action to get all transactions that fall between the start and end date
            const startDate = this.state.date[0].toISOString();
            const endDate = this.state.date[1].toISOString();

            // filtering by date depending on if we also want to filter by user, payment state, or product ID
            if (this.props.userId) {
                this.props.filterBy(startDate, endDate, this.props.userId, null, null);
            } else {
                this.props.filterBy(startDate, endDate, null, null, null);
            }
        }
    }

    filterTransactions(e, filter) {
        if (this.props.userId) {
            if (e.target.value === 'SHOW_ALL') {
                this.props.filterBy(null, null, this.props.userId, null, null);
            }

            if (filter === 'paymentState') {
                this.props.filterBy(null, null, this.props.userId, e.target.value, null);
            }

            if (filter === 'productName') {
                this.props.filterBy(null, null, this.props.userId, null, e.target.value);
            }
        } else {
            if (e.target.value === 'SHOW_ALL') {
                this.props.getTransactions(0, null, null);
            }

            if (filter === 'paymentState') {
                this.props.filterBy(null, null, null, e.target.value, null);
            }

            if (filter === 'productName') {
                this.props.filterBy(null, null, null, null, e.target.value);
            }
        }
    }

    // function used to set the date range on the local state, used in the onChange of the DateRangePicker component
    setDateRange = date => this.setState({date});

    render() {
        if (!this.props.data || !this.props.indexedProducts) {
            return null;
        }

        let data = this.props.data.map(tx => {
               const newTx = Object.assign({}, tx, {
                   buyPrice: <Price price = {tx.buyPrice} />,
                   created: <DateTime date = {tx.created} />
               });
               let product = this.props.indexedProducts[tx.productId];
               if (product) {
                   newTx["tag"] = product.tag;
               } else {
                   newTx["tag"] = tx.productId;
               }
               return newTx;
           });

        let paginator = null;
        if (this.props.pages && this.props.pages > 1) {
            paginator = <Paginator
                            pages={this.props.pages}
                            currentPage={this.props.currentPage}
                            pageClick={page => this.props.getTransactions(page, null, null)}
                        />
        }

        let options;
        if (this.props.products) {
            options = this.props.products.map(product =>
                <option value={product.id} key={product.id}>{product.tag}</option>
            );
        }

        const cssClass = this.props.view === 'card' ? 'transactions-menu more-space' : 'transactions-menu';
        const paginatorClass = this.props.userId ? 'hide' : 'transactions-paginator';

        return (
            <React.Fragment>
                {
                    this.props.loading ?
                    <ClipLoader sizeUnit="px" size={100} color="black"/> :
                    null
                }
                {
                    this.props.hideCard ?
                    null :
                    <React.Fragment>
                        <div className={cssClass}>
                            <div className="select-dates">
                                <label htmlFor="date-checkbox">Filter By Date</label>
                                <input
                                    type="checkbox"
                                    id="date-checkbox"
                                    onClick={() => this.setState({showDateRangePicker: !this.state.showDateRangePicker})}
                                /><br/>
                                {
                                    this.state.showDateRangePicker ?
                                    <DateRangePicker
                                        onChange={this.setDateRange}
                                        value={this.state.date}
                                        className="date-range-picker"
                                    /> :
                                    null
                                }
                            </div>
                            <div className="select-payment-state">
                                <label htmlFor="payment-state-select">Filter By Payment State</label>
                                <select
                                    onChange={e => this.filterTransactions(e, 'paymentState')}
                                    id="payment-state-select"
                                >
                                    <option>---</option>
                                    <option value="UNPROCESSED">UNPROCESSED</option>
                                    <option value="FAILURE">FAILURE</option>
                                    <option value="SUCCESS">SUCCESS</option>
                                    <option value="CANCELED">CANCELED</option>
                                    <option value="REFUNDED">REFUNDED</option>
                                    <option value="PAST_DUE">PAST DUE</option>
                                    <option value="SHOW_ALL">SHOW ALL</option>
                                </select>
                            </div>
                            <div className="select-product-name">
                                <label htmlFor="product-name-select">Filter By Product Name</label>
                                <select
                                    onChange={e => this.filterTransactions(e, 'productName')}
                                    id="product-name-select"
                                >
                                    <option>---</option>
                                    {options}
                                    <option value="SHOW_ALL">SHOW ALL</option>
                                </select>
                            </div>
                            <div className={paginatorClass}>
                                {paginator}
                            </div>
                        </div>
                        {
                            this.props.view === 'card' ?
                            <Card
                                data={data}
                                format={transactions => formatTransactionCard(transactions)}
                                url={'/transaction/'}
                                userShowAll={this.props.userShowAll}
                                hideShowAllButton={this.props.hideShowAllButton}
                            /> :
                             <TableView
                                data={data}
                                headers={{
                                    tag: 'Tag',
                                    paymentState: 'Payment State',
                                    paymentProvider: 'Payment Provider',
                                    buyPrice: 'Price',
                                    created: 'Created'
                                }}
                                keys={['tag', 'paymentState', 'paymentProvider', 'buyPrice', 'created']}
                                rowClick={id =>
                                {
                                    console.log(this.props);
                                    this.props.history.push('/transaction/' + id)
                                }
                                }
                             />
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.transactions.loading,
        data: state.transactions.data,
        userData: state.transactions.userData,
        error: state.transactions.error,
        pages: state.transactions.pages,
        currentPage: state.transactions.currentPage,
        products: state.products.data,
        indexedProducts: state.products.indexedData,
        productsLoading: state.products.loading,
        view: state.view.view
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTransactions: (page, sort, dir) => dispatch(actions.getTransactions(page, sort, dir)),
        getTransactionsForUser: (userId) => dispatch(actions.getTransactionsForUser(userId)),
        getTransactionsForCompany: (companyId) => dispatch(actions.getTransactionsForCompany(companyId)),
        getProducts: () => dispatch(actions.getProducts()),
        filterBy: (startDate, endDate, userId, paymentState, productId) => dispatch(filterBy(startDate, endDate, userId, paymentState, productId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
