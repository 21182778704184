import * as actionTypes from "./actionTypes";
import {postData} from "../../shared/utility";

export const addUsersToCompanySeatsStart = () => {
    return {
        type: actionTypes.ADD_USERS_COMPANY_SEATS_START
    }
};

export const addUsersToCompanySeatsSuccess = (data) => {
    return {
        type: actionTypes.ADD_USERS_COMPANY_SEATS_SUCCESS,
        data: data
    }
};

export const addUsersToCompanySeatsFail = (error) => {
    return {
        type: actionTypes.ADD_USERS_COMPANY_SEATS_FAIL,
        error: error
    }
};

export const addUsersToCompanySeats = (data) => {
    return dispatch => {
        dispatch(addUsersToCompanySeatsStart());
        postData(
            "/bundle/user/add",
            data,
            response => {
                alert("Users Have Been Successfully Added.");
                dispatch(addUsersToCompanySeatsSuccess(response.data))
            },
            error => {
                dispatch(addUsersToCompanySeatsFail(error.response.status))
            },
            dispatch
        );
    };
};