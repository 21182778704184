
export const formFields = [
    {
        key: "list",
        type: "paste",
        placeholder: "Paste Bundle User List"
    }
];

export const csvFile = [
    {
        key: "file",
        type: "file",
        placeholder: "Upload Bundle User List"
    }
];

