import React from 'react';
import tableClasses from "../../sharedCss/Tables.module.css";
import {FaCog, FaEdit} from "react-icons/fa";

const KeyValueBlock = (props) => {
    const rows = props.keys.map(key => {
        return <tr className={tableClasses.underlinedRow} key={key}>
            <td>{props.translations[key]}</td>
            <td>{props.data[key]}</td>
        </tr>
    });
    let actions = null;
    if (props.actions && props.actions.length > 0) {
        actions = props.actions.map(action => {
            return <button className={tableClasses.dropdownButton} key={action.name}
                           onClick={action.action}>{action.name}</button>
        })
    }
    return (
        <div className={tableClasses.root}>
            <div className={tableClasses.buttonRow}>
                {props.editClick ?
                    <button className={tableClasses.addButton} onClick={(event) => props.editClick(event)}>
                        <FaEdit/>
                    </button> :
                    null
                }
                {actions ?
                    <div className={tableClasses.addButton + " " + tableClasses.actionsButton}>
                        <FaCog/>
                        <div className={tableClasses.dropdown}>{actions}</div>
                    </div>
                    : null
                }
            </div>
            <table className={tableClasses.table}>
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    );
};

export default KeyValueBlock;