import React from 'react';
import Price from "../Price/Price";
import DateTime from "../Date/Date";
import '../../styles/components/Card.scss';

export const formatUserCard = user => {
    return (
        <React.Fragment>
            <div className="card-title">
                <h3 className="user-name">{user.firstName} {user.lastName}</h3>
                <h3 className="user-status">{user.status}</h3>
            </div>
            <p className="user-role">{user.role}</p>
            <p className="user-phone">{user.phone}</p>
            <p className="user-email">{user.email}</p>
        </React.Fragment>
    );
};


export const formatTaskCard = task => {
    return (
        <React.Fragment>
            <div className="card-title">
                <h3 className="card-name">{task.name}</h3>
                <h3 className="product-interval">{task.priority}</h3>
            </div>
            <p>Description: {task.description}</p>
            <p>Created: {task.created}</p>
            <p>On Behalf Of: {task.onBehalfOf}</p>
            <p>Status: {task.status}</p>

        </React.Fragment>
    );
};


export const formatProductCard = product => {
    return (
        <React.Fragment>
            <div className="card-title">
                <h3 className="card-name">{product.descriptiveName}</h3>
                <h3 className="product-interval">{product.interval}</h3>
            </div>
            <p>PRICE: <Price price={product.price}/></p>
            <p>FUNNEL PRICE: <Price price={product.funnelPrice}/></p>
            <p>{product.description}</p>
        </React.Fragment>
    );
};

export const formatProductGroupCard = productGroup => {
    return (
        <React.Fragment>
            <div className="card-title">
                <h3>{productGroup.descriptiveName}</h3>
            </div>
            <p>ORDER: {productGroup.order}</p>
            <p>VISIBILITY: {productGroup.visible.toString()}</p>
        </React.Fragment>
    );
};

export const formatOrderFormCard = orderForm => {
    return (
        <React.Fragment>
            <div className="card-title">
                <h3 className="card-name">{orderForm.tag}</h3>
                <h3>{orderForm.paymentState}</h3>
            </div>
            <p>Price : {orderForm.buyPrice}</p>
            <p>Issuer : {orderForm.issuerName}</p>
        </React.Fragment>
    )
};

export const formatCompanyCard = data => {
    return <React.Fragment>
        <div className="card-title">
            <h3 className="user-name">{data.name}</h3>
            <h3 className="user-status">{data.status}</h3>
        </div>
        <p className="user-role">{data.role}</p>
        <p className="user-phone">{data.phone}</p>
        <p className="user-email">{data.email}</p>
    </React.Fragment>
};

export const formatTransactionCard = transaction => {
    return (
        <React.Fragment>
            <div className="card-title">
                <h3 className="card-name">{transaction.tag}</h3>
                <h3>{transaction.paymentState}</h3>
            </div>
            <p>PAYMENT PROVIDER : {transaction.paymentProvider}</p>
            <p>PRICE : {transaction.buyPrice}</p>
            <p>CREATED : {transaction.created}</p>
        </React.Fragment>
    );
};

export const formatBundleCard = bundle => {
    const numberSeated = bundle.seatUsers ? bundle.seatUsers.length : 0;
    return (
        <React.Fragment>
            <p>Total Seats : {bundle.seatsTotal}</p>
            <p>Users Seated : {numberSeated}</p>
            <p>Created : <DateTime date={bundle.created}/></p>
        </React.Fragment>
    );
};

export const formatCouponCard = coupon => {
    return (
        <React.Fragment>
            <h3 className="card-title">Code : {coupon.code}</h3>
            <p className="card-name">Type : {coupon.type}</p>
            <p className="card-name">Discount : {coupon.discount}</p>
            <p className="card-name">Active : {coupon.active}</p>
        </React.Fragment>
    );
};
