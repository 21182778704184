import {types} from "../shared/validators";

export const couponFormFields = [
    {
        key: "code",
        type: "text",
        placeholder: "Discount code",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "type",
        type: "select",
        placeholder: "Discount type",
        options: {
            FLAT_RATE: "FLAT RATE",
            PERCENTAGE: "PERCENTAGE"
        },
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    },
    {
        key: "discount",
        type: "number",
        placeholder: "Discount amount",
        validation: [
            {
                type: types.REQUIRED
            }
        ]
    }
];

export const updateCouponFields = [
    {
        key: "active",
        type: "select",
        placeholder: "Activate or deactivate coupon.",
        options: {
            true: "Activate coupon",
            false: "Deactivate coupon"
        }
    }
];
