import * as actionTypes from "./actionTypes";
import {getData, postData} from "../../shared/utility";

export const getMeStart = () => {
    return {
        type: actionTypes.GET_ME_START
    }
};

export const getMeSuccess = (data) => {
    return {
        type: actionTypes.GET_ME_SUCCESS,
        data: data
    }
};

export const getMeFail = (error) => {
    return {
        type: actionTypes.GET_ME_FAIL,
        error: error
    }
};

export const changePasswordStart = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_START
    }
};

export const changePasswordSuccess = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_SUCCESS
    }
};

export const changePasswordFail = (error) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_FAIL,
        error: error
    }
};

export const getMe = () => {
    return dispatch => {
        dispatch(getMeStart());
        getData(
            "/users/me",
            response => {
                dispatch(getMeSuccess(response.data))
            },
            error => {
                dispatch(getMeFail(error.response.status))
            },
            dispatch);
    };
};

export const changePassword = (data) => {
    return dispatch => {
        dispatch(changePasswordStart());
        postData("/users/me/password",
            data,
            response => {
                dispatch(changePasswordSuccess())
            },
            error => {
            console.log(error.response)
                dispatch(changePasswordFail(error.response.data.message))
            },
            dispatch);
    }
};