import React from 'react';
import classes from './Header.module.css'

const UserOptions = (props) => {
    return (
        <React.Fragment>
            <div className={classes.backdrop} onClick={props.closeOptions}/>
            <div className={classes.options}>
                <button className={classes.option} onClick={props.openPasswordForm}>Change password</button>
                <button className={classes.option} onClick={props.logout}>Logout</button>
            </div>
        </React.Fragment>
    )
};

export default UserOptions;